import React, {Component} from 'react';
import {withTranslation} from "react-i18next";

import "./MyMessagesList.css"
import images from "../../images"
import Reply from "./Reply";
import utils, {getTranslatedRequestTitle} from "../../utils";
import {AlertsTitles} from "../../utils";

class MyMessagesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: '',
        };
    }

    render() {
        const {requests, alerts, activeMessageIndex, activeAlertIndex, isAlertsView, meters, t} = this.props;
        const list = isAlertsView?  alerts : requests;

        const messagesList = list.map((item, index) => {
            const className = "ReplayItem "
                + ((isAlertsView? activeAlertIndex === index : activeMessageIndex === index) ? "selected " : ' ')
                + ((item?.wasRead || item?.wasRead === null || item?.isRead === "true")? null : 'notRead');
            const meter = meters.find(meter => +meter.meterCount === +item.meterCount);
            return (
                <li className={className}
                     key={`replays${index}`}
                     onClick={() => this.props.handleActiveMessage(index)}>
                    <div className="ReplayItem__Container">
                        <div className="ReplayItem__Container_Icon">
                            <img src={utils.getSmallIconForAlertType(item.alertTypeId)} alt="message icon"/>
                        </div>
                        <div className="ReplayItem_content">
                            <div className="ReplayItem_header">
                                {isAlertsView? t(`AlertsTitles.${AlertsTitles[+item.alertTypeId] ?? ''}`) : getTranslatedRequestTitle(item.requestSubject)}</div>
                            <div className="ReplayItem_description">
                                {isAlertsView?
                                   `${meter? meter?.meterSn + ',' : ""} ${meter?.fullAddress || ""}`
                                    : ''}</div>
                            <div className="ReplayItem_date">
                                {utils.toAlertDate(isAlertsView? item.alertTime : item.answerDate? item.answerDate : item.requestDate)}</div>
                        </div>
                    </div>
                    <img src={images().icArrowRightBlue} alt="open" className="ReplayItem_Arrow"/>
                </li>
            )
        })


        return (
            <div className="Requests">
                <div className={((!isAlertsView && activeMessageIndex !== '') || (isAlertsView && activeAlertIndex !== '')) ? " Requests_listContainer_hidden" : "Requests_listContainer"}>
                    <ul className={((!isAlertsView && activeMessageIndex !== '') || (isAlertsView && activeAlertIndex !== '')) ? " ReplaysList_hidden" : "ReplaysList"}>{messagesList}</ul>
                </div>
                {((!isAlertsView && activeMessageIndex !== '') || (isAlertsView && activeAlertIndex !== '')) &&

                    <Reply {...this.props} />
                }
            </div>
        );
    }
}

export default withTranslation('translations')(MyMessagesList);
