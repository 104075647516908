/* eslint-disable */
import React from 'react';
import './RegisterFinish.css';
import * as api from "../../api";
import Input from 'react-validation/build/input';
import Form from 'react-validation/build/form';
import Button from 'react-validation/build/button';
import * as validators from '../../validators';
import DataManager from "../../managers/DataManager";
import images from "../../images";

import Popup from "reactjs-popup";
import PersistenceManager from "../../managers/PersistenceManager";
import utils from "../../utils";
import globals from "../../globals";
import AppPopup from "../../components/AppPopup";
import {withTranslation} from "react-i18next";

import AppButton from "../../components/AppButton";

class RegisterFinish extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            confirmEmail: '',
            pass: '',
            confirmPass: '',
            isSecure: true,
            openTerms: false,
            openPrivacy: false,
            isNumCharsValid: true,
            isCharsValid: true,
            errorMessage: null
        }
    }

    render() {
        const {t} = this.props

        return (
            <div className="registerFinish_Container">
                <h1 className={"title"}>{t('JoinMyWaterAdvisor')}</h1>
                <Form ref={c => { this.form = c }}
                      method={"post"}
                      onSubmit={this.onSubmit.bind(this)}
                style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div style={{marginTop: 20}}>
                        <h1 className={"inputTitle"}>{t('validEmail')}</h1>
                        <Input validations={[validators.required, validators.email]}
                               placeholder={t('mail')}
                               type="email"
                               // autoComplete="off"
                               className="rectangleRegisterFinish" name='email'
                               value={this.state.email}
                               onChange={(event) => this.setState({email: event.target.value})}
                               style={{paddingLeft: 10, paddingRight: 10, display: 'flex', fontSize: 16}}>
                        </Input>
                    </div>
                    <div style={{marginTop: 20}}>
                        <h1 className={"inputTitle"}>{t('repeatEmail')}</h1>
                        <Input validations={[validators.required, validators.email]}
                               placeholder={t('mail')}
                               type="email"
                               className="rectangleRegisterFinish" name='email'
                               value={this.state.confirmEmail}
                               onChange={(event) => this.setState({confirmEmail: event.target.value})}
                               style={{paddingLeft: 10, paddingRight: 10, display: 'flex', fontSize: 16}}>
                        </Input>
                    </div>
                    <div style={{marginTop: 20}}>
                        <h1 className={"inputTitle"}>{t('chooseNewPassword')}</h1>
                        <div style={{display: 'flex'}}>
                            <Input validations={[validators.required, validators.passwordRequirements]} placeholder={t('PASSWORD')}
                                   type={this.state.isSecure ? "password" : "text"} className="rectangleRegisterFinish"
                                   name='PASSWORD'
                                   autoComplete="new-password"
                                   value={this.state.pass}
                                   onChange={(event) => this.setState({pass: event.target.value})}
                                   style={{paddingLeft: 10, paddingRight: 10, display: 'flex', fontSize: 16}}>
                            </Input>
                            <button type={'button'}
                                    className="eyeButton"
                                    onClick={(event) => this.toggleSecurePass(event)}>
                                <img src={images().icOpenEyeGray}/>
                            </button>
                        </div>

                    <p className={"passwordRulesText"}
                       style={{
                           margin: 0,
                           marginTop: 8,
                           color: this.state.isCharsValid ? '#999ea8' : '#ff7a7a'
                       }}>{t('mustContain')}</p>
                    {/*<p className={"passwordRulesText"}*/}
                    {/*   style={{*/}
                    {/*       margin: 0,*/}
                    {/*       marginTop: 4,*/}
                    {/*       color: this.state.isNumCharsValid ? '#999ea8' : '#ff7a7a'*/}
                    {/*   }}>{t('mustContain8Char')}</p>*/}
                    </div>
                    <div style={{marginTop: 20}}>
                        <h1 className={"inputTitle"}>{t('typePasswordAgain')}</h1>
                        <div style={{display: 'flex'}}>
                            <Input validations={[validators.required, validators.passwordRequirements]} placeholder={t('PASSWORD')}
                                   type={this.state.isSecure ? "password" : "text"} className="rectangleRegisterFinish"
                                   name='PASSWORD'
                                   autoComplete="new-password"
                                   value={this.state.confirmPass}
                                   onChange={(event) => this.setState({confirmPass: event.target.value})}
                                   style={{paddingLeft: 10, paddingRight: 10, display: 'flex', fontSize: 16}}>
                            </Input>
                            <button type={'button'}
                                    className="eyeButton"
                                    onClick={(event) => this.toggleSecurePass(event)}>
                                <img src={images().icOpenEyeGray}/>
                            </button>
                        </div>
                    </div>
                    <div style={{marginTop: 20}}>
                        <h4 className={"signingUp"}>{t('iAgree')} <a
                            onClick={()=> this.props.openTerms()}>{t('TermsOfUse')}</a> {t('and')} <a
                            onClick={()=> this.props.openPrivacy()}>{t('Privacy')}</a></h4>
                    </div>
                    <div style={{marginTop: 20, display: 'flex', justifyContent: 'center'}}>
                        <Button style={{border: 0, backgroundColor: 'transparent'}}>
                            <AppButton text={t('buttons.finish')} color='blue' onClick={()=> this.form.validateAll()} propsStyle={{marginBottom: 30, width: 190}} />
                        </Button>
                    </div>
                    <Popup
                        contentStyle={{borderRadius: 8, width: 'initial', minWidth: '50%'}}
                        open={this.state.errorMessage}
                        onClose={this.closePopup.bind(this)}
                    >
                        <AppPopup onClose={this.closePopup.bind(this)} message={this.state.errorMessage}/>
                    </Popup>
                </Form>
            </div>
        );
    }

    closePopup = () => {
        this.setState({errorMessage: null});
    };

    onSubmit = (event) => {
        event.preventDefault();
        const {t} = this.props;

        const sessionId = DataManager.sharedInstance.sessionId;
        const {code} = this.props.match.params;
        const {email, confirmEmail, pass, confirmPass} = this.state;
        if (pass && confirmPass &&  pass !== confirmPass) {
            this.setState({errorMessage: t('passwordDontMatch')});
        } else
            if (email && confirmEmail && email !== confirmEmail) {
                this.setState({errorMessage: t('emailsDontMatch')});
            } else {
            const isCharsValid = /^(?=.*[a-zA-Z])(?=.*[0-9])/.test(pass);
            const isNumCharsValid = pass.length >= 8;

            this.setState({isCharsValid: isCharsValid});
            this.setState({isNumCharsValid: isNumCharsValid});

            if (isCharsValid && isNumCharsValid) {
                //Success
                //this.setState({isLoading: true});
                api.registerFinish(sessionId, email, pass, code, (response, error) => {
                    //this.setState({isLoading: false});
                    if (error) {
                        this.setState({errorMessage: t('errorOccurred')});
                    } else {
                        if (response.error) {
                            this.setState({errorMessage: response.error});
                        } else {
                            //Success
                            const uniqueId = utils.generateId();
                            api.login(email, pass, uniqueId, (response, error) => {
                                if (error) {
                                    console.log('Unknown error has occurred.');
                                    this.setState({errorMessage: t('errorOccurred')});
                                } else {
                                    if (response.error) {
                                        console.log(response.error);
                                        this.setState({errorMessage: response.error});
                                    } else {
                                        const token = response.token;
                                        console.log('token = ' + token);
                                        api.setTokenValue(token);

                                        PersistenceManager.storeItem(globals.STORAGE.TOKEN, token);

                                        this.props.history.push('/home');
                                        this.props.onLogin();
                                    }
                                }
                            });

                        }
                    }
                })
            }
        }

    };

    toggleSecurePass(event) {
        event.preventDefault();
        if (event.detail !== 0) {
            this.setState({isSecure: !this.state.isSecure});
        }
    }
}

export default withTranslation('translations')(RegisterFinish)
