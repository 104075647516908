import globals from "./globals";
import DataManager from "./managers/DataManager";
import i18next from "./i18n";

export let token = undefined;

// const apiFile = (url, authorize, callback) => {
//     const headers = {
//         Accept: 'application/json',
//         'Content-Type': 'application/json'
//     };
//
//     if (authorize) headers['x-access-token'] = token;
//
//     fetch(url, {
//         method: 'GET',
//         headers: headers
//     })
//         .then(res => res.blob())
//         .then(blob => callback(blob, null));
// };

// const _getFile = (url, authorize, callback) => {
//   console.log('token', token);

//   const headers = {
//       Accept: 'application/json',
//       'Content-Type': 'text/html; charset=utf-8'
//   };

//   if (authorize) headers['x-access-token'] = token;

//   fetch(url, {
//       method: 'GET',
//       headers: headers
//   })
//   .then(async res => {
//     const decoder = new TextDecoder('ISO-8859-8');
//     let arrayBuffer = await res.arrayBuffer();
//     let text = decoder.decode(arrayBuffer);

//     console.log('res', modifyExcel(text));

//     text = modifyExcel(text);

//     const csvFile = new Blob([text], {type: 'text/csv'});
//     return csvFile;
//   })
//       // .then(res => res.blob())
//       .then(blob => callback(blob, null));
// };

// const modifyExcel = (inputString) => {
//   const words = inputString.split(" ");

//   words[0] = excelTitlesMap(0);
//   words[1] = excelTitlesMap(1);

//   const modifiedString = words.join(" ");

//   return modifiedString;
// }

// const excelTitlesMap = (index) => {
//   const currentLanguage = i18next.language;
//   let replacedTitle = ''
//   switch (currentLanguage) {
//     case 'ar':
//       if (index === 0) replacedTitle = "تاریخ";
//       if (index === 1) replacedTitle = "استهلاك";
//       break;
//     case 'en':
//       if (index === 0) replacedTitle = "Date";
//       if (index === 1) replacedTitle = "Consumption";
//       break;
//     case 'es':
//       if (index === 0) replacedTitle = "Fecha";
//       if (index === 1) replacedTitle = "Consumo";
//       break;
//     case 'he':
//       if (index === 0) replacedTitle = "תאריך";
//       if (index === 1) replacedTitle = "צריכה";
//       break;
//     default:
//       if (index === 0) replacedTitle = "Date";
//       if (index === 1) replacedTitle = "Consumption";
//       break;
//   }

//   if (index === 0) replacedTitle += '\t';
//   if (index === 1) replacedTitle += '\n';

//   return replacedTitle;
// }


export const api = async (
  url,
  authorize,
  callback,
  params,
  method = "GET",
  blob = false
) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (authorize) {
    headers["x-access-token"] = token;
  } else {
    if (url.includes('/v1/municipals')) {
      headers["x-app-id"] = globals.APP_ID_2;
    } else {
      headers["x-app-id"] = globals.APP_ID;
    }
  }

  // console.log('token', token);
  // console.log('APP_ID', globals.APP_ID);

  let result;
  try {
    const Timeout = (time) => {
      let controller = new AbortController();
      setTimeout(() => controller.abort(), time * 1000);
      return controller;
    };

    result = await fetch(url, {
      method,
      headers: headers,
      body: method === "GET" ? null : JSON.stringify(params),
      signal: Timeout(15).signal
    })
      .then((res) => {
        console.log(url, res, JSON.stringify(params));
        const { status } = res;
        if (status === 401) {
          DataManager.sharedInstance.isAuthenticated = false;
          return blob ? res.blob() : res.text();
        } else if (status === 404) {
          return { error: status };
        }
        else if (status === 204) {
          return { error: status };
        } else {
          console.log("res", res);
          return blob ? res.blob() : res.text();
        }
      })
      .then((res, err) => {
        console.log("res2", res, err);
        const result = blob ? res : res ? JSON.parse(res) : {};
  
        if (url === `${globals.BASE_URL}/municipals/h1/measurmentunits`) {
          console.log("meter units", result);
        }
  
        const error =
          +i18next.t(result?.code) === +result?.code
            ? result.error
            : i18next.t(result?.code);
        return result?.code ? { ...result, error } : result;
      })
      .catch((err) => {
        console.log("error is", err);
        throw err;
      });
  } catch (e) {
    console.log('GET error', e.toString());
    result = {"code": 0, "error": i18next.t("errorOccurred")};
  }

  
  callback(result);
};

export const setTokenValue = (tokenValue) => {
  token = tokenValue;
};

//Push
export const sendDeviceToken = (deviceId, token, callback) => {
  const url = `${globals.BASE_URL}/communication/mobiledevices/pushprovidertoken`;
  const params = {
    deviceId: deviceId,
    pushProviderToken: token,
    pushProviderId: 1,
  };

  api(url, true, callback, params, "POST");
};

//Vacations
export const deleteVacation = (vacationID, callback) => {
  const url = `${globals.BASE_URL}/consumer/vacations/${vacationID}`;
  api(url, true, callback, {}, "DELETE");
};

export const editVacation = (
  vacationID,
  startDate,
  endDate,
  consumptionDailyLimit,
  // meterCount,
  callback
) => {
  const url = `${globals.BASE_URL}/consumer/vacations/`;
  const params = {
    vacationID: vacationID,
    startDate: startDate,
    endDate: endDate,
    consumptionDailyLimit: consumptionDailyLimit,
    // meterCount: meterCount
  };

  api(url, true, callback, params, "PUT");
};

export const addVacation = (
  startDate,
  endDate,
  consumptionDailyLimit,
  // meterCount,
  callback
) => {
  const url = `${globals.BASE_URL}/consumer/vacations/`;
  const params = {
    vacationID: 0,
    startDate: startDate,
    endDate: endDate,
    consumptionDailyLimit: consumptionDailyLimit,
    // meterCount: meterCount
  };

  api(url, true, callback, params, "POST");
};

export const getVacations = (callback) => {
  const url = `${globals.BASE_URL}/consumer/vacations/`;
  api(url, true, callback);
};

//Alerts
export const confirmAlert = (alertLogId, callback) => {
  const url = `${globals.SECOND_URL}/consumer/myalerts/confirm/${alertLogId}`;
  api(url, true, callback, {}, "POST");
};

// export const getAlertDetails = (alertTypeId, alertLogId, callback) => {
//     const url = `${
//         globals.BASE_URL
//     }/consumer/myalerts/${alertLogId}/${alertTypeId}`;
//     api(url, true, callback);
// };

export const getAlerts = (callback) => {
  const url = `${globals.SECOND_URL}/consumer/myalerts`;
  api(url, true, callback);
};

//Alerts settings
export const unsetAlert = (alertTypeId, mediaTypeId, callback) => {
  const url = `${globals.SECOND_URL}/consumer/myalerts/settings/${alertTypeId}`;
  const params = [mediaTypeId];
  api(url, true, callback, params, "DELETE");
};

export const setAlert = (alertTypeId, mediaTypeId, callback) => {
  const url = `${globals.SECOND_URL}/consumer/myalerts/settings/${alertTypeId}`;
  const params = [mediaTypeId];
  api(url, true, callback, params, "PUT");
};

export const getAlertSettings = (callback) => {
  const url = `${globals.BASE_URL}/consumer/myalerts/settings`;
  api(url, true, callback);
};

export const getMe = (callback) => {
  const url = `${globals.BASE_URL}/consumer/me`;
  api(url, true, callback);
};

export const adminLogin = (email, password, captchaResponse, callback) => {
  const url = `${globals.BASE_URL}/account/login`;
  const params = {
    username: email,
    password,
    lc: "en-US",
    captchaResponse,
  };

  api(url, false, callback, params, "POST");
};

export const login = (email, password, deviceId, callback) => {
  const url = `${globals.BASE_URL}/consumer/login`;
  const params = {
    email: email,
    // type: 1,
    pw: password,
    deviceId: deviceId,
    // osType: 3,
    // app: "3a869241-d476-40f6-a923-d789d63db11d",
  };

  api(url, false, callback, params, "POST");
};

// export const getAvgHouseHoldsConsumption = (fromDate, toDate, callback) => {
//     const url = `${
//         globals.SECOND_URL
//     }/consumption/avghouseholds/${fromDate}/${toDate}`;
//     api(url, true, callback);
// };

// export const getAllDailyConsumptionBasedOnBillingCycle = (callback) => {
//     const url = `${globals.SECOND_URL}/consumption/daily/lastbillingcycle`;
//     api(url, true, callback);
// };

export const getAllDailyConsumptionByDates = (fromDate, toDate, callback) => {
  const url = `${globals.SECOND_URL}/consumption/daily/${fromDate}/${toDate}`;
  api(url, true, callback);
};

// export const getMeterDailyConsumptionBasedOnBillingCycle = (meterCount, callback) => {
//     const url = `${globals.SECOND_URL}/consumption/daily/lastbillingCycle/${meterCount}`;
//     api(url, true, callback);
// };

export const getMeterDailyConsumptionByDates = (
  meterCount,
  fromDate,
  toDate,
  callback
) => {
  const url = `${globals.SECOND_URL}/consumption/daily/${meterCount}/${fromDate}/${toDate}`;
  api(url, true, callback);
};

export const getAllMonthlyConsumption = (fromMonth, toMonth, callback) => {
  const url = `${globals.SECOND_URL}/v1.1/consumption/monthly/${fromMonth}/${toMonth}`;
  api(url, true, callback);
};

export const getAllDailyConsumptionExcelBasedOnBillingCycle = (meterCount, callback) => {
  const url = `${globals.BASE_URL}/consumption/dailyExcel/${meterCount !== null ? meterCount + '/' : ''}lastbillingcycle`;
  api(url, true, callback, null, "GET", true);
  // _getFile(url, true, callback);
};

export const getAllDailyConsumptionExcel = (meterCount, fromDate, toDate, callback) => {
  const url = `${globals.SECOND_URL}/consumption/dailyExcel/${meterCount !== null ? meterCount + '/' : ''}${fromDate}/${toDate}`;
  api(url, true, callback, null, "GET", true);
  // _getFile(url, true, callback);
};

export const getAllMonthlyConsumptionExcel = (meterCount, fromMonth, toMonth, callback) => {
  const url = `${globals.SECOND_URL}/consumption/monthlyExcel/${meterCount !== null ? meterCount + '/' : ''}${fromMonth}/${toMonth}`;
  api(url, true, callback, null, "GET", true);
  // _getFile(url, true, callback);
};

export const getAllMonthlyForecast = (callback) => {
  const url = `${globals.SECOND_URL}/consumption/forecast`;
  api(url, true, callback);
};

export const getMeterMonthlyForecast = (meterCount, callback) => {
  const url = `${globals.SECOND_URL}/consumption/forecast/${meterCount}`;
  api(url, true, callback);
};

export const getMeterDailyConsumption = (
  meterCount,
  fromDate,
  toDate,
  callback
) => {
  const url = `${globals.BASE_URL}/consumption/daily/${meterCount}/${fromDate}/${toDate}`;
  api(url, true, callback);
};

export const getMeterMonthlyConsumption = (
  meterCount,
  fromMonth,
  toMonth,
  callback
) => {
  const url = `${globals.SECOND_URL}/v1.1/consumption/monthly/${meterCount}/${fromMonth}/${toMonth}`;
  api(url, true, callback);
};

export const setMonthlyLimit = (limit, callback) => {
  const url = `${globals.SECOND_URL}/consumer/settings/monthlylimit/${limit}`;
  api(url, true, callback, {}, "POST");
};

export const getMonthlyLimit = (callback) => {
  const url = `${globals.SECOND_URL}/consumption/Low-Rate-Limit`;
  api(url, true, callback);
};

export const deleteMonthlyLimit = (callback) => {
  const url = `${globals.BASE_URL}/consumer/settings/monthlylimit`;
  api(url, true, callback, {}, "DELETE");
};

export const getMeasurementUnits = (callback) => {
  const url = `${globals.BASE_URL}/municipals/h1/measurmentunits`;
  api(url, true, callback);
};

export const getMeters = (callback) => {
  const url = `${globals.SECOND_URL}/consumer/meters`;
  api(url, true, callback);
};

export const getLastRead = (callback) => {
  const url = `${globals.SECOND_URL}/consumption/last-read`;
  api(url, true, callback);
};

export const finishRecovery = (code, password, callback) => {
  const url = `${globals.BASE_URL}/consumer/password/recovery/finish`;
  const params = {
    code: code,
    password: password,
  };

  api(url, false, callback, params, "POST");
};

export const validateRecoveryCode = (code, callback) => {
  const url = `${globals.BASE_URL}/consumer/password/recovery/codevalidation`;
  const params = {
    code: code,
  };

  api(url, false, callback, params, "POST");
};

export const recoverPassword = (email, callback) => {
  const url = `${globals.BASE_URL}/consumer/password/recovery`;
  const params = {
    email: email,
  };

  api(url, false, callback, params, "POST");
};

export const getMunicipalCustomerService = (callback) => {
  const url = `${globals.BASE_URL}/municipals/municipalCustomerService`;
  api(url, true, callback);
};

export const searchUtilities = (term, callback) => {
  const url = `${globals.BASE_URL}/municipals/h1/search/${term}`;
  api(url, false, callback);
};

export const logout = (callback) => {
  const url = `${globals.BASE_URL}/consumer/logout`;
  api(url, true, callback, {}, "POST");
};

export const registerPhone = (
  countryCode,
  phoneNumber,
  languageCode,
  callback
) => {
  const url = `${globals.BASE_URL}/consumer/register/phone`;
  const params = {
    countryCode: countryCode, //+972
    phoneNumber: phoneNumber, //0544310754
    languageCode: languageCode,
  };
  api(url, false, callback, params, "POST");
};

export const registerAccount = (
  propertyId,
  accountNumber,
  municipalId,
  siteId,
  callback
) => {
  let url = `${globals.SECOND_URL}/consumer/register/account`;
  const params = {
    propertyId,
    accountNumber,
    municipalId,
    languageCode: "he",
    siteId
  };
  api(url, false, callback, params, "POST");
};

export const registerFinish = (sessionId, email, password, code, callback) => {
  const url = `${globals.SECOND_URL}/consumer/register/finish`;
  let params = {
    sessionId: sessionId,
    email: email,
    password: password,
    type: 1,
  };

  if (code) params.code = code;

  api(url, false, callback, params, "POST");
};

export const validateRegisterCode = (sessionId, code, callback) => {
  const url = `${globals.BASE_URL}/consumer/register/codevalidation`;
  const params = {
    sessionId: sessionId,
    code: code,
  };

  api(url, false, callback, params, "POST");
};

export const updatePhoneNumber = (
  countryCode,
  phoneNumber,
  languageCode,
  callback
) => {
  const url = `${globals.SECOND_URL}/consumer/phone`;
  const params = {
    countryCode: countryCode, //972
    phoneNumber: phoneNumber, //0544310754
    languageCode: languageCode,
  };

  api(url, true, callback, params, "PUT");
};

export const changePassword = (currentPassword, newPassword, callback) => {
  const url = `${globals.BASE_URL}/consumer/password/change`;
  const params = {
    currentPassword,
    newPassword,
  };

  api(url, true, callback, params, "PUT");
};

//Admin
export const getLoginCredentials = (key, callback) => {
  const url = `${globals.BASE_URL}/consumer/login-credentials/${key}`;
  api(url, true, callback);
};

export const getMunicipalityMessages = (callback) => {
  const url = `${globals.SECOND_URL}/municipality/${DataManager.sharedInstance.user.municipalId}/messages`;
  api(url, true, callback);
};

export const getMessagesSubjects = (callback) => {
  const url = `${globals.SECOND_URL}/municipality/${DataManager.sharedInstance.user.municipalId}/messages/message-subjects`;
  api(url, true, callback);
};

export const sendMunicipalityMessage = (params, callback) => {
  const url = `${globals.SECOND_URL}/municipality/${DataManager.sharedInstance.user.municipalId}/messages/add-consumer-request`;
  api(url, true, callback, params, "POST");
};

export const setMessageWasRead = (answerId, callback) => {
  const url = `${globals.SECOND_URL}/municipality/${DataManager.sharedInstance.user.municipalId}/messages/set-read?answerId=${answerId}`;
  api(url, true, callback, null, "POST");
};

export const isMessagesAllowed = (callback) => {
  const url = `${globals.SECOND_URL}/municipality/${DataManager.sharedInstance.user.municipalId}/messages/is-messages-to-municipal-allowed`;
  api(url, true, callback);
};

export const getCurrentConsumption = (fromMonth, toMonth, callback) => {
  const url = `${globals.SECOND_URL}/consumption/currentConsumption`;
  api(url, true, callback);
};

export const getAlertsForSettings = (callback) => {
  const url = `${globals.SECOND_URL}/consumer/alertsForSettings`;
  api(url, true, callback);
};

export const searchMunicipals = (params, callback) => {
  const url = `${globals.BASE_URL}/v1/municipals`;
  api(url, false, callback);
};
