/* eslint-disable */
import React from 'react';
import * as validators from "../../validators";
import images from "../../images";
import './ChangePassword.css';
import '../../App.css';
import * as api from "../../api";
import Input from 'react-validation/build/input';
import Form from 'react-validation/build/form';
import Button from 'react-validation/build/button';
import {Mobile} from "../../App";

import Popup from "reactjs-popup";
import AppPopup from "../../components/AppPopup";
import {withTranslation} from "react-i18next";

import AppButton from "../../components/AppButton";

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pass: '',
            confirmPass: '',
            isSecureNewPass: true,
            isSecureConfirmPass: true,
            errorMessage: null
        };
    }

    render() {
        const {t} = this.props
        return (
            <div>
                <Mobile>
                    {matches => {
                        if (matches)
                            return this._renderMobile(t);
                        else
                            return this._renderDesktop(t);
                    }}
                </Mobile>
            </div>
        );
    }

    _renderMobile(t) {
        return (
            <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginRight: 20,
                marginLeft: 20
            }}>
                <h1 className={"titleMobile"} style={{fontSize: 17}}>{t('changePassword')}</h1>
                <Form ref={c => { this.form = c }} method={"post"} onSubmit={this.onSubmit.bind(this)}>
                    <div style={{marginTop: 10}}>
                        <h1 className={"choosePassword"} style={{textAlign: 'center'}}>{t('chooseNewPassword')}</h1>
                        <div style={{
                            flexDirection: 'row',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Input validations={[validators.required, validators.passwordRequirements]} placeholder={t('PASSWORD')}
                                   className="rectangleMobile rectangle"
                                   name='PASSWORD'
                                   value={this.state.pass}
                                   type={this.state.isSecureNewPass ? "password" : "text"}
                                   onChange={(event) => this.setState({pass: event.target.value})}
                                   style={{paddingLeft: 10, paddingRight: 10, display: 'flex', fontSize: 16}}>
                            </Input>
                            <button className="eyeButton"
                                    onClick={(event) => this.toggleSecureNewPass(event)}>
                                <img src={images().icOpenEyeGray}/>
                            </button>
                        </div>
                    </div>
                    <div style={{marginTop: 20}}>
                        <h1 className={"choosePassword"} style={{textAlign: 'center'}}>{t('typePasswordAgain')}</h1>
                        <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'center'}}>
                            <Input validations={[validators.required, validators.passwordRequirements]}
                                   placeholder={t('PASSWORD')}
                                   type={this.state.isSecureConfirmPass ? "password" : "text"}
                                   className="rectangle rectangleMobile"
                                   name='PASSWORD'
                                   value={this.state.confirmPass}
                                   onChange={(event) => this.setState({confirmPass: event.target.value})}
                                   style={{paddingLeft: 10, paddingRight: 10, display: 'flex', fontSize: 16}}>
                            </Input>
                            <button className="eyeButton"
                                    onClick={(event) => this.toggleSecureConfirmPass(event)}>
                                <img src={images().icOpenEyeGray}/>
                            </button>
                        </div>
                    </div>
                    <div style={{marginTop: 10, display: 'flex', justifyContent: 'center'}}>
                        {/* <Button style={{border: 0, backgroundColor: 'transparent'}} onClick={(e) => {e.preventDefault(); this.form.validateAll(); this.onSubmit(e)}}> */}
                            <AppButton text={t('buttons.savePassword')} color='blue' onClick={(e) => this.onButtonClick(e)} propsStyle={{width: 125, marginBottom: 30}} />
                        {/* </Button> */}
                    </div>
                    <Popup
                        contentStyle={{borderRadius: 5}}
                        open={this.state.errorMessage}
                        onClose={this.closePopup.bind(this)}
                    >
                        <AppPopup onClose={this.closePopup.bind(this)} message={this.state.errorMessage}/>
                    </Popup>
                </Form>
            </div>
        );
    }

    onButtonClick = (e) => {
        console.log('click');
        e.preventDefault(); 
        console.log('click');
        this.form.validateAll(); 
        this.onSubmit(e);
    }

    _renderDesktop(t) {
        return (
            <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <h1 className={"title"}>{t('changePassword')}</h1>
                <Form ref={c => { this.form = c }} method={"post"} onSubmit={this.onSubmit.bind(this)}>
                    <div style={{marginTop: 20}}>
                        <h1 className={"choosePassword"}>{t('chooseNewPassword')}</h1>
                        <div style={{display: 'flex'}}>
                            <Input validations={[validators.required, validators.passwordRequirements]}
                                   placeholder={t('PASSWORD')}
                                   className="rectangle" name='PASSWORD'
                                   value={this.state.pass}
                                   type={this.state.isSecureNewPass ? "password" : "text"}
                                   onChange={(event) => this.setState({pass: event.target.value})}
                                   style={{paddingLeft: 10, paddingRight: 10, display: 'flex', fontSize: 16}}>
                            </Input>
                            <button className="eyeButton"
                                    onClick={(event) => this.toggleSecureNewPass(event)}>
                                <img src={images().icOpenEyeGray}/>
                            </button>
                        </div>
                    </div>
                    <div style={{marginTop: 20}}>
                        <h1 className={"choosePassword"}>{t('typePasswordAgain')}</h1>
                        <div style={{display: 'flex'}}>
                            <Input validations={[validators.required, validators.passwordRequirements]} placeholder={t('PASSWORD')}
                                   type={this.state.isSecureConfirmPass ? "password" : "text"} className="rectangle"
                                   name='PASSWORD'
                                   value={this.state.confirmPass}
                                   onChange={(event) => this.setState({confirmPass: event.target.value})}
                                   style={{paddingLeft: 10, paddingRight: 10, display: 'flex', fontSize: 16}}>
                            </Input>
                            <button className="eyeButton"
                                    onClick={(event) => this.toggleSecureConfirmPass(event)}>
                                <img src={images().icOpenEyeGray}/>
                            </button>
                        </div>
                    </div>
                    <div style={{marginTop: 20, display: 'flex', justifyContent: 'center'}}>
                        {/* <Button type="submit" style={{border: 0, backgroundColor: 'transparent'}} onClick={(e) => console.log('click2')}> */}
                            <AppButton text={t('buttons.savePassword')} color='blue' onClick={(e) => this.onButtonClick(e)} propsStyle={{width: 250, marginBottom: 30}} />
                        {/* </Button> */}
                    </div>
                    <Popup
                        contentStyle={{borderRadius: 5}}
                        open={this.state.errorMessage}
                        onClose={this.closePopup.bind(this)}
                    >
                        <AppPopup onClose={this.closePopup.bind(this)} message={this.state.errorMessage}/>
                    </Popup>
                </Form>
            </div>
        );
    }

    closePopup() {
        this.setState({errorMessage: null});
    }

    toggleSecureNewPass(event) {
        event.preventDefault();
        this.setState({isSecureNewPass: !this.state.isSecureNewPass});
    }

    toggleSecureConfirmPass(event) {
        event.preventDefault();
        this.setState({isSecureConfirmPass: !this.state.isSecureConfirmPass});
    }

    onSubmit = (event) => {
        console.log('onsubmit')
        
        event.preventDefault();
        const {t} = this.props;
        const {pass, confirmPass} = this.state;

        if (pass !== confirmPass) {
            this.setState({errorMessage: t('passwordDontMatch')});
        } else {
            //const isCharsValid = /^\w+$/.test(pass);
            const isCharsValid = /^(?=.*[a-zA-Z])(?=.*[0-9])/.test(pass);
            const isNumCharsValid = pass.length >= 8;

            // this.setState({isCharsValid: isCharsValid});
            // this.setState({isNumCharsValid: isNumCharsValid});

            if (isCharsValid && isNumCharsValid) {
                //Success
                const {code} = this.props.match.params;
                //this.setState({isLoading: true});
                api.finishRecovery(code, pass, (response, error) => {
                    //this.setState({isLoading: false});
                    if (error) {
                        this.setState({errorMessage: t('errorOccurred')});
                    } else {
                        if (response.error) {
                            //Error
                            this.setState({errorMessage: response.error});
                        } else {
                            //Success
                            this.setState({errorMessage: t('passwordResetSuccessfully')});
                            //DataManager.sharedInstance.onEnter();

                            this.props.history.push('/login');
                        }
                    }
                });
            } else {
                this.setState({errorMessage: t('passwordError')});
            }
        }
    };
}

export default withTranslation('translations')(ChangePassword)
