import React from 'react';
import {withTranslation} from "react-i18next";

import './AccessibilityStatement.css';


class AccessibilityStatement extends React.Component {

    render() {
        const {isLoggedIn, t} =  this.props;
        return (
            <div className="AccessContainer" style={ !isLoggedIn? {marginTop: -50} : {}}>
                <h3 className="AccessContainer_header">{t('AccessibilityStatementTitle')}</h3>
                {t('firstParagraph').split('\n').map( (row, index) =>
                <p key={'firstParagraph'+index}>{row}</p>
                )}
                <h3>{t('howAccessibilityWork')}</h3>
                <div className="AccessContainer_row">
                    <p>{t('howAccessibilityWorkFirstRow')}
                        <a href="https://www.nagich.co.il/" target="_blank" rel="noopener noreferrer">{t('howAccessibilityWorkFirstRowLink')}</a>
                        {t('howAccessibilityWorkFirstRowEnd')}
                    </p>

                </div>
                <p>{t('howAccessibilityWorkSecondRow')}</p>
                <div className="AccessContainer_row">
                    <p>{t('howAccessibilityWorkThirdRow')}
                        <a href="https://www.nagich.co.il/html5/sbs.py?_id=8684&did=5905&G=8684"
                           target="_blank"
                           rel="noopener noreferrer">{t('howAccessibilityWorkThirdRowLink')}</a>
                    </p>

                </div>
                <p>{t('howAccessibilityListHeader')}</p>
                <ul>
                    {t('howAccessibilityWorkList').split('\n').map( (row, index) =>
                        <li key={'howAccessibilityWorkList'+index}>{row}</li>
                    )}
                </ul>
                <h3>{t('accessibilityIssues')}</h3>
                <p>{t('accessibilityIssuesRow')}</p>

                <div className="AccessContainer_contacts">
                    <div className="AccessContainer_row">
                        <p>{t('accessibilityContactName')}&nbsp;</p>
                        <p style={{fontWeight: 'bold'}}>{t('accessibilityContactNameBold')}</p>
                    </div>
                    <div className="AccessContainer_row">
                        <p>{t('Telephone')}:&nbsp;</p>
                        <p>1-700-50-6565</p>
                    </div>
                    <div className="AccessContainer_row">
                        <p>{t('emailAccessibility')}:</p>
                        <a href="mailto:supportarad@arad.co.il">supportarad@arad.co.il</a>
                    </div>
                </div>
                <p>{t('accessibilityDate')}</p>
            </div>
        );
    }
}

export default withTranslation('translations')(AccessibilityStatement)
