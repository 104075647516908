/* eslint-disable */
import React from 'react';
import images from "../../images";
import {Link} from "react-router-dom";
import './ForgotPassword.css';
import { Mobile} from "../../App";
import * as validators from "../../validators";
import Input from 'react-validation/build/input';
import Form from 'react-validation/build/form';
import Button from 'react-validation/build/button';
import * as api from "../../api";

import Popup from "reactjs-popup";
import AppPopup from "../../components/AppPopup";
import {withTranslation} from "react-i18next";

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            errorMessage: null
        }
    }

    render() {
        const {t} = this.props
        return (
            <div>
                <Mobile>
                    {matches => {
                        if (matches)
                            return this._renderMobile(t);
                        else
                            return this._renderDesktop(t);
                    }}
                </Mobile>
            </div>
        );
    }

    _renderMobile(t) {
        return (
            <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginLeft: 20,
                marginRight: 20
            }}>
                <h2 className={"mainTitleMob"} style={{fontSize: 22}}>{t('resetPassword')}</h2>
                <h3 className="Enter-your-e-mail-below-and-we-will-send-you-the-i">{t('sendInstructions')}</h3>
                <Form ref={c => { this.form = c }} method={"post"} onSubmit={this.onSubmit.bind(this)}>
                    <div style={{margin: 10, marginTop: 30}}>
                        <Input validations={[validators.required, validators.email]} placeholder={t('mail')}
                               type="email"
                               className="Rectangle rectangleMobile" name='email' value={this.state.email}
                               onChange={(event) => this.setState({email: event.target.value})}
                               style={{paddingLeft: 10, paddingRight: 10, display: 'flex'}}>
                        </Input>
                    </div>
                    <div style={{margin: 30, display: 'flex', justifyContent: 'center'}}>
                        {/*<Button>*/}
                            <Button className="blueButton btn_send_instructions">
                                <div onClick={()=> this.form.validateAll()}>{t('resetPassword').toUpperCase()}</div>
                            </Button>
                        {/*</Button>*/}
                    </div>
                </Form>
                <Link to='/'>{t('cancel')}</Link>
                <Popup
                    contentStyle={{borderRadius: 5}}
                    open={this.state.errorMessage}
                    onClose={this.closePopup.bind(this)}
                >
                    <AppPopup onClose={this.closePopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }

    _renderDesktop(t) {
        return (
            <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30}}>
                <h1 className={"title"}>{t('resetPassword')}</h1>
                <h3 className="Enter-your-e-mail-below-and-we-will-send-you-the-i">{t('sendInstructions')}</h3>
                <Form ref={c => { this.form = c }} method={"post"} onSubmit={this.onSubmit.bind(this)}>
                    <div style={{marginTop: 20}}>
                        <Input validations={[validators.required, validators.email]} placeholder={t('mail')}
                               type="email"
                               className="Rectangle" name='email' value={this.state.email}
                               onChange={(event) => this.setState({email: event.target.value})}
                               style={{paddingLeft: 10, paddingRight: 10, display: 'flex'}}>
                        </Input>
                    </div>
                    <div style={{marginTop: 40, marginBottom: 40, display: 'flex', justifyContent: 'center'}}>
                            <Button className="blueButton btn_send_instructions">
                                <div onClick={()=> this.form.validateAll()}>{t('resetPassword').toUpperCase()}</div>
                            </Button>
                    </div>
                    <Popup
                        contentStyle={{borderRadius: 5}}
                        open={this.state.errorMessage}
                        onClose={this.closePopup.bind(this)}
                    >
                        <AppPopup onClose={this.closePopup.bind(this)} message={this.state.errorMessage}/>
                    </Popup>
                </Form>
                <Link to='/'>{t('cancel')}</Link>
            </div>
        );
    }

    closePopup() {
        this.setState({errorMessage: null});
    }

    onSubmit = (event) => {
        event.preventDefault();

        //this.setState({isLoading: true});
        api.recoverPassword(this.state.email, (res, error) => {
            //this.setState({isLoading: false});
            if (error) {
                this.setState({errorMessage: 'Password recovery failed.'});
            } else {
                if (res.error) {
                    this.setState({errorMessage: res.error});
                } else {
                    this.props.history.push(`/password/code`);
                }
            }
        })

    };
}

export default withTranslation('translations')(ForgotPassword)
