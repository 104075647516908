import React from 'react';
import {withTranslation} from "react-i18next";

import './AlertInfo.css';
import Reply from "./Reply";
import * as api from "../../api";

class AlertInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: '',
            isAlertsView: true,
            activeAlertIndex: 0,
            alerts: []
        };
    }

    componentDidMount() {
        if (typeof this.props.history.location.state === 'undefined') {
            this.getAlert();
        } else {
            this.setState({
                alerts: [this.props.history.location.state.alert]
            })
        }
    }

    getAlert = async () => {
        if (this.props.alerts.length === 0) return;

        const alerts = this.props.alerts;

        const path = this.props.history.location.pathname.split('/'); 
        const alertId = path[path.length - 1];

        const alert = alerts.find(data => data.logId === alertId);

        this.setState({
          alerts: [alert]
        });
    }

    handleDeleteAnItem = (id, itemId) => {
        const {t} = this.props;
        console.log('handleDeleteAnItem')
            api.confirmAlert(itemId, (res, err) => {
                if (err) {
                    this.setState({errorMessage: t('CouldNotConfirmAlert')});
                } else {
                    if (res.error) {
                        this.setState({errorMessage: res.error});
                    } else {
                        this.props.confirmAlert();
                        const index = this.props?.alerts.findIndex(alert => +alert.logId === +this.props.match.params.id)
                        let alerts = [...this.props?.alerts];
                        alerts.splice(index, 1);
                        this.props.history.goBack();
                        this.props.handleStateUpdate({alerts});
                    }
                }
            })
    }


    render() {
        return (
            <div className="alertContainer">
                <Reply {...this.props}
                       {...this.state}
                       handleDeleteAnItem={this.handleDeleteAnItem}
                       backHistory={true}
                />
            </div>
        );
    }
}


export default withTranslation('translations')(AlertInfo)
