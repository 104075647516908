/* eslint-disable */
import React from 'react';
import images from '../../images';
import '../../App.css';
import './AdminLogin.css';
import {Link} from 'react-router-dom';

import Input from 'react-validation/build/input';
import Form from 'react-validation/build/form';
import Button from 'react-validation/build/button';
import * as validators from '../../validators';
import * as api from '../../api';

import PersistenceManager from '../../managers/PersistenceManager';
import globals from '../../globals';
import { Mobile} from '../../App';
import {ClipLoader} from 'react-spinners';
import DataManager from '../../managers/DataManager';
import Popup from "reactjs-popup";
import AppPopup from "../../components/AppPopup";
import AppButton from "../../components/AppButton";
import ReCAPTCHA from "react-google-recaptcha";
import queryString from 'query-string';
import {withTranslation} from "react-i18next";

//genichm@gmail.com / 123qwe!@#
class AdminLogin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            isLoading: false,
            errorMessage: null,
            isSecurePass: true,
            captchaResponse: null
        };
    }

    componentWillMount() {
        if (this.props.onEnter) this.props.onEnter(true);

        let params = queryString.parse(this.props.location.search);
        if (params && params.session_id) {
            this.setState({sessionId: params.session_id})
        }
    }

    render() {
        const {t} = this.props
        return (
            <div>
                <Mobile>
                    {matches => {
                        if (matches) return this._renderMobile(t);
                        else return this._renderDesktop(t);
                    }}
                </Mobile>
            </div>
        );
    }

    _renderMobile(t) {
        return (
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: -66
                }}
            >
                {this.state.isLoading && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                            flex: 1,
                            backgroundColor: 'black',
                            opacity: 0.3
                        }}
                    />
                )}
                <div
                    className={'mainTitleMob'}
                    style={{marginTop: 10, fontSize: 16}}
                >
                    Admin Log In
                </div>
                <Form onSubmit={this.onSubmit.bind(this)} method={'post'}>
                    <div style={{margin: 10}}>
                        <h1 className={'Registered-e-mail'}>
                            {t('USERNAME')}
                        </h1>
                        <Input
                            validations={[
                                validators.required,
                                validators.email
                            ]}
                            placeholder={t('email')}
                            type="email"
                            className="rectangleMobile"
                            name='email'
                            value={this.state.email}
                            onChange={event =>
                                this.setState({email: event.target.value})
                            }
                            style={{
                                paddingLeft: 10,
                                paddingRight: 10,
                                display: 'flex'
                            }}
                        />
                    </div>
                    <div style={{margin: 10}}>
                        <h1 className={'Registered-e-mail'}>
                            {t('PASSWORD')}
                        </h1>
                        <div
                            style={{
                                flexDirection: 'row',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Input
                                validations={[validators.required]}
                                placeholder={t('PASSWORD')}
                                className="rectangleMobile"
                                name='PASSWORD'
                                value={this.state.password}
                                onChange={event =>
                                    this.setState({
                                        password: event.target.value
                                    })
                                }
                                type={
                                    this.state.isSecurePass
                                        ? 'password'
                                        : 'text'
                                }
                                style={{
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    display: 'flex'
                                }}
                            />
                            <button
                                type="button"
                                disabled={this.state.isLoading}
                                className="eyeButton"
                                onClick={event => this.toggleSecurePass(event)}
                            >
                                <img src={images().icOpenEyeGray}/>
                            </button>
                        </div>
                    </div>
                    <div
                        style={{
                            marginTop: 10,
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'row',
                            marginBottom: -20
                        }}
                    >
                        <Button
                            disabled={this.state.isLoading}
                            type="submit"
                            style={{
                                border: 0,
                                backgroundColor: 'transparent'
                            }}
                        >
                            <AppButton text={t('buttons.logIn')} color='blue' onClick={() => null} propsStyle={{marginBottom: 25}} />
                        </Button>
                    </div>
                </Form>
                <Link to="/password/recovery">{t('ForgotPassword')}</Link>
                <ClipLoader
                    style={{marginTop: 10}}
                    color={'white'}
                    loading={this.state.isLoading}
                />
                <Popup
                    contentStyle={{borderRadius: 5}}
                    open={this.state.errorMessage}
                    onClose={this.closePopup.bind(this)}
                >
                    <AppPopup onClose={this.closePopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }

    _renderDesktop(t) {
        return (
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                {/*<AlertContainer ref={a => this.msg = a} {...this.alertOptions} />*/}
                {this.state.isLoading && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                            flex: 1,
                            backgroundColor: 'black',
                            opacity: 0.3
                        }}
                    />
                )}
                <h1 className={'title'}>{t('AdminLogIn')}</h1>
                <Form onSubmit={this.onSubmit.bind(this)} method={'post'}>
                    <div style={{marginTop: 20}}>
                        <h1 className={'Registered-e-mail'}>
                            {t('USERNAME')}
                        </h1>
                        <Input
                            validations={[
                                validators.required,
                                validators.email
                            ]}
                            placeholder={t('USERNAME')}
                            type="email"
                            className="Rectangle"
                            name='USERNAME'
                            value={this.state.email}
                            onChange={event =>
                                this.setState({email: event.target.value})
                            }
                            style={{
                                paddingLeft: 10,
                                paddingRight: 10,
                                display: 'flex'
                            }}
                        />
                    </div>
                    <div style={{marginTop: 20}}>
                        <h1 className={'Registered-e-mail'}>
                            {t('PASSWORD')}
                        </h1>
                        <div
                            style={{
                                flexDirection: 'row',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Input
                                validations={[validators.required]}
                                placeholder={t('PASSWORD')}
                                className="Rectangle"
                                name='PASSWORD'
                                value={this.state.password}
                                type={
                                    this.state.isSecurePass
                                        ? 'password'
                                        : 'text'
                                }
                                onChange={event =>
                                    this.setState({
                                        password: event.target.value
                                    })
                                }
                                style={{
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    display: 'flex'
                                }}
                            />
                            <button
                                type="button"
                                disabled={this.state.isLoading}
                                className="eyeButton"
                                onClick={event => this.toggleSecurePass(event)}
                            >
                                <img src={images().icOpenEyeGray}/>
                            </button>
                        </div>
                    </div>
                    <div
                        style={{
                            marginTop: 20,
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <Button
                            disabled={this.state.isLoading || !this.state.captchaResponse}
                            type="submit"
                            style={{
                                border: 0,
                                opacity: this.state.captchaResponse ? 1 : 0.4,
                                backgroundColor: 'transparent'
                            }}
                        >
                            <AppButton text={t('buttons.logIn')} color='blue' onClick={() => null} propsStyle={{marginBottom: 25}} />
                        </Button>
                    </div>
                </Form>
                <ReCAPTCHA
                    sitekey={globals.RECAPTCHA_SITE_KEY}
                    onChange={(captchaResponse) => {
                        console.log('reCaptcha = ' + captchaResponse);
                        this.setState({captchaResponse})
                    }}
                />
                <div style={{marginTop: 10}}>
                    <ClipLoader
                        color={'white'}
                        loading={this.state.isLoading}
                    />
                </div>
                <Popup
                    contentStyle={{borderRadius: 5}}
                    open={this.state.errorMessage}
                    onClose={this.closePopup.bind(this)}
                >
                    <AppPopup onClose={this.closePopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }

    closePopup = () => {
        this.setState({errorMessage: null});
    };

    toggleSecurePass(event) {
        event.preventDefault();
        if (event.detail !== 0) {
            this.setState({isSecurePass: !this.state.isSecurePass});
        }
    }

    onSubmit = event => {
        event.preventDefault();
        const {t} = this.props;

        const {from} = this.props.location.state || {
            from: {pathname: '/home'}
        };
        const {email, password, captchaResponse} = this.state;

        this.setState({isLoading: true});
        api.adminLogin(email, password, captchaResponse, (response, error) => {
            this.setState({isLoading: false});
            if (error) {
                console.log('Unknown error has occurred.');
                this.setState({errorMessage: t('errorOccurred')});
            } else {
                if (response.error) {
                    console.log(response.error);
                    this.setState({errorMessage: response.error});
                } else {
                    const token = response.token;
                    api.setTokenValue(token);

                    this.setState({isLoading: true});
                    api.getLoginCredentials(this.state.sessionId, (response, error) => {
                            this.setState({isLoading: false});

                            if (error) {
                                console.log('Unknown error has occurred.');
                                this.setState({errorMessage: t('errorOccurred')});
                                // this.msg.show('Unknown error has occurred.', {
                                //     time: 2000,
                                //     type: 'info'
                                // })
                            } else {
                                if (response.error) {
                                    console.log(response.error);
                                    this.setState({errorMessage: response.error});
                                } else {
                                    const token = response.token;
                                    console.log('token = ' + token);
                                    api.setTokenValue(token);
                                    DataManager.sharedInstance.isAuthenticated = true;

                                    PersistenceManager.storeItem(globals.STORAGE.TOKEN, token);

                                    this.setState({isLoading: true});
                                    api.getMe((res, err) => {
                                        this.setState({isLoading: false});
                                        if (err) {
                                            console.log('Unknown error has occurred.');
                                        } else {
                                            if (res.error) {
                                                console.log(res.error);
                                            } else {
                                                DataManager.sharedInstance.user = res;
                                                DataManager.sharedInstance.isAuthenticated = true;

                                                this.props.history.push(from.pathname);
                                                this.props.onLogin();
                                            }
                                        }
                                    });
                                }
                            }
                        }
                    );
                }
            }
        });
    }
}

export default withTranslation('translations')(AdminLogin)
