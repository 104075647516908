/* eslint-disable */
import React from 'react';
import {withTranslation} from "react-i18next";
import moment from 'moment';
import DatePicker from "react-date-picker";
import Toggle from 'react-toggle';
import Popup from 'reactjs-popup';

import './DefineVacationPopup.css';
import utils, {AlertType} from '../../utils';
import * as api from '../../api';
import {Mobile} from '../../App';
import images from '../../images';
import globals from '../../globals';
import AppPopup from "../../components/AppPopup";
import i18next from "../../i18n";

import AppButton from "../../components/AppButton";

class DefineVacationPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            smsAlert: false,
            emailAlert: false,
            consumptionDailyLimit: '',
            startDate: '',
            endDate: '',
            vacationID: '',
            openCalendarPicker: false,
            errorMessage: null,
            createVacation: false,
        };

        this.tomorrowDate = new Date();
        this.tomorrowDate.setDate(this.tomorrowDate.getDate() + 1);
    }

    componentWillMount() {
        const {vacation} = this.props;

        this.setState({
            consumptionDailyLimit: vacation? vacation.consumptionDailyLimit : '',
            startDate: vacation? vacation.startDate : '',
            endDate: vacation? vacation.endDate : '',
            vacationID: vacation? vacation.vacationID : '',
            smsAlert: !vacation,
            emailAlert: !vacation,
        })

        if (vacation) {
            api.getAlertSettings((res, err) => {
                //this.setState({isLoading: false});
                if (err) {
                    console.log('Unknown error has occurred.');
                } else {
                    if (res.error) {
                        console.log(res.error);
                    } else {
                        console.log('result in getAlertSettings', res)
                        if (res.length > 0) {
                            let emailAlert = false;
                            let smsAlert = false;

                            for (let alertSetting of res) {
                                if (
                                    alertSetting.alertTypeId ===
                                    AlertType.Consumption_During_Vacation
                                ) {
                                    alertSetting.mediaTypeId === 1
                                        ? (emailAlert = true)
                                        : (smsAlert = true);
                                }
                            }

                            this.setState({emailAlert, smsAlert});
                        }
                    }
                }
            });
        }

        if (typeof vacation?.startDate === 'undefined') {
            this.setState({
                startDate: this.tomorrowDate,
                endDate: this.tomorrowDate
            });
        }
    }

    render() {
        const {t} = this.props
        return (
            <div>
                <Mobile>
                    {matches => {
                        if (matches) return this._renderMobile(t);
                        else return this._renderDesktop(t);
                    }}
                </Mobile>
            </div>
        );
    }

    _renderMobile(t) {
        const {units} = this.props;
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 10,
                    paddingLeft: 20
                }}
            >
                <h1 className={'setMonthlyTitle'}>
                    {t('setMonthlyTitle')}
                </h1>
                <div
                    style={{
                        marginTop: 0,
                        display: 'flex'
                    }}
                >
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                        <div className="dailyUsage">{t('DailyUsageLimit')}</div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: 8
                            }}
                        >
                            <input min="0" step="0.1" placeholder="0.0"
                                onKeyDown={event => {if (event.key === "e") event.preventDefault()}} 
                                onChange={event =>
                                    this._onChangeLimit(event.target.value)
                                }
                                type="number"
                                className="borderRect"
                                value={this.state.consumptionDailyLimit}
                            />
                            <div
                                style={{marginLeft: 20, marginRight: 20}}
                                className="monthlyLimitUnits"
                            >
                                {units}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dailyUsage" style={{marginTop: 20}}>
                    {t('VacationDates')}
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: 14,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            minWidth: 170,
                        }}
                    >
                        <div className="fromTo">{t('from')}</div>

                        <DatePicker
                            onChange={ startDate => {
                                this.setState({startDate})
                                if (moment(startDate).diff(moment(this.state.endDate)) > 0) {
                                    this.setState({endDate: startDate});
                                }
                            }}
                            closeCalendar={false}
                            clearIcon={null}
                            locale={i18next.language}
                            format={i18next.dir() === 'rtl'? "d MMM yyyy" : "dd MMM yyyy"}
                            dayPlaceholder={t('ChooseDate')}
                            monthPlaceholder=""
                            yearPlaceholder=""
                            minDate={this.tomorrowDate}
                            value={this.state.startDate ? utils.toDateFromString(this.state.startDate) : this.tomorrowDate}
                            calendarIcon={<img src={images().icSmallCalendar}/>}
                        />

                    </div>
                    <div className="datePickerDivider"/>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            minWidth: 170,
                        }}
                    >
                        <div className="fromTo">{t('to')}</div>

                        <DatePicker
                            onChange={ endDate => this.setState({endDate}) }
                            closeCalendar={false}
                            clearIcon={null}
                            locale={i18next.language}
                            format={i18next.dir() === 'rtl'? "d MMM yyyy" : "dd MMM yyyy"}
                            dayPlaceholder={t('ChooseDate')}
                            monthPlaceholder=""
                            yearPlaceholder=""
                            minDate={this.state.startDate? utils.toDateFromString(this.state.startDate) : this.tomorrowDate}
                            value={this.state.endDate ? utils.toDateFromString(this.state.endDate) : this.tomorrowDate}
                            calendarIcon={<img src={images().icSmallCalendar}/>}
                        />

                    </div>
                </div>
                <div className="datePickerHorizontalDivider"/>

                <div
                    style={{
                        marginTop: 31,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: 10,
                    }}
                >
                    {/*<div className="monthlyLimitUnits" style={{fontSize:16,}} >{t('ReceiveAlerts')}</div>*/}

                    {/* <div className="toggleContainer">
                        <div className="monthlyLimitToggleContainer">
                            <div
                                className="monthlyLimitUnits"
                                style={{marginLeft: 10, marginRight: 10, fontSize: 16,}}
                            >
                                {t('EmailAlert')}
                            </div>
                            <Toggle
                                icons={false}
                                checked={this.state.emailAlert}
                                onChange={e =>
                                    this._toggleEmailAlertSwitch(e.target.checked)
                                }
                            />
                        </div>
                        <div className="monthlyLimitToggleContainer">
                            <div
                                className="monthlyLimitUnits"
                                style={{marginLeft: 10, marginRight: 10, fontSize: 16,}}
                            >
                                {t('SMSAlert')}
                            </div>
                            <Toggle
                                icons={false}
                                checked={this.state.smsAlert}
                                onChange={e =>
                                    this._toggleSMSAlertSwitch(e.target.checked)
                                }
                            />
                        </div>
                    </div> */}


                </div>
                <div className="Vacation_ButtonsContainer">
                    <button onClick={this.saveVacation.bind(this)}>
                    <AppButton text={t('buttons.save')} color='blue' onClick={() => null} propsStyle={{width: 75, marginBottom: 30}} />
                    </button>
                    <div style={{width: 20}} />
                    <button onClick={this.closePopup.bind(this)}>
                        <AppButton text={t('buttons.cancel')} color='white' onClick={() => null} propsStyle={{width: 75, marginBottom: 30}} />
                    </button>
                </div>

                <Popup
                    contentStyle={{borderRadius: 5}}
                    open={this.state.errorMessage}
                    onClose={this.closeErrorPopup.bind(this)}
                >
                    <AppPopup onClose={this.closeErrorPopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }

    _renderDesktop(t) {
        const {units} = this.props;

        return (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 10,
                    paddingLeft: 50,
                    paddingRight: 50,
                    minWidth: 670,
                }}
            >
                <h1 className="setMonthlyTitle">
                    {t('setMonthlyTitle')}
                </h1>
                <div
                    style={{
                        display: 'flex',
                        marginTop: 0
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            width: '50%'
                        }}
                    >
                        <div className="dailyUsage">{t('DailyUsageLimit')}</div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: 12,
                            }}
                        >
                            <input min="0" step="0.1" placeholder="0.0"
                                   onKeyDown={event => {if (event.key === "e") event.preventDefault()}} 
                                   onChange={event =>
                                       this._onChangeLimit(event.target.value)
                                   }
                                   type="number"
                                   className="borderRect"
                                   value={this.state.consumptionDailyLimit}
                            />
                            <div
                                style={{marginLeft: 20, marginRight: 20}}
                                className="monthlyLimitUnits"
                            >
                                {units}
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: 340,
                        }}
                    >
                        <div className="dailyUsage">{t('VacationDates')}</div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: 10
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: 1,
                                }}
                            >
                                <div style={{fontSize: 14}}
                                     className="fromTo">{t('from')}</div>

                                <DatePicker
                                    onChange={ startDate => {
                                        this.setState({startDate})
                                        if (moment(startDate).diff(moment(this.state.endDate)) > 0) {
                                            this.setState({endDate: startDate});
                                        }
                                    }}
                                    closeCalendar={false}
                                    clearIcon={null}
                                    locale={i18next.language}
                                    format={i18next.dir() === 'rtl'? "d MMM yyyy" : "dd MMM yyyy"}
                                    dayPlaceholder={t('ChooseDate')}
                                    monthPlaceholder=""
                                    yearPlaceholder=""
                                    minDate={this.tomorrowDate}
                                    value={this.state.startDate ? utils.toDateFromString(this.state.startDate) : this.tomorrowDate}
                                    calendarIcon={<img src={images().icSmallCalendar}/>}
                                />

                            </div>
                            <div className="datePickerDivider"/>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: 1,
                                }}
                            >
                                <div style={{fontSize: 14}}
                                     className="fromTo">{t('to')}</div>

                                <DatePicker
                                    onChange={ endDate => this.setState({endDate}) }
                                    closeCalendar={false}
                                    clearIcon={null}
                                    locale={i18next.language}
                                    format={i18next.dir() === 'rtl'? "d MMM yyyy" : "dd MMM yyyy"}
                                    dayPlaceholder={t('ChooseDate')}
                                    monthPlaceholder=""
                                    yearPlaceholder=""
                                    minDate={this.state.startDate? utils.toDateFromString(this.state.startDate) : this.tomorrowDate}
                                    value={this.state.endDate ? utils.toDateFromString(this.state.endDate) : this.tomorrowDate}
                                    calendarIcon={<img src={images().icSmallCalendar}/>}
                                />

                            </div>
                        </div>
                        <div
                            style={{
                                backgroundColor: globals.COLOR.SILVER,
                                height: 1,
                                marginTop: 12
                            }}
                        />
                    </div>
                </div>
                <div
                    style={{
                        marginTop: 20,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {/*<div className="monthlyLimitUnits" style={{fontSize: 16}}>{t('ReceiveAlerts')}</div>*/}
                    {/* <div className="monthlyLimitToggleContainer">
                        <div className="monthlyLimitToggleContainer">
                            <div
                                className="monthlyLimitUnits"
                                style={{marginLeft: 15, marginRight: 15, fontSize: 16,}}
                            >
                                {t('EmailAlert')}
                            </div>
                            <Toggle
                                icons={false}
                                checked={this.state.emailAlert}
                                onChange={e =>
                                    this._toggleEmailAlertSwitch(e.target.checked)
                                }
                            />
                        </div>
                        <div className="monthlyLimitToggleContainer">
                            <div
                                className="monthlyLimitUnits"
                                style={{marginLeft: 15, marginRight: 15, fontSize: 16,}}
                            >
                                {t('SMSAlert')}
                            </div>
                            <Toggle
                                icons={false}
                                checked={this.state.smsAlert}
                                onChange={e =>
                                    this._toggleSMSAlertSwitch(e.target.checked)
                                }
                            />
                        </div>
                    </div> */}
                </div>
                <div className="Vacation_ButtonsContainer">
                    <button onClick={this.saveVacation.bind(this)}>
                        <AppButton text={t('buttons.save')} color='blue' onClick={() => null} propsStyle={{width: 160, marginBottom: 30}} />
                    </button>
                    <div style={{width: 40}} />
                    <button onClick={this.closePopup.bind(this)}>
                        <AppButton text={t('buttons.cancel')} color='white' onClick={() => null} propsStyle={{width: 160, marginBottom: 30}} />
                    </button>
                </div>

                {/*<Popup*/}
                {/*    contentStyle={{borderRadius: 5}}*/}
                {/*    open={this.state.openCalendarPicker}*/}
                {/*    closeOnDocumentClick={false}*/}
                {/*>*/}
                {/*    <CalendarRangePicker*/}
                {/*        isVacation*/}
                {/*        startDate={new Date(this.state.startDate)}*/}
                {/*        endDate={new Date(this.state.endDate)}*/}
                {/*        onClose={this.closeCalendarPicker.bind(this)}*/}
                {/*    />*/}
                {/*</Popup>*/}
                <Popup
                    contentStyle={{borderRadius: 5}}
                    open={this.state.errorMessage}
                    onClose={this.closeErrorPopup.bind(this)}
                >
                    <AppPopup onClose={this.closeErrorPopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }

    closeErrorPopup = () => {
        this.setState({errorMessage: null});
    };

    _onChangeLimit = limit => {
        // limit = limit.replace(/(\.\d{1})\d+/g, '$1');
        if (typeof +limit === 'number' || !limit) {
            if (limit.includes('.') && limit.split('.')[1] !== null) {
                limit = limit.split('.')[0] + '.' + limit.split('.')[1].split('')[0]
            }
            limit = Math.abs(limit)
                this.setState({consumptionDailyLimit: limit? +limit : ''});
            }

    };

    closeCalendarPicker = (startDate, endDate) => {
        this.setState({
            openCalendarPicker: false,
            startDate: startDate ? startDate : this.state.startDate,
            endDate: endDate ? endDate : this.state.endDate
        });
    };

    closePopupSave = vacation => {
        this.props.onClose(vacation);
    };

    closePopup = () => {
        this.props.onClose();
    };

    saveVacation = () => {
        const {t} = this.props;
        if (
            !this.state.startDate ||
            this.state.startDate === '' ||
            !this.state.endDate ||
            this.state.endDate === ''
        ) {
            this.setState({errorMessage: t('invalidDates')});
            return;
        }

        const startOffset = Math.ceil(moment.parseZone(this.state.startDate).utcOffset() / 60);
        const endOffset = Math.ceil(moment.parseZone(this.state.endDate).utcOffset() / 60);

        if (this.state.vacationID) {
            //Edit
            
            api.editVacation(
                this.state.vacationID,
                moment.utc(this.state.startDate).add(startOffset, 'hours').format(globals.DATE.STANDARD_FORMAT),
                moment.utc(this.state.endDate).add(endOffset, 'hours').format(globals.DATE.STANDARD_FORMAT),
                this.state.consumptionDailyLimit? this.state.consumptionDailyLimit : 0,
                (res, err) => {
                    if (err) {
                        this.setState({errorMessage: t('errorOccurred')});
                    } else {
                        if (res.error) {
                            this.setState({errorMessage: res.error});
                        } else {
                            const vacation = {
                                startDate: this.state.startDate,
                                endDate: this.state.endDate,
                                vacationID: this.state.vacationID,
                                consumptionDailyLimit: this.state.consumptionDailyLimit? this.state.consumptionDailyLimit : 0
                            };
                            this.closePopupSave(vacation);
                        }
                    }
                }
            );
        } else {
            //Create
            api.addVacation(
                moment.utc(this.state.startDate).add(startOffset, 'hours').format(globals.DATE.STANDARD_FORMAT),
                moment.utc(this.state.endDate).add(endOffset, 'hours').format(globals.DATE.STANDARD_FORMAT),
                this.state.consumptionDailyLimit? this.state.consumptionDailyLimit : 0,
                (res, err) => {
                    this.setState({isLoading: false});
                    if (err) {
                        this.setState({errorMessage: t('errorOccurred')});
                    } else {
                        if (res.error) {
                            this.setState({errorMessage: res.error});
                        } else {
                            const vacationID = res.newVacationId;
                            const vacation = {
                                startDate: this.state.startDate,
                                endDate: this.state.endDate,
                                vacationID: vacationID,
                                consumptionDailyLimit: this.state.consumptionDailyLimit? this.state.consumptionDailyLimit: 0,
                            };

                            this.closePopupSave(vacation);
                        }
                    }
                }
            );
        }
    };

    onChooseDate() {
        this.setState({openCalendarPicker: true});
    }

    _toggleSMSAlertSwitch(smsAlert) {
        this.setState({smsAlert});
    }

    _toggleEmailAlertSwitch(emailAlert) {
        this.setState({emailAlert});
    }
}

export default withTranslation('translations')(DefineVacationPopup)
