/* eslint-disable */
import React, { PureComponent } from "react";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import { withTranslation } from "react-i18next";
import i18next from "../i18n";

import globals from "../globals";
import utils from "../utils";
import { Mobile } from "../App";
import "./MeterChart.css";

const Statistics = ({ data, t, units, daily }) => {
  return (
    <div
      className="chartLegend_Wrapper"
      style={{ marginTop: daily ? -85 : -120 }}
    >
      <div className="chartLegendColumnDescription">
        {daily ? (
          <>
            <div>
              <div className="realBox" />
              <p>{t("realConsumption")}</p>
            </div>
            <div className="chartLegendColumnDescription_divider" />
            <div>
              <div className="estimateBox" />
              <p>{t("estimateConsumption")}</p>
            </div>
          </>
        ) : (
          <>
            <div>
              <div className="belowBox" />
              <p>{t("ConsumptionBelowLimit")}</p>
            </div>
            <div className="chartLegendColumnDescription_divider" />
            <div>
              <div className="underBox" />
              <p>{t("ConsumptionOverLimit")}</p>
            </div>
          </>
        )}
      </div>

      <div className="chartLegendStatistics">
        <div className="chartLegendItem">
          <div>{t("Minimum")}</div>
          <div className="chartLegend_ValuesContainer">
            <div style={{direction: 'ltr'}}>
              {/* {data
                                .reduce(
                                    (min, val) =>
                                        val < min ? val : min,
                                    data[0]
                                )?.toFixed(2)} */}
              {utils.getFormattedConsumption(
                data.reduce((min, val) => (val < min ? val : min), data[0])
              )}
            </div>
            <div className="chartLegendUnits">{units}</div>
          </div>
        </div>
        <div className="chartLegendItem">
          <div>{t("Maximum")}</div>

          <div className="chartLegend_ValuesContainer">
            <div style={{direction: 'ltr'}}>
              {/* {data
                .reduce((max, val) => (val > max ? val : max), data[0])
                ?.toFixed(2)} */}
                {utils.getFormattedConsumption(data
                .reduce((max, val) => (val > max ? val : max), data[0]))}
            </div>
            <div className="chartLegendUnits">{units}</div>
          </div>
        </div>
        <div className="chartLegendItem">
          <div>{t("Average")}</div>
          <div className="chartLegend_ValuesContainer">
            <div style={{direction: 'ltr'}}>
              {/* {(data.reduce((a, v) => a + v) / data.length)?.toFixed(2)} */}
              {utils.getFormattedConsumption(data.reduce((a, v) => a + v) / data.length)}
            </div>
            <div className="chartLegendUnits">{units}</div>
          </div>
        </div>
        <div className="chartLegendItem">
          <div style={{ color: globals.COLOR.DUSK }}>{t("Total")}</div>
          <div className="chartLegend_ValuesContainer">
            <div style={{direction: 'ltr'}}>
            {/* {data.reduce((a, v) => a + v)?.toFixed(2)} */}
            {utils.getFormattedConsumption(data.reduce((a, v) => a + v))}
            </div>
            <div className="chartLegendUnits">{units}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Legend = ({ entries }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        marginLeft: 25,
        // marginTop: -50
      }}
    >
      {entries?.map((item, index) => {
        return (
          <div
            key={"year" + index}
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: 26,
            }}
          >
            <div
              style={{
                width: 16,
                height: 16,
                backgroundColor: item.color,
              }}
            />
            <div
              style={{
                marginLeft: 14,
                marginRight: 14,
                fontSize: 18,
                color: globals.COLOR.COOL_GRAY,
              }}
            >
              {item.text}
            </div>
          </div>
        );
      })}
    </div>
  );
};

class MeterChart extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { type, timeType, units, selectedMeter, t } = this.props;
    const ticksStyle = {
      fontSize: 18,
      fontFamily:
        i18next.language === "he"
          ? "'OpenSansHebrew', 'sans-serif'"
          : "'Lato', 'sans-serif'",
      fontColor: globals.COLOR.DUSK,
      direction: 'ltr'
    };

    if (type === 0) {
      //My consumptions
      switch (timeType) {
        case 0:
          return this._renderMonthlyChart(units, t, ticksStyle);
        case 1:
          return this._renderDailyChart(units, t, ticksStyle);
      }
    } else if (type === 1) {
      return this._renderYearChart(units, t, ticksStyle);
    }
  }

  _renderYearChart(units, t, ticksStyle) {
    const allYearsData = this.props.yearsConsumptions;
    let yearsArray = [];
    allYearsData.forEach((month) => {
      const monthYear = utils.getYear(month.consDate);
      if (!yearsArray.some((year) => year === monthYear)) {
        yearsArray.push(monthYear);
      }
    });

    const maxValue = Math.max(
      ...allYearsData?.map((item) => +item.cons)
    )?.toFixed();

    let yLabel = null;
    const chartOptions = {
      legend: {
        display: false,
      },
      layout: {
        padding: {
          bottom: 50,
        },
      },
      tooltips: {
        titleAlign: i18next.language === "he" ? "right" : "left",
        callbacks: {
          title: (title, data) => {
            return (
              title[0]?.label +
              " " +
              data.datasets[title[0]?.datasetIndex]?.label
            );
          },
          label: (tooltipItem) => {
            let tooltip = utils.getFormattedConsumption(tooltipItem.yLabel) + '';

            if ((i18next.language === 'he' || i18next.language === 'ar') && tooltip.includes('-')) {
              tooltip = tooltip.replace('-', '') + '-';
            }

            tooltip += " " + units?.toLowerCase();
            
            return tooltip;
          },
        },
      },
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              min: 0,
              callback: function (label) {
                let formattedLabel = label === 0 ? `0 ${units}` : utils.roundDecimal(label, 3);
                if (yLabel === null || yLabel !== formattedLabel) {
                  yLabel = formattedLabel;
                } else if (yLabel === formattedLabel) {
                  return '';
                }

                if ((i18next.language === 'he' || i18next.language === 'ar') && formattedLabel.includes('-')) {
                  formattedLabel = formattedLabel.replace('-', '').replace(' ', '- ');
                }

                return formattedLabel;
              },
              labelOffset: 20,
              ...ticksStyle,
            },
            gridLines: {
              color: "rgba(223,223,223, 0.4)",
            },
            stacked: false,
          },
        ],
        xAxes: [
          {
            barPercentage: 1,
            ticks: {
              beginAtZero: true,
              callback: (label) => {
                return label.toUpperCase();
              },
              ...ticksStyle,
            },
            stacked: false,
            gridLines: {
              display: false,
            },
          },
        ],
      },
    };

    const labels = moment.monthsShort("-MMM-");

    const data = (canvas) => {
      const ctx = canvas.getContext("2d");
      const gradient3 = ctx.createLinearGradient(0, 0, 0, 200);
      gradient3.addColorStop(0, "#3eb3f9");
      gradient3.addColorStop(1, "#2b74fa");

      const gradient2 = ctx.createLinearGradient(0, 0, 0, 200);
      gradient2.addColorStop(0, "#1fede0");
      gradient2.addColorStop(1, "#18b6c0");

      const gradient1 = ctx.createLinearGradient(0, 0, 0, 200);
      gradient1.addColorStop(0, "#c6c6c6");
      gradient1.addColorStop(1, "#8d8d8d");

      const gradient4 = ctx.createLinearGradient(0, 0, 0, 200);
      gradient3.addColorStop(0, "#1F28FF");
      gradient3.addColorStop(1, "#1F28FF");

      const gradients = [gradient1, gradient2, gradient3, gradient4];
      const legendColours = ["#ACACAC", "#1CD3D2", "#3DAEFA", "#1F28FF"];

      let datasets = [];

      yearsArray.forEach((year, index) => {
        let data = [];

        allYearsData.forEach((month) => {
          const monthYear = utils.getYear(month.consDate);
          if (year === monthYear) {
            data[moment(month.consDate).locale('en').format("M") - 1] =
              utils.getFormattedConsumption(month.cons);
          }
        });

        datasets.push({
          label: year,
          backgroundColor: legendColours[index],
          data: data,
        });
      });

      return {
        labels,
        datasets,
      };
    };

    const legendColours = ["#ACACAC", "#1CD3D2", "#3DAEFA", "#1F28FF"];

    return (
      <div style={{ marginTop: 20, marginBottom: 100 }}>
        <Bar data={data} options={chartOptions} width={1000} height={500} />
        <Legend
          entries={yearsArray.map((year, index) => ({
            text: year,
            color: legendColours[index],
          }))}
        />
      </div>
    );
  }

  _renderNeighborChart(units, t, ticksStyle) {
    const data1 = this.props.data;
    const data2 =
      this.props.neighborData?.length > 0
        ? this.props.neighborData?.map((item) => item.avgCons)
        : [];

    let isEmpty = true;

    if (data1 && data1.length > 0) {
      const pivot = Math.floor(data1.length / 2);

      for (let i = 0, j = data1.length - 1; i < pivot, j >= pivot; i++, j--) {
        if (data1[i].cons > 0 || data1[j].cons > 0) {
          isEmpty = false;
          break;
        }
      }
    }

    let yLabel = null;
    const chartOptions = {
      legend: {
        display: false,
      },
      layout: {
        padding: {
          bottom: 100,
        },
      },
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        titleAlign: i18next.language === "he" ? "right" : "left",
        callbacks: {
          label: (tooltipItem, data) => {
            // data for manipulation
            // return Math.round(tooltipItem.yLabel);
            return utils.getFormattedConsumption(tooltipItem.yLabel);
          },
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: !isEmpty ? undefined : 60000,
              callback: function (label) {
                let formattedLabel = label === 0 ? `0 ${units}` : utils.roundDecimal(label, 3);
                if (yLabel === null || yLabel !== formattedLabel) {
                  yLabel = formattedLabel;
                } else if (yLabel === formattedLabel) {
                  return '';
                }

                if ((i18next.language === 'he' || i18next.language === 'ar') && formattedLabel.includes('-')) {
                  formattedLabel = formattedLabel.replace('-', '').replace(' ', '- ');
                }

                return formattedLabel;
              },
              labelOffset: 20,
              ...ticksStyle,
            },
            gridLines: {
              color: "rgba(223,223,223, 0.4)",
            },
            stacked: false,
          },
        ],
        xAxes: [
          {
            barPercentage: 1,
            ticks: {
              beginAtZero: true,
              callback: function (label, index, labels) {
                if (data1 && data1.length > 0) {
                  const date = utils
                    .getMonthName(data1[index].consDate)
                    .toUpperCase();
                  return date;
                }
              },
              ...ticksStyle,
            },
            gridLines: {
              display: false,
            },
            stacked: false,
          },
        ],
      },
    };

    //let labels = moment.monthsShort('-MMM-');
    let labels = [];
    // const startMonth = utils.getMonthDigit(data1[0].consDate);
    // const endMonth = utils.getMonthDigit(data1[data1.length - 1].consDate);

    for (
      let i = 0;
      i <
      utils.getMonthDiff(data1[0].consDate, data1[data1.length - 1].consDate);
      i++
    ) {
      labels.push(utils.getMonthName(data1[i].consDate));
    }

    // if (startMonth && endMonth) {
    //     labels = labels.slice(startMonth-1, endMonth);
    // }

    const data = (canvas) => {
      const ctx = canvas.getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, 200);
      gradient.addColorStop(0, "#3eb3f9");
      gradient.addColorStop(1, "#2b74fa");

      return {
        labels: labels,
        datasets: [
          {
            label: "My usage",
            backgroundColor: gradient,
            pointColor: "#fff",
            pointStrokeColor: "#2b74fa",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "#2b74fa",
            data: data1?.length > 0 ? data1?.map((item) => item.cons) : [],
          },
          {
            label: "Household average usage",
            backgroundColor: "#d9d9d9",
            pointColor: "#fff",
            pointStrokeColor: "#2b74fa",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "#2b74fa",
            data: data2,
          },
        ],
      };
    };

    return (
      <div style={{ marginTop: 20, marginBottom: 100 }}>
        <Bar data={data} options={chartOptions} width={1000} height={500} />
        <Legend
          entries={[
            {
              text: "My usage",
              color: globals.COLOR.DODGER_BLUE_2,
            },
            {
              text: "Household average usage",
              color: globals.COLOR.WHITE_THREE,
            },
          ]}
        />
      </div>
    );
  }

  _renderDailyChart(units, t, ticksStyle) {
    let chartData = this.props.data;
    console.log('_renderDailyChart chartData', chartData);

    if (chartData && chartData.length > 0) {
      //if (!utils.isToday(chartData[0].consDate)) {
      if (utils.isArrAscendingByConsDate(chartData)) {
        chartData.reverse();
      }

      chartData = this.props.data.filter((item, index) => index < 90);

      //if (utils.isToday(chartData[0].consDate)) {
      if (!utils.isArrAscendingByConsDate(chartData)) {
        chartData.reverse();
      }
    }

    const maxValue = Math.max(...chartData?.map((item) => +item.cons))?.toFixed(
      1
    );

    let yLabel = null;
    const chartOptions = {
      legend: {
        display: false,
      },
      layout: {
        padding: {
          bottom: 100, right: 50,
        },
      },
      tooltips: {
        titleAlign: i18next.language === "he" ? "right" : "left",
        callbacks: {
          title: (title) =>
            moment(chartData[title[0].index]?.consDate).format("DD MMM YYYY"),
          label: (tooltipItem) => {
            let tooltip = utils.getFormattedConsumption(tooltipItem.yLabel) + '';

            if ((i18next.language === 'he' || i18next.language === 'ar') && tooltip.includes('-')) {
              tooltip = tooltip.replace('-', '') + '-';
            }

            tooltip += " " + units?.toLowerCase();
            
            return tooltip;
          },
        },
      },
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              // max: maxValue? maxValue : 1,
              // maxTicksLimit: 2,
              // precision: 0,
              callback: function (label) {
                let formattedLabel = label === 0 ? `0 ${units}` : utils.roundDecimal(label, 3);
                if (yLabel === null || yLabel !== formattedLabel) {
                  yLabel = formattedLabel;
                } else if (yLabel === formattedLabel) {
                  return '';
                }

                if ((i18next.language === 'he' || i18next.language === 'ar') && formattedLabel.includes('-')) {
                  formattedLabel = formattedLabel.replace('-', '') + '-';
                }

                return formattedLabel;
              },
              labelOffset: 20,
              ...ticksStyle,
            },
            gridLines: {
              color: "rgba(223,223,223, 0.4)",
            },
          },
        ],
        xAxes: [
          {
            barPercentage: 0.4,
            ticks: {
              beginAtZero: true,
              autoSkip: true,
              lineHeight: 2.5,
              maxRotation: 0,
              // callback: (label) => {
              //     return label[1]? label : label[0]
              // },
              ...ticksStyle,
            },
            stacked: false,
            gridLines: {
              display: false,
            },
          },
        ],
      },
    };

    let isGapNeeded = false;
    const labels = chartData?.map((day, index) => {
      let year = '';

      if (index === 0) {
        year = moment(day.consDate).format("MMM YYYY");
        
        if (moment(day.consDate).daysInMonth() === parseInt(moment(day.consDate).format('D')) && moment(chartData[1]?.consDate).format('D') === moment(chartData[1]?.consDate).startOf('month').format('D')) {
          isGapNeeded = true;
        }
      } else {
        if (isGapNeeded) {
          if (index === 3 && moment(day.consDate).format("M") !== moment(chartData[0]?.consDate).format("M")) {
            year = moment(day.consDate).format("MMM YYYY");
          }
        } else {
          if (moment(day.consDate).format('D') === moment(day.consDate).startOf('month').format('D')) { 
            year = moment(day.consDate).format("MMM YYYY");
          }
        }
      }

      return [moment(day.consDate).format("DD"), year.toUpperCase()];
    });

    console.log('labels', labels);

    const chartShape = this.props.chartShape;
    let sum =
      chartData && chartData.length > 0
        ? chartData.reduce(function (prev, cur) {
            return prev + cur.cons;
          }, 0)
        : 0;

    sum = utils.getFormattedConsumption(sum) + '';

    if ((i18next.language === 'he' || i18next.language === 'ar') && sum.includes('-')) {
      sum = sum.replace('-', '') + '-';
    }

    const data = () => {
      const backgroundColor = chartData?.map((item) =>
        item?.estimationType === 0 ? "#1cd3d2" : "#3daefa"
      );

      return {
        labels,
        datasets: [
          {
            backgroundColor,
            data:
              chartData?.length > 0
                ? chartData?.map((item) => utils.getFormattedConsumption(item?.cons))
                : [],
          },
        ],
      };
    };

    return (
      <Mobile>
        {(matches) => {
          if (matches)
            return (
              <div
                style={{
                  marginTop: 20,
                  marginBottom: 100,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Bar data={data} options={chartOptions} height={500} />
                {chartData && chartData.length > 0 && (
                  <Statistics
                    t={t}
                    data={chartData?.map((item) => (item.cons ? item.cons : 0))}
                    units={units}
                    daily={true}
                  />
                )}
              </div>
            );
          else
            return chartShape === "bar" ? (
              <div style={{ marginTop: 20, marginBottom: 100 }}>
                <Bar
                  data={data}
                  options={chartOptions}
                  width={1000}
                  height={500}
                />
                {chartData && chartData.length > 0 && (
                  <Statistics
                    t={t}
                    data={chartData?.map((item) => (item.cons ? item.cons : 0))}
                    units={units}
                    daily={true}
                  />
                )}
              </div>
            ) : (
              <ul style={{ marginBottom: 100, marginRight: 40 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 8,
                      backgroundColor: "#cdd3db",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        fontSize: 14,
                        color: globals.COLOR.DUSK,
                      }}
                    >
                      {t("Data")}
                    </div>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        fontSize: 14,
                        color: globals.COLOR.DUSK,
                      }}
                    >
                      {t("Consumption")} ({units})
                    </div>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        color: globals.COLOR.DUSK,
                        fontSize: 14,
                      }}
                    >
                      {(this.props.selectedMeter !== null) ? t("Status") : ''}
                    </div>
                    
                  </div>
                  {chartData?.map((item, index) => {
                    let consumption = utils.getFormattedConsumption(item.cons) + '';

                    if ((i18next.language === 'he' || i18next.language === 'ar') && consumption.includes('-')) {
                      consumption = consumption.replace('-', '') + '-';
                    }

                    return (
                      <div
                        key={"dataTable" + index}
                        style={{
                          backgroundColor: globals.COLOR.WHITE_THREE,
                          borderBottomColor: globals.COLOR.COOL_GRAY,
                          borderBottomWidth: 2,
                        }}
                      >
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 8,
                            backgroundColor: "white",
                            marginBottom: 1,
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              color: globals.COLOR.COOL_GRAY,
                              fontSize: 14,
                            }}
                          >
                            {moment(item.consDate).format("DD/MM/YYYY")}
                          </div>
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                // alignItems: 'flex-end',
                                width: 80,
                                fontSize: 14,
                                color: globals.COLOR.COOL_GRAY,
                              }}
                            >
                              {consumption}
                            </div>
                          </div>
                            <div
                            style={{
                              flex: 1,
                              fontSize: 14,
                              display: "flex",
                              color: globals.COLOR.COOL_GRAY,
                            }}
                          >
                            {(this.props.selectedMeter !== null) ? item?.meterStatusDesc ?? t("OK") : ""}
                          </div>
                      
                        </li>
                      </div>
                    );
                  })}
                  <div style={{ marginTop: 10 }}>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                        padding: 8,
                        backgroundColor: "white",
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          color: globals.COLOR.COOL_GRAY,
                          fontWeight: "bold",
                        }}
                      >
                        {t("Total")}
                      </div>
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          color: globals.COLOR.COOL_GRAY,
                        }}
                      >
                        {sum}
                      </div>
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          color: globals.COLOR.COOL_GRAY,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </ul>
            );
        }}
      </Mobile>
    );
  }

  _renderMonthlyChart(units, t, ticksStyle) {
    let chartData = this.props.data;
    console.log('this.props.selectedMeter', this.props.selectedMeter);
    // const maxValue = Math.max(...chartData?.map(item => +item?.cons))?.toFixed()

    let yLabel = null;
    const chartOptions = {
      legend: {
        display: false,
        position: "bottom",
        align: "end",
        rtl: i18next.language === "he",
        reverse: true,
        labels: {
          fontFamily:
            i18next.language === "he"
              ? "'OpenSansHebrew', 'sans-serif'"
              : "'Lato', 'sans-serif'",
          fontSize: 18,
          fontColor: "#46557a",
          boxWidth: 16,
          padding: 60,
        },
      },
      layout: {
        padding: {
          bottom: 100,
        },
      },
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        titleAlign: i18next.language === "he" ? "right" : "left",
        callbacks: {
          title: (title) =>
            moment(chartData[title[0].index].consDate).format("MMM YYYY"),
          label: (tooltipItem) => {
            let tooltip = utils.getFormattedConsumption(tooltipItem.yLabel) + '';

            if ((i18next.language === 'he' || i18next.language === 'ar') && tooltip.includes('-')) {
              tooltip = tooltip.replace('-', '') + '-';
            }

            tooltip += " " + units?.toLowerCase();
          
            // data for manipulation
            // return Math.round(tooltipItem.yLabel) + " " + units?.toLowerCase();
            return tooltip;
            // return tooltipItem.datasetIndex === 0 ?
            //     t('ConsumptionBelowLimit') + ' ' + Math.round(tooltipItem.yLabel) + ' ' + units?.toLowerCase()
            //     : t('ConsumptionOverLimit') + ' ' + Math.round(tooltipItem.yLabel) + ' ' + units?.toLowerCase()
          },
        },
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
              // max: maxValue? maxValue : 1,
              callback: function (label) {
                let formattedLabel = label === 0 ? `0 ${units} ` : utils.roundDecimal(label, 3);
                if (yLabel === null || yLabel !== formattedLabel) {
                  yLabel = formattedLabel;
                } else if (yLabel === formattedLabel) {
                  return '';
                }

                if ((i18next.language === 'he' || i18next.language === 'ar') && formattedLabel.includes('-')) {
                  formattedLabel = formattedLabel.replace('-', '') + '-';
                }

                return formattedLabel;
              },
              ...ticksStyle,
            },
            gridLines: {
              color: "rgba(223,223,223, 0.4)",
            },
          },
        ],
        xAxes: [
          {
            barPercentage: 0.4,
            ticks: {
              beginAtZero: true,
              lineHeight: 2.5,
              ...ticksStyle,
            },
            stacked: true,
            gridLines: {
              display: false,
            },
          },
        ],
      },
    };

    const data = (canvas) => {
      const ctx = canvas.getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, 200);
      gradient.addColorStop(0, "#3eb3f9");
      gradient.addColorStop(1, "#2b74fa");
      const labels = chartData?.map((month, index) => {
        let year =
          !index || +moment(month.consDate).format("M") === 1
            ? moment(month.consDate).format("YYYY")
            : " ";
        return [utils.getMonthName(month.consDate).toUpperCase(), year];
      });

      // console.log('labels', labels)

      return {
        labels,
        datasets: [
          {
            label: t("ConsumptionBelowLimit"),
            backgroundColor: gradient,
            pointColor: "#fff",
            pointStrokeColor: "#2b74fa",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "#2b74fa",
            data:
              chartData?.length > 0
                ? chartData?.map((item) => {
                    if (item === null) {
                      return 0;
                    }
                    return this.props.monthlyLimit > item.cons
                      ? item.cons
                      : this.props.monthlyLimit;
                  })
                : [],
          },
          {
            label: t("ConsumptionOverLimit"),
            backgroundColor: "#ff541e",
            pointColor: "#fff",
            pointStrokeColor: "#ff541e",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "#ff774b",
            data:
              chartData?.length > 0
                ? chartData?.map((item) =>
                    this.props.monthlyLimit < item.cons
                      ? item.cons - this.props.monthlyLimit
                      : 0
                  )
                : [],
          },
        ],
      };
    };

    const chartShape = this.props.chartShape;
    let sum =
      chartData?.length > 0
        ? chartData.reduce(function (prev, cur) {
            return prev + cur.cons;
          }, 0)
        : 0;

    sum = utils.getFormattedConsumption(sum) + '';

    if ((i18next.language === 'he' || i18next.language === 'ar') && sum.includes('-')) {
      sum = sum.replace('-', '') + '-';
    }

    return (
      <Mobile>
        {(matches) => {
          if (matches)
            return (
              <div
                style={{
                  marginTop: 20,
                  marginBottom: 100,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  direction: 'ltr'
                }}
              >
                <Bar data={data} options={chartOptions} height={500} />
                {chartData?.length > 0 && (
                  <Statistics
                    t={t}
                    data={chartData?.map((item) => (item.cons ? item.cons : 0))}
                    units={units}
                  />
                )}
              </div>
            );
          else
            return chartShape === "bar" ? (
              <div style={{ marginTop: 20, marginBottom: 100 }}>
                <Bar
                  data={data}
                  options={chartOptions}
                  width={1000}
                  height={500}
                />
                {chartData?.length > 0 && (
                  <Statistics
                    t={t}
                    data={chartData?.map((item) => (item.cons ? item.cons : 0))}
                    units={units}
                  />
                )}
              </div>
            ) : (
              <ul style={{ marginBottom: 100, marginRight: 40 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 8,
                      backgroundColor: "#cdd3db",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        fontSize: 14,
                        color: globals.COLOR.DUSK,
                      }}
                    >
                      {t("Data")}
                    </div>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          fontSize: 14,
                          color: globals.COLOR.DUSK,
                        }}
                      >
                        {t("Consumption")} ({units})
                      </div>
                    </div>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        color: globals.COLOR.DUSK,
                        fontSize: 14,
                      }}
                    >
                      {/* {t("Status")} */}
                    </div>
                  </div>
                  {chartData?.map((item, index) => {
                    let consumption = utils.getFormattedConsumption(item.cons) + '';

                    if ((i18next.language === 'he' || i18next.language === 'ar') && consumption.includes('-')) {
                      consumption = consumption.replace('-', '') + '-';
                    }

                    return (
                      <div
                        key={"dataTable" + index}
                        style={{
                          backgroundColor: globals.COLOR.WHITE_THREE,
                          borderBottomColor: globals.COLOR.COOL_GRAY,
                          borderBottomWidth: 2,
                        }}
                      >
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 8,
                            backgroundColor: "white",
                            marginBottom: 1,
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              color: globals.COLOR.COOL_GRAY,
                              fontSize: 14,
                            }}
                          >
                            {moment(item.consDate).format("MM/YYYY")}
                          </div>
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                // alignItems: 'flex-end',
                                width: 80,
                                fontSize: 14,
                                color: globals.COLOR.COOL_GRAY,
                              }}
                            >
                              {consumption}
                            </div>
                          </div>
                          <div
                            style={{
                              flex: 1,
                              fontSize: 14,
                              display: "flex",
                              color: globals.COLOR.COOL_GRAY,
                            }}
                          >
                            {/* {item?.meterStatusDesc ?? t("OK")} */}
                          </div>
                        </li>
                      </div>
                    );
                  })}
                  <div style={{ marginTop: 10 }}>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                        padding: 8,
                        backgroundColor: "white",
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          color: globals.COLOR.COOL_GRAY,
                          fontWeight: "bold",
                        }}
                      >
                        {t("Total")}
                      </div>
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          color: globals.COLOR.COOL_GRAY,
                        }}
                      >
                        {sum}
                      </div>
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          color: globals.COLOR.COOL_GRAY,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </ul>
            );
        }}
      </Mobile>
    );
  }
}

export default withTranslation("translations")(MeterChart);
