/* eslint-disable */
import React from 'react';
import Helmet from 'react-helmet';
import DayPicker, {DateUtils} from 'react-day-picker';
import DatePicker from 'react-date-picker';
import 'react-day-picker/lib/style.css';
import moment from "moment";
import MomentLocaleUtils from 'react-day-picker/moment';

import images from "../images";
import {Mobile} from "../App";
import i18next from '../i18n';

import AppButton from "../components/AppButton";

export default class CalendarRangePicker extends React.Component {
    constructor(props) {
        super(props);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleDayMouseEnter = this.handleDayMouseEnter.bind(this);
        this.handleResetClick = this.handleResetClick.bind(this);
        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            from: this.props.startDate,
            to: this.props.endDate,
            enteredTo: this.props.endDate, // Keep track of the last day for mouseEnter.
            singleDay: this.props.singleDay,
            isVacation: this.props.isVacation,
            isMonthSelection: this.props.isMonthSelection,
            shouldSelectMonth: true,
            startOfMonthSelected: false,
            fromMonth: null,
            billingCycle: this.props.billingCycle
        };
    }

    isSelectingFirstDay(from, to, day) {
        const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
        const isRangeSelected = from && to;
        return !from || isBeforeFirstDay || isRangeSelected;
    }

    handleDayClick(day) {
        const {from, to, singleDay, isVacation} = this.state;

        if (isVacation && day <= new Date())
            return;

        if (singleDay) {
            this.setState({
                from: day,
                to: null,
                enteredTo: null,
            });
            return;
        }

        // if (from && to && day >= from && day <= to) {
        //     this.handleResetClick();
        //     return;
        // }

        if (this.isSelectingFirstDay(from, to, day)) {
            this.setState({
                from: day,
                to: null,
                enteredTo: null,
            });

            if (this.state.isMonthSelection && this.state.shouldSelectMonth) {
                if (!this.state.startOfMonthSelected) {
                    //Selecting 'from' month
                    const startOfMonth = moment(day).startOf('month').toDate();
                    this.setState({
                        startOfMonthSelected: !this.state.startOfMonthSelected,
                        shouldSelectMonth: false,
                        fromMonth: startOfMonth
                    }, () => {
                        const endOfMonth = moment(day).endOf('month').toDate();
                        //const nextMonth = moment(day).add(1, 'month').toDate();
                        //this.handleDayClick(nextMonth);

                        this.handleDayClick(startOfMonth);
                        this.handleDayClick(startOfMonth);
                        this.handleDayClick(endOfMonth);
                    });
                } else {
                    //Selecting 'to' month
                    const startOfMonth = this.state.fromMonth;
                    this.setState({
                        startOfMonthSelected: !this.state.startOfMonthSelected,
                        shouldSelectMonth: false,
                        fromMonth: null
                    }, () => {
                        const endOfMonth = moment(day).endOf('month').toDate();

                        this.handleDayClick(startOfMonth);
                        this.handleDayClick(startOfMonth);
                        this.handleDayClick(endOfMonth);
                    });
                }

            } else if (!this.state.shouldSelectMonth) {
                this.setState({shouldSelectMonth: true});
            }

        } else {
            this.setState({
                to: day,
                enteredTo: day,
            });

            if (this.state.isMonthSelection && !this.state.shouldSelectMonth) {
                this.setState({shouldSelectMonth: true});
            }
        }
    }

    handleDayMouseEnter(day) {
        const {from, to, singleDay} = this.state;
        if (singleDay)
            return;

        if (!this.isSelectingFirstDay(from, to, day)) {
            this.setState({
                enteredTo: day,
            });
        }
    }

    handleResetClick() {
        this.setState(this.getInitialState());
    }

    closePopup = () => {
        this.props.onClose();
    };

    saveDates = () => {
        this.props.onClose(this.state.from, this.state.to);
    };

    render() {
        return (
            <div>
                <Mobile>
                    {matches => {
                        if (matches)
                            return this._renderMobile();
                        else
                            return this._renderDesktop();
                    }}
                </Mobile>
            </div>
        );
    }

    _renderMobile() {
        const {from, to, enteredTo, singleDay, isVacation} = this.state;
        const modifiers = {start: from, end: enteredTo};
        const disabledDays = isVacation ? {before: new Date()} : {after: this.state.isMonthSelection
            ? moment().subtract(1, 'months').startOf('month')
            : new Date()};
            console.log(disabledDays)
          const selectedDays = [from, {from, to: enteredTo}];
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <DayPicker
                    initialMonth={moment(from).isValid() ? from : new Date()}
                    className="Range"
                    numberOfMonths={singleDay ? 1 : 2}
                    fromMonth={new Date(2015, 1)}
                    selectedDays={selectedDays}
                    disabledDays={disabledDays}
                    modifiers={modifiers}
                    onDayClick={this.handleDayClick}
                    onDayMouseEnter={this.handleDayMouseEnter}
                    localeUtils={MomentLocaleUtils}
                    locale={i18next.language}
                />
                <div style={{
                    // marginTop: 24,
                    position: 'absolute',
                    bottom: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'row',
                    backgroundColor: 'transparent'
                }}>
                    <button style={{border: 0, backgroundColor: 'transparent'}} onClick={this.saveDates.bind(this)}>
                        <AppButton text={t('buttons.save')} color='blue' onClick={() => null} propsStyle={{width: 75, marginBottom: 30}} />
                    </button>
                    <div style={{width: 20}} />
                    <button style={{border: 0, backgroundColor: 'transparent'}} onClick={this.closePopup.bind(this)}>
                        <AppButton text={t('buttons.cancel')} color='white' onClick={() => null} propsStyle={{width: 75, marginBottom: 30}} />
                    </button>
                </div>
                <Helmet>
                    <style>{`
  .Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .Range .DayPicker-Day {
    border-radius: 0 !important;
  }
`}</style>
                </Helmet>
            </div>
        );
    }

    _renderDesktop() {
        const {from, to, enteredTo, singleDay, isVacation} = this.state;
        const modifiers = {start: from, end: enteredTo};
        let beforeDate = new Date();
        beforeDate.setDate(beforeDate.getDate() + 1);
        beforeDate.setHours(0, 0, 0);
        const disabledDays = isVacation ? {before: beforeDate} : {after: 
        this.state.isMonthSelection
          ? moment().subtract(2, 'months').startOf('month')
          : new Date()};
          console.log('disabledDays', disabledDays)
        const selectedDays = [from, {from, to: enteredTo}];
        return (
            <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', height: 410}}>
                <DayPicker
                    initialMonth={moment(from).isValid() ? from : new Date()}
                    className="Range"
                    numberOfMonths={singleDay ? 1 : 2}
                    // numberOfMonths={2}
                    fromMonth={new Date(2015, 1)}
                    selectedDays={selectedDays}
                    disabledDays={disabledDays}
                    modifiers={modifiers}
                    onDayClick={this.handleDayClick}
                    onDayMouseEnter={this.handleDayMouseEnter}
                    localeUtils={MomentLocaleUtils}
                    locale={i18next.language}
                />

                <div style={{
                    // marginTop: 24,
                    position: 'absolute',
                    bottom: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    backgroundColor: 'transparent'
                }}>
                    <button style={{border: 0, backgroundColor: 'transparent'}} onClick={this.saveDates.bind(this)}>
                        <AppButton text={t('buttons.save')} color='blue' onClick={() => null} propsStyle={{width: 160, marginBottom: 30}} />
                    </button>
                    <div style={{width: 40}} />
                    <button style={{border: 0, backgroundColor: 'transparent'}} onClick={this.closePopup.bind(this)}>
                        <AppButton text={t('buttons.cancel')} color='white' onClick={() => null} propsStyle={{width: 160, marginBottom: 30}} />
                    </button>
                </div>
                <Helmet>
                    <style>{`
  .Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .Range .DayPicker-Day {
    border-radius: 0 !important;
  }
`}</style>
                </Helmet>
            </div>
        );
    }
}
