import * as React from "react";
import moment from 'moment';
import { IntlProvider } from 'react-intl';

import * as api from '../api';
import PersistenceManager from './PersistenceManager';
import globals from '../globals';


export default class DataManager {
    static sharedInstance =
        this.sharedInstance == null ? new DataManager() : this.sharedInstance;

    constructor() {
        const locale = IntlProvider.locale;
        moment.locale(locale);

        this.meters = [];
        this.units = '';
        this.user = { firstName: '', lastName: '', accountNumber: '', phoneNumber: '', municipalId: '' };
        this.vacation = null;
        this.muni = null;
        this.showAlertBadge = false;
        this.sessionId = '';
        this.isAuthenticated = false;
        this.clearedAlerts = [];
        this.loginErrors = {phone: 0, account: 0};
        this.errorCode = 0;
        this.errorMessage = '';
    }

    reset() {
        api.setTokenValue(undefined);
        PersistenceManager.deleteItem(globals.STORAGE.TOKEN);
        this.meters = [];
        this.units = '';
        this.user = { firstName: '', lastName: '', accountNumber: '', phoneNumber: '', municipalId: '' };
        this.vacation = null;
        this.muni = null;
        this.showAlertBadge = false;
        this.sessionId = '';
        this.isAuthenticated = false;
        this.requests = [];
        this.isAlertLoaded = false;
        this.isMessagesAllowed = false;
        this.isRequestsLoaded = false;
        this.errorCode = 0;
        this.errorMessage = '';
    }
}

export const DataContext = React.createContext(DataManager.sharedInstance)
