/* eslint-disable */
import React from 'react';
import './SettingsChangePassword.css';
import images from '../../images';
import '../../App.css';
import * as validators from '../../validators';
import * as api from '../../api';
import Input from 'react-validation/build/input';
import Form from 'react-validation/build/form';
import Button from 'react-validation/build/button';

import {Mobile} from '../../App';
import Popup from "reactjs-popup";
import AppPopup from "../../components/AppPopup";
import {withTranslation} from "react-i18next";

import AppButton from "../../components/AppButton";

class SettingsChangePassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPass: '',
            pass: '',
            confirmPass: '',
            isSecurePass: true,
            isSecureNewPass: true,
            isSecureConfirmPass: true,
            screenOrientation: window.matchMedia("(orientation: portrait)").matches ? 'portrait' : 'landscape',
            errorMessage: null,
            successMessage: null,
        };
    }

    componentDidMount() {
        window.addEventListener('orientationchange', this.setScreenOrientation);
    }

    componentWillUnmount() {
        window.removeEventListener('orientationchange', this.setScreenOrientation);
    }

    setScreenOrientation = () => {
        if (window.matchMedia("(orientation: portrait)").matches) {
            console.log('orientation: portrait');
            this.setState({
                screenOrientation: 'portrait'
            });
        }

        if (window.matchMedia("(orientation: landscape)").matches) {
            console.log('orientation: landscape');
            this.setState({
                screenOrientation: 'landscape'
            });
        }
    };


    render() {
        const {t} = this.props
        return (
            <div style={{display: "flex", justifyContent: "center"}}>
                <Mobile>
                    {matches => {
                        if (matches) return this._renderMobile(t);
                        else return this._renderDesktop(t);
                    }}
                </Mobile>
            </div>
        );
    }

    _renderMobile(t) {
        return (
            <div
                style={{
                    // padding: 10,
                    paddingTop: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    width: "fit-content"
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <img src={images().icBigLock}/>
                    <h1 className={'title'} style={{marginLeft: 15, marginRight: 15, width: 'initial', fontSize: 20,}}>
                        {t('changePassword')}
                    </h1>
                </div>
                <Form ref={c => {
                    this.form = c
                }} method={'post'} onSubmit={this.onSubmit.bind(this)} style={{width: 300}}>
                    <div>
                        <h1 className='choosePassword choosePasswordMob'>
                            {t('curPassword')}
                        </h1>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Input
                                validations={[validators.required]}
                                placeholder={t('PASSWORD')}
                                className="rectangle"
                                name='PASSWORD'
                                value={this.state.currentPass}
                                onChange={event =>
                                    this.setState({
                                        currentPass: event.target.value
                                    })
                                }
                                type={
                                    this.state.isSecurePass
                                        ? 'password'
                                        : 'text'
                                }
                                style={{
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    display: 'flex',
                                    fontSize: 16
                                }}
                            />
                            <button className="eyeButton"
                                    onClick={event => this.toggleSecurePass(event)}
                            >
                                <img src={images().icOpenEyeGray}/>
                            </button>
                        </div>
                    </div>
                    <div style={{marginTop: 10}}>
                        <h1 className={'choosePassword choosePasswordMob'}>
                            {t('choosePassword')}
                        </h1>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Input
                                validations={[validators.required, validators.passwordRequirements]}
                                placeholder={t('PASSWORD')}
                                className="rectangle"
                                name='PASSWORD'
                                value={this.state.pass}
                                onChange={event =>
                                    this.setState({pass: event.target.value})
                                }
                                type={
                                    this.state.isSecureNewPass
                                        ? 'password'
                                        : 'text'
                                }
                                style={{
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    display: 'flex',
                                    fontSize: 16,
                                    marginTop: '2%'
                                }}
                            />
                            <button className="eyeButton"
                                    onClick={event =>
                                        this.toggleSecureNewPass(event)
                                    }
                            >
                                <img src={images().icOpenEyeGray}/>
                            </button>
                        </div>
                    </div>
                    <div
                        className="changePassRequirements"
                        style={{marginTop: '1%'}}
                    >
                        {t('mustContain')}
                    </div>
                    <div
                        className="changePassRequirements"
                        style={{marginTop: 2}}
                    >
                        {t('mustContain8Char')}
                    </div>
                    <div style={{marginTop: '2%'}}>
                        <h1 className={'choosePassword choosePasswordMob'}>
                            {t('typePasswordAgain')}
                        </h1>
                        <div style={{display: 'flex'}}>
                            <Input
                                validations={[validators.required, validators.passwordRequirements]}
                                placeholder={t('PASSWORD')}
                                type={
                                    this.state.isSecureConfirmPass
                                        ? 'password'
                                        : 'text'
                                }
                                className="rectangle"
                                name='PASSWORD'
                                onChange={event =>
                                    this.setState({
                                        confirmPass: event.target.value
                                    })
                                }
                                value={this.state.confirmPass}
                                style={{
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    display: 'flex',
                                    fontSize: 16
                                }}
                            />
                            <button
                                className="eyeButton"
                                onClick={event =>
                                    this.toggleSecureConfirmPass(event)
                                }
                            >
                                <img src={images().icOpenEyeGray}/>
                            </button>
                        </div>
                    </div>
                    {this.state.screenOrientation === 'landscape' ?
                        <div
                            style={{
                                display: 'flex',
                                position: 'absolute',
                                bottom: 10,
                                right: 0,
                                backgroundColor: 'transparent'
                            }}
                        >
                            <Button
                                type="submit"
                                style={{
                                    border: 0,
                                    backgroundColor: 'transparent',
                                    marginLeft: -14,
                                    marginRight: -14,
                                }}
                            >
                                <AppButton text={t('buttons.save')} color='blue' onClick={() => null} propsStyle={{width: 50, marginBottom: 30}} />
                            </Button>
                            <div style={{width: 40}} />
                            <button
                                style={{
                                    border: 0,
                                    backgroundColor: 'transparent',
                                    marginLeft: -14,
                                    marginRight: -14,
                                }}
                            >
                            <AppButton text={t('buttons.cancel')} color='white' onClick={this.closePopup.bind(this)} propsStyle={{width: 50, marginBottom: 30}} />
                            </button>
                        </div>
                        :
                        <div
                            style={{
                                marginTop: 10,
                                display: 'flex',
                                justifyContent: 'center',
                                backgroundColor: 'transparent'
                            }}
                        >
                            <Button
                                style={{
                                    border: 0,
                                    backgroundColor: 'transparent',
                                    marginLeft: -14,
                                    marginRight: -14,
                                }}
                            >
                                <AppButton text={t('buttons.save')} color='blue' onClick={() => this.form.validateAll()} propsStyle={{width: 75, marginBottom: 30}} />
                            </Button>
                            <button
                                style={{
                                    border: 0,
                                    backgroundColor: 'transparent',
                                    marginLeft: -14,
                                    marginRight: -14,
                                }}
                            >
                                <AppButton text={t('buttons.cancel')} color='white' onClick={this.closePopup.bind(this)} propsStyle={{width: 75, marginBottom: 30}} />
                            </button>
                        </div>
                    }
                </Form>
                <Popup
                    contentStyle={{borderRadius: 8, width: 'initial', minWidth: '50%'}}
                    open={this.state.errorMessage}
                    onClose={this.closeErrorPopup.bind(this)}
                >
                    <AppPopup onClose={this.closeErrorPopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
                <Popup
                    contentStyle={{borderRadius: 8, width: 'initial', minWidth: '50%'}}
                    open={this.state.successMessage}
                    onClose={this.closePopup.bind(this)}
                >
                    <AppPopup onClose={this.closePopup.bind(this)} message={this.state.successMessage}/>
                </Popup>

            </div>
        );
    }

    _renderDesktop(t) {
        return (
            <div
                style={{
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: "fit-content"
                }}
            >
                <div style={{display: 'flex'}}>
                    <img src={images().icBigLock}/>
                    <h1 className={'title'} style={{marginLeft: 15, marginRight: 15}}>
                        {t('changePassword')}
                    </h1>
                </div>
                {/*{this.state.successMessage ?*/}
                {/*    <>*/}
                {/*        <p>{this.state.successMessage}</p>*/}
                {/*        */}
                {/*    </>*/}
                {/*    :*/}
                    <Form ref={c => {
                        this.form = c
                    }} method={'post'} onSubmit={this.onSubmit.bind(this)} style={{width: 380}}>
                        <div style={{marginTop: 10}}>
                            <h1 className={'choosePassword'}>
                                {t('curPassword')}
                            </h1>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Input
                                    validations={[validators.required]}
                                    placeholder={t('PASSWORD')}
                                    className="rectangle"
                                    name='PASSWORD'
                                    value={this.state.currentPass}
                                    onChange={event =>
                                        this.setState({
                                            currentPass: event.target.value
                                        })
                                    }
                                    type={
                                        this.state.isSecurePass
                                            ? 'password'
                                            : 'text'
                                    }
                                    style={{
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        display: 'flex',
                                        fontSize: 16
                                    }}
                                />
                                <button className="eyeButton" onClick={event => this.toggleSecurePass(event)}>
                                    <img src={images().icOpenEyeGray}/>
                                </button>
                            </div>
                        </div>
                        <div style={{marginTop: 10, justifyContent: 'center'}}>
                            <h1 className={'choosePassword'}>
                                {t('choosePassword')}
                            </h1>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Input
                                    validations={[validators.required, validators.passwordRequirements]}
                                    placeholder={t('PASSWORD')}
                                    className="rectangle"
                                    name='PASSWORD'
                                    value={this.state.pass}
                                    onChange={event => this.setState({pass: event.target.value})}
                                    type={
                                        this.state.isSecureNewPass
                                            ? 'password'
                                            : 'text'
                                    }
                                    style={{
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        display: 'flex',
                                        fontSize: 16
                                    }}
                                />
                                <button className="eyeButton" onClick={event => this.toggleSecureNewPass(event)}>
                                    <img src={images().icOpenEyeGray}/>
                                </button>
                            </div>
                        </div>
                        <div
                            className="changePassRequirements"
                            style={{marginTop: 10}}
                        >
                            {t('mustContain')}
                        </div>
                        <div
                            className="changePassRequirements"
                            style={{marginTop: 2}}
                        >
                            {t('mustContain8Char')}
                        </div>
                        <div style={{marginTop: 20, justifyContent: 'center'}}>
                            <h1 className={'choosePassword'}>
                                {t('typePasswordAgain')}
                            </h1>
                            <div style={{display: 'flex'}}>
                                <Input
                                    validations={[validators.required, validators.passwordRequirements]}
                                    placeholder={t('PASSWORD')}
                                    type={
                                        this.state.isSecureConfirmPass
                                            ? 'password'
                                            : 'text'
                                    }
                                    className="rectangle"
                                    name='PASSWORD'
                                    onChange={event =>
                                        this.setState({
                                            confirmPass: event.target.value
                                        })
                                    }
                                    value={this.state.confirmPass}
                                    style={{
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        display: 'flex',
                                        fontSize: 16
                                    }}
                                />
                                <button className="eyeButton" onClick={event => this.toggleSecureConfirmPass(event)}>
                                    <img src={images().icOpenEyeGray}/>
                                </button>
                            </div>
                        </div>
                        <div
                            style={{
                                marginTop: 20,
                                display: 'flex',
                                justifyContent: 'center',
                                backgroundColor: 'transparent'
                            }}
                        >
                            <Button
                                style={{
                                    border: 0,
                                    backgroundColor: 'transparent',
                                    marginLeft: -14,
                                    marginRight: -14,
                                }}
                            >
                                <AppButton text={t('buttons.save')} color='blue' onClick={() => this.form.validateAll()} propsStyle={{width: 150, marginBottom: 30}} />
                            </Button>
                            <div style={{width: 40}} />
                            <button
                                style={{
                                    border: 0,
                                    backgroundColor: 'transparent',
                                    marginLeft: -14,
                                    marginRight: -14,
                                }}
                            >
                                <AppButton text={t('buttons.cancel')} color='white' onClick={this.closePopup.bind(this)} propsStyle={{marginBottom: 30, width: 150}} />
                            </button>
                        </div>
                    </Form>
                {/*}*/}
                <Popup
                    contentStyle={{borderRadius: 8, width: 'initial', minWidth: '50%'}}
                    open={this.state.errorMessage}
                    onClose={this.closeErrorPopup.bind(this)}
                >
                    <AppPopup onClose={this.closeErrorPopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
                <Popup
                    contentStyle={{borderRadius: 8, width: 'initial', minWidth: '50%'}}
                    open={this.state.successMessage}
                    onClose={this.closePopup.bind(this)}
                >
                    <AppPopup onClose={this.closePopup.bind(this)} message={this.state.successMessage}/>
                </Popup>
            </div>
        );
    }

    closeErrorPopup() {
        this.setState({errorMessage: null});
    }

    toggleSecurePass(event) {
        event.preventDefault();
        if (event.detail !== 0) {
            this.setState({isSecurePass: !this.state.isSecurePass});
        }
    }

    toggleSecureNewPass(event) {
        event.preventDefault();
        this.setState({isSecureNewPass: !this.state.isSecureNewPass});
    }

    toggleSecureConfirmPass(event) {
        event.preventDefault();
        this.setState({isSecureConfirmPass: !this.state.isSecureConfirmPass});
    }

    onSubmit = event => {
        event.preventDefault();
        const {t} = this.props;

        if (this.state.pass !== this.state.confirmPass) {
            this.setState({errorMessage: t('passwordDontMatch')});
        } else {
            //const isCharsValid = /^\w+$/.test(pass);
            const pass = this.state.pass;
            const isCharsValid = /^(?=.*[a-zA-Z])(?=.*[0-9])/.test(pass);
            const isNumCharsValid = pass.length >= 8;

            if (isCharsValid && isNumCharsValid) {
                api.changePassword(
                    this.state.currentPass,
                    this.state.pass,
                    res => {
                        if (res.error) {
                            this.setState({errorMessage: res.error});
                        } else {
                            this.setState({successMessage: t('PasswordChangedSuccessfully')});
                        }
                    }
                );
            } else {
                this.setState({errorMessage: t('passwordError')});
            }
        }
    };

    closePopup = () => {
        this.props.onClose();
    };
}

export default withTranslation('translations')(SettingsChangePassword)
