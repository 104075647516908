/* eslint-disable */
import React from "react";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import Button from "react-validation/build/button";
import Popup from "reactjs-popup";
import { withTranslation } from "react-i18next";

import images from "../../images";
import "../../App.css";
import "./RegisterAccount.css";
import * as validators from "../../validators";
import * as api from "../../api";
import DataManager from "../../managers/DataManager";
import { Mobile } from "../../App";
import AppPopup from "../../components/AppPopup";

import AppButton from "../../components/AppButton";

class RegisterAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fullName: "",
      municipal: "",
      municipalOptions: null,
      account: "",
      propertyNumber: "",
      errorMessage: null,
      dropDownVisible: false,
      validation: false,
      searchInputText: "",
    };
  }

  componentDidMount() {
    const { t } = this.props;
    api.searchMunicipals(null, (res, err) => {
      if (err) {
        this.setState({ errorMessage: t("errorOccurred") });
      } else {
        if (res.error) {
          console.log(res.error);
          this.setState({ errorMessage: res.error });
        } else {
          this.setState({ municipalOptions: res });
        }
      }
    });
  }

  removeSpecialChars(e) {
    if (!new RegExp("\\w").test(e.key)) {
      e.preventDefault();
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <Mobile>
          {(matches) => {
            if (matches) return this._renderMobile(t);
            else return this._renderDesktop(t);
          }}
        </Mobile>
      </div>
    );
  }

  _renderMobile(t) {
    const { municipalOptions, municipal, account, validation } = this.state;
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginLeft: 20,
          marginRight: 20,
          // marginTop: -36
        }}
      >
        <h1 className={"title"} style={{ fontSize: 22 }}>
          {t("JoinMyWaterAdvisor")}
        </h1>
        <Form
          ref={(c) => {
            this.form = c;
          }}
          method={"post"}
          onSubmit={this.onSubmit.bind(this)}
          className="RegisterForm"
        >
          <div style={{ marginTop: 10 }}>
            <h1
              className="inputTitleMobile"
              // style={{ marginLeft: 30, marginRight: 30 }}
            >
              {t("billMunicipality").toUpperCase()}
            </h1>
            <div className="dropdown">
              <button
                className={
                  "dropdownInput registerDropdown " +
                  (municipal ? "" : "placeholder")
                }
                disabled
              >
                {municipal?.description || t("chooseMunicipality")}
              </button>
              <img
                className="dropdown_button"
                src={images().icDropDownArrow}
                alt=""
              />
              <div className="dropdown-content dropdown-content-register">
                {municipalOptions?.map((municipal) => (
                  <div
                    key={"municipal" + municipal.municipalID}
                    onClick={() => {
                      this.setState({ municipal, validation: false });
                    }}
                  >
                    {municipal.description}
                  </div>
                ))}
              </div>
            </div>
            <span
              className="inputError"
              style={{ display: validation && !municipal ? "initial" : "none" }}
            >
              {t("municipality")}&nbsp;{t("isRequired")}
            </span>
          </div>

          <div style={{ marginTop: 10 }}>
            <h1 className="inputTitleMobile">
              {t("billAccountNumberUpperCase")}
            </h1>
            <Input
              validations={[validators.required]}
              placeholder={t("AccountNumber")}
              onKeyDown={(e) => this.removeSpecialChars(e)}
              type="text"
              className="rectangle rectangleMobile"
              name="AccountNumber"
              onChange={(event) =>
                this.setState({ account: event.target.value })
              }
              value={this.state.account}
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                display: "flex",
                fontSize: 16,
                maxWidth: "initial",
                width: "100%",
              }}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <h1 className="inputTitle">{t("billPropertyName")}</h1>
            <Input
              validations={[validators.required]}
              placeholder={t("propertyNumber")}
              onKeyDown={(e) => this.removeSpecialChars(e)}
              type="text"
              className="rectangle"
              name="propertyNumber"
              value={this.state.propertyNumber}
              onChange={(event) =>
                this.setState({ propertyNumber: event.target.value })
              }
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                display: "flex",
                fontSize: 16,
                maxWidth: "initial",
                width: "100%",
              }}
            />
          </div>
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              style={{
                border: 0,
                backgroundColor: "transparent",
              }}
            >
              <AppButton text={t('buttons.continue')} color='blue' onClick={() => {this.setState({ validation: true }); this.form.validateAll()}} propsStyle={{marginBottom: 30, width: 170}} />
            </Button>
          </div>
        </Form>
        <Popup
          contentStyle={{ borderRadius: 5 }}
          open={this.state.errorMessage}
          onClose={this.closeErrorPopup.bind(this)}
        >
          <AppPopup
            onClose={this.closeErrorPopup.bind(this)}
            message={this.state.errorMessage}
          />
        </Popup>
      </div>
    );
  }

  _renderDesktop(t) {
    const { municipalOptions, municipal, account, validation, propertyNumber } =
      this.state;
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 20,
          // backgroundColor: 'black'
        }}
        // onFocus={() => this.setState({
        //                     dropDownVisible: false,
        //                   })}
        onClick={(e) => {
          e.stopPropagation();
          this.setState({
            dropDownVisible: false,
          });
        }}
      >
        <h1 className={"title"}>{t("JoinMyWaterAdvisor")}</h1>
        <Form
          ref={(c) => {
            this.form = c;
          }}
          method={"post"}
          onSubmit={this.onSubmit.bind(this)}
        >
          <div style={{ marginTop: 20 }}>
            <h1 className={"inputTitle"}>
              {t("billMunicipality").toUpperCase()}
            </h1>
            <div className="dropdown" onClick={(e) => e.stopPropagation()}>
              <input
                type="text"
                className={
                  "dropdownInput registerDropdown " +
                  (municipal ? "" : "placeholder")
                }
                onFocus={(e) =>
                  this.setState({
                    dropDownVisible: true,
                  })
                }
                // onBlur={() =>
                //   this.setState({
                //     dropDownVisible: false,
                //   })
                // }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    console.log("do validate");

                    const newArray = [];
                    municipalOptions
                      ?.sort((a, b) =>
                        a.description.localeCompare(b.description)
                      )
                      ?.map((municipal) => {
                        if (
                          municipal.description
                            .toLowerCase()
                            .includes(this.state.searchInputText.toLowerCase())
                        ) {
                          newArray.push(municipal);
                        }
                      });

                    if (newArray.length === 1) {
                      this.setState({
                        municipal: newArray[0],
                        searchInputText: newArray[0].description,
                        validation: false,
                        dropDownVisible: false,
                      });
                    }
                  }
                }}
                placeholder={t("chooseMunicipality")}
                value={this.state.searchInputText}
                onChange={(e) => {
                  console.log("e", e);
                  this.setState({
                    searchInputText: e.target.value,
                    municipal: "",
                  });
                }}
              />
              {/* <button
                className={
                  "dropdownInput registerDropdown " +
                  (municipal ? "" : "placeholder")
                }
                enabled
                onClick={() => {
                  this.setState({
                    dropDownVisible: !this.state.dropDownVisible,
                  });
                }}
                name={t("chooseMunicipality")}
              >
                {municipal?.description || t("chooseMunicipality")}
              </button> */}
              {this.state.dropDownVisible ? (
                <img
                  className="dropdown_button_close"
                  src={images().icClose}
                  alt=""
                  width={15}
                  height={15}
                  onClick={() =>
                    this.setState({
                      // dropDownVisible: false,
                      searchInputText: "",
                      municipal: "",
                    })
                  }
                />
              ) : (
                <img
                  className="dropdown_button"
                  src={images().icDropDownArrow}
                  alt=""
                  onClick={() =>
                    this.setState({
                      dropDownVisible: true,
                    })
                  }
                />
              )}

              {this.state.dropDownVisible && (
                <div
                  className="dropdown-content dropdown-content-register"
                  style={{
                    maxHeight: '35%',
                    minWidth: 500,
                    maxWidth: 500,
                    overflowY: "scroll",
                    flex: 1,
                  }}
                >
                  {municipalOptions
                    ?.sort((a, b) => a.description.localeCompare(b.description))
                    ?.map((municipal) => {
                      if (
                        municipal.description
                          .toLowerCase()
                          .includes(this.state.searchInputText.toLowerCase())
                      )
                        return (
                          <div
                            key={"municipal" + municipal.municipalID}
                            onClick={() => {
                              console.log("onClick");
                              this.setState({
                                municipal,
                                searchInputText: municipal.description,
                                validation: false,
                                dropDownVisible: false,
                              });
                            }}
                          >
                            {municipal.description}
                          </div>
                        );
                    })}
                </div>
              )}
            </div>
            <span
              className="inputError"
              style={{ display: validation && !municipal ? "initial" : "none" }}
            >
              {t("municipality")}&nbsp;{t("isRequired")}
            </span>
          </div>

          <div style={{ marginTop: 20 }}>
            <h1 className={"inputTitle"}>{t("billAccountNumberUpperCase")}</h1>

            <Input
              validations={[validators.required]}
              placeholder={t("AccountNumber")}
              type="text"
              onKeyDown={(e) => this.removeSpecialChars(e)}
              className="rectangle"
              name="AccountNumber"
              value={account}
              onChange={(event) =>
                this.setState({ account: event.target.value })
              }
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                display: "flex",
                fontSize: 16,
                width: 500,
              }}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <h1 className="inputTitle">{t("billPropertyName")}</h1>
            <Input
              validations={[validators.required]}
              placeholder={t("propertyNumber")}
              onKeyDown={(e) => this.removeSpecialChars(e)}
              type="text"
              className="rectangle"
              name="propertyNumber"
              value={this.state.propertyNumber}
              onChange={(event) =>
                this.setState({ propertyNumber: event.target.value })
              }
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                display: "flex",
                fontSize: 16,
                width: 500,
              }}
            />
          </div>
          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              style={{
                border: 0,
                backgroundColor: "transparent",
              }}
            >
              <AppButton text={t('buttons.continue')} color='blue' onClick={() => {this.setState({ validation: true }); this.form.validateAll()}} propsStyle={{marginBottom: 30, width: 170}} />
            </Button>
          </div>
        </Form>
        <Popup
          contentStyle={{ borderRadius: 5 }}
          open={this.state.errorMessage}
          onClose={this.closeErrorPopup.bind(this)}
        >
          <AppPopup
            onClose={this.closeErrorPopup.bind(this)}
            message={this.state.errorMessage}
          />
        </Popup>
      </div>
    );
  }

  closeErrorPopup() {
    this.setState({ errorMessage: null });
  }

  onSubmit = (event) => {
    event.preventDefault();
    const { t } = this.props;

    //this.setState({isLoading: true});

    if (this.state.municipal) {
      api.registerAccount(
        this.state.propertyNumber,
        this.state.account,
        this.state.municipal?.municipalID,
        this.state.municipal?.siteId,
        (response, error) => {
          //this.setState({isLoading: false});
          if (error) {
            this.setState({ errorMessage: t("errorOccurred") });
          } else {
            if (response.error) {
              //Error
              if (response.code === 10030) {
                DataManager.sharedInstance.loginErrors.account = 1;
                this.props.history.push("/register/account/not_found");
              } else {
                this.setState({ errorMessage: response.error });
              }
            } else {
              DataManager.sharedInstance.sessionId = response.sessionId;
              this.props.history.push("/register/finish");
            }
          }
        }
      );
    }
  };
}

export default withTranslation("translations")(RegisterAccount);
