import React from 'react';
import {withTranslation} from "react-i18next";

import "./Alerts.css"
import MyMessagesList from "./MyMessagesList";
import * as api from "../../api";


class Alerts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAlertsView: true,
            activeMessageIndex: '',
            activeAlertIndex: '',
            errorMessage: '',
            description: '',
            phoneNumber: '',
            previousAlerts: '',
        };
    }

    handleDeleteAnItem = (id, itemId) => {
        const {t} = this.props
        if (this.state.isAlertsView) {
            api.confirmAlert(itemId, (res, err) => {
                if (err) {
                    this.setState({errorMessage: t('CouldNotConfirmAlert')});
                } else {
                    if (res.error) {
                        this.setState({errorMessage: res.error});
                    } else {
                        // this.setState({activeAlertIndex: ''});
                        let alerts = [...this.props?.alerts];
                        // alerts.splice(id, 1);
                        alerts[id].isRead = 'true'
                        this.props.handleStateUpdate({alerts})
                        this.props.confirmAlert();
                    }
                }
            })
        } else {
            this.setState({activeMessageIndex: ''});
            let requests = [...this.props?.requests]
            requests.splice(id, 1);
            this.props.handleStateUpdate({requests})
            this.props.confirmAlert();
        }
    }

    handleActiveMessage = (id, resetID) => {
        const {t} = this.props;
        if (!this.state.isAlertsView && !this.props.requests[id].wasRead && this.props.requests[id].wasRead !== null) {
            api.setMessageWasRead(this.props.requests[id].answerID, (res, err) => {
                if (err) {
                    this.setState({errorMessage: t('errorOccurred')});
                } else {
                    if (res.error) {
                        this.setState({errorMessage: res.error});
                    } else {
                        const requests = [...this.props.requests];
                        requests[id].wasRead = true;
                        this.props.handleStateUpdate({requests});
                        this.props.confirmAlert();
                    }
                }
            })
        }

        // if (this.state.isAlertsView && this.props.alerts[id].isRead !== 'true') {
        //     api.setMessageWasRead(this.props.alerts[id].logId, (res, err) => {
        //         if (err) {
        //             this.setState({errorMessage: t('errorOccurred')});
        //         } else {
        //             if (res.error) {
        //                 this.setState({errorMessage: res.error});
        //             } else {
        //                 const requests = [...this.props.requests];
        //                 requests[id].wasRead = true;
        //                 this.props.handleStateUpdate({requests});
        //                 this.props.confirmAlert();
        //             }
        //         }
        //     })
        // }

        if (resetID) {
            if (this.state.isAlertsView) {
                this.setState({activeAlertIndex: ''})
            } else {
                this.setState({activeMessageIndex: ''})
            }
        } else if (this.state.isAlertsView) {
            this.setState({activeAlertIndex: id})
        } else {
            this.setState({activeMessageIndex: id})
        }
    }

    render() {
        const {t, isMessagesAllowed} = this.props;
        const {isAlertsView, activeMessageIndex, activeAlertIndex} = this.state;
        const hideOnMobile = ((!isAlertsView && activeMessageIndex !== '') || (isAlertsView && activeAlertIndex !== '')) ? " mobileHidden" : "";

        const screenName = <MyMessagesList
            handleActiveMessage={this.handleActiveMessage}
            handleDeleteAnItem={this.handleDeleteAnItem}
            {...this.props}
            {...this.state}/>;

        return (
            <div className="AlertsContainer">
                {/*<div className={"AlertsContainer__title" + hideOnMobile}>{t('RealTimeAlerts')}</div>*/}
                <div className={"AlertsContainer__Navigation" + hideOnMobile}>
                    <div className={"NavButton " + (isAlertsView && "active")}
                         onClick={() => this.setState({isAlertsView: true})}
                    >{t('SystemMessages')}</div>
                    {isMessagesAllowed &&
                    <div className={"NavButton " + (!isAlertsView && "active")}
                         onClick={() => this.setState({isAlertsView: false})}
                    >{t('Requests')}</div>}
                </div>
                {screenName}
            </div>
        );
    }

}

export default withTranslation('translations')(Alerts)
