/* eslint-disable */
import React from 'react';
import globals from "../globals";
import utils from "../utils";
import images from "../images";
import {Mobile} from "../App";
import {withTranslation} from "react-i18next";

 class MeterSelection extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {t} = this.props
        return (
            <div>
                <Mobile>
                    {matches => {
                        if (matches)
                            return this._renderMobile(t);
                        else
                            return this._renderDesktop(t);
                    }}
                </Mobile>
            </div>
        );
    }

    _renderMobile(t) {
        return (
            <div style={{height: '100%'}}>
                <div style={{padding: 20}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                        <div style={{color: globals.COLOR.COOL_GRAY, fontSize: 16}}>{t('ChooseWaterMeter')}</div>
                        <button onClick={() => this.props.onClose()} style={{backgroundColor: 'transparent'}}>
                            <img src={images().icClose} style={{width: 15, height: 15}}/>
                        </button>
                    </div>
                    <div style={{height: 1, backgroundColor: globals.COLOR.COOL_GRAY, marginTop: 10}}/>
                    {this.props.waterMeters.map((item, index) => {
                        return (
                            <div key={'meter'+index} style={{padding: 6}}>
                                <button onClick={() => this.props.onMeterSelected(item)} style={{color: globals.COLOR.DUSK, fontSize: 14, backgroundColor: 'transparent'}}>{utils.getMeterDesc(item)}</button>
                            </div>
                        );
                    })}
                    <button style={{backgroundColor: globals.COLOR.WHITE_TWO, padding: 14, marginTop: 16, width: '100%  '}}
                            onClick={() => this.props.onMeterSelected(null)}>
                        <div style={{color: globals.COLOR.DUSK, fontSize: 14}}>{t('AllWaterMeters')}</div>
                    </button>
                </div>
            </div>
        );
    }

    _renderDesktop(t) {

        return (
            <div style={{ width: "100%", height: "600px", overflowY: "scroll" }}>
                <div style={{padding: 20}}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <div style={{color: globals.COLOR.COOL_GRAY, fontSize: 16}}>{t('ChooseWaterMeter')}</div>
                        <button onClick={() => this.props.onClose()} style={{backgroundColor: 'transparent'}}>
                            <img src={images().icClose} style={{width: 15, height: 15}}/>
                        </button>
                    </div>
                    <div style={{height: 1, backgroundColor: globals.COLOR.COOL_GRAY, marginTop: 10}}/>
                    {this.props.waterMeters.map((item, index) => {
                        return (
                            <div key={'meter'+index} style={{padding: 6}}>
                                <button onClick={() => this.props.onMeterSelected(item)} style={{color: globals.COLOR.DUSK, fontSize: 14}}>{utils.getMeterDesc(item)}</button>
                            </div>
                        );
                    })}
                    <button style={{backgroundColor: globals.COLOR.WHITE_TWO, padding: 14, marginTop: 16, width: '100%  '}}
                                      onClick={() => this.props.onMeterSelected(null)}>
                        <div style={{color: globals.COLOR.DUSK, fontSize: 14}}>{t('AllWaterMeters')}</div>
                    </button>
                </div>
            </div>
        );
    }
}

export default withTranslation('translations')(MeterSelection)
