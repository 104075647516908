/* eslint-disable */
import React from 'react';
import {Mobile} from '../../App';
import './About.css';
import images from '../../images';
import {withTranslation} from "react-i18next";

const About = ({t}) => {

    return (

        <Mobile>
            {matches => (
                <div
                    className={
                        matches ? 'mobileAboutContent' : 'desktopAboutContent'
                    }
                >
                    <div className="aboutContainer">
                        <h3 className="title">{t('AboutMasterMeter')}</h3>
                        <p className="version">v1.0</p>
                        <div className="row">
                            <p className="aboutInfo">
                                {t('aboutText')}
                            </p>
                            <div className="aboutContainer_divider"/>
                            <div className="aboutImg">
                                <img src={images().aboutLogo}/>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Mobile>
    );
};

export default withTranslation('translations')(About);
