import React from 'react';
import {withTranslation} from "react-i18next";

import './TermsOfUse.css';
import images from '../../images';
import '../../App.css';

class TermsOfUse extends React.Component {

    render() {
        const {t} = this.props
        return (
            <div className="termsOfUse_container">
                <button
                    style={{ float: 'right', backgroundColor: 'transparent' }}
                    onClick={() => this.props.onClose()}
                >
                    <img src={images().icClose} alt="close"/>
                </button>
                <p className='termsOfUse'>{t('TermsOfUseTitle')}</p>
                {/*<p className={'websiteTerms'}>{t('websiteTerms')}</p>*/}
                {/*<p className={'acceptance'}>{t('acceptance')}</p>*/}
                {/*<div style={{ float: 'inherit', overflowY: 'auto', height: '100%' }}>*/}
                    <textarea disabled
                        style={{ border: 0, overflowY: 'auto', height: '100%', }}
                        className='termsText'
                    >
                        {t('TermsOfUseText')}
                    </textarea>
                {/*</div>*/}
            </div>
        );
    }
}

export default withTranslation('translations')(TermsOfUse)
