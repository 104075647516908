/* eslint-disable */
import React from 'react';
import {Link} from 'react-router-dom';
import {withTranslation} from "react-i18next";

import images from '../../images';
import './LandingPage.css';
import globals from '../../globals';
import {Mobile, MobileLand} from '../../App';
import i18next from '../../i18n'
import LanguageSwitcher from '../../components/LanguageSwitcher';

class LandingPage extends React.Component {
    constructor(props) {
        super(props);
        this.isLandscape = false;
    }

    componentWillMount() {
        this.props.toggleHeader(false);
    }

    render() {
        const {t} = this.props
        return (
            <div  style={{position: "relative"}}>
                <MobileLand>
                    {matches => {
                        if (matches) {
                            this.isLandscape = true;
                            return this._renderMobile(t);
                        } else {
                            return <Mobile>
                                {matches => {
                                    if (matches) {
                                        this.isLandscape = false;
                                        return this._renderMobile(t);
                                    } else return this._renderDesktop(t);
                                }}
                            </Mobile>
                        }
                    }}
                </MobileLand>
            </div>
        );
    }

    _renderMobile(t) {
        const {handleStateUpdate} = this.props;
        return (
            <div className="landing_container">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        marginTop: 30
                    }}
                >
                    <img
                        style={{position: 'absolute', left: 0, right: 0, top: 0,}}
                        src={images().lpTopImageMob}
                        className="lpBgTopMob"
                    />
                    <div
                        style={{
                            display: 'flex',
                            position: 'absolute',
                            left: 0,
                            direction: 'ltr',
                        }}
                    >
                        <img className="logoMWAHeader" src={images().logo}/>
                        <div className={i18next.dir() === "rtl" ? "header_item_title_rtl" : "header_item_title"}>
                            <div
                                className="headerTitleLabel"
                                style={{margin: 0, padding: 0}}
                            >
                                {t('MyWaterAdvisor')}
                            </div>
                            <div
                                className="headerSubtitleLabelMob"
                                style={{margin: 0, padding: 0}}
                            >
                                {t('OnlineYourWorldWater')}
                            </div>
                        </div>
                    </div>
                    <img
                        src={i18next.language === 'he' ? images().lpImage : images().lpImageEn}
                        className="lpBigImageMob"
                        style={{marginTop: 100, zIndex: 1}}
                    />
                    <div className="lpMonitorLabelMob" style={{color: '#3c63d7'}}>
                        {t('MonitorRealTimeConsumption')}
                    </div>
                    <div
                        style={{
                            marginTop: this.isLandscape ? 140 : 40,
                            marginBottom: 40,
                            display: 'flex',
                            alignItems: 'center',
                            alignSelf: 'center',
                            zIndex: 1000
                        }}
                    >
                        <Link to={'/register'} onClick={()=> handleStateUpdate({currentScreen: 0})} className="JoinButton">{t('JoinButton')}</Link>
                        <div className="LandingButtonDivider"/>
                        <Link to={'/login'} onClick={()=> handleStateUpdate({currentScreen: 0})} className="LoginButton">{t('Login').toUpperCase()}</Link>
                    </div>
                    <div
                        style={{
                            flexDirection: 'column',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <img
                            src={images().icEmpowerment}
                            className="lpImage"
                            style={{width: 30, height: 30}}
                        />
                        <div className="lpButtonTitle" style={{marginTop: 10}}>
                            {t('Empowerment')}
                        </div>
                        <div className="lpButtonSubTitle" style={{marginTop: 10}}>
                            {t('KnowledgeOfHowUseWaterEmpower')}
                        </div>
                    </div>

                    <div
                        style={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            display: 'flex',
                            marginTop: 20
                        }}
                    >
                        <img
                            src={images().icFlexibility}
                            className="lpImage"
                            style={{width: 30, height: 30}}
                        />
                        <div
                            className="lpButtonTitle"
                            style={{marginTop: 10}}
                        >
                            {t('Flexibility')}
                        </div>
                        <div
                            className="lpButtonSubTitle"
                            style={{marginTop: 10}}
                        >
                            {t('AppsAvailableForBothApple')}
                        </div>
                    </div>

                    <div
                        style={{
                            flexDirection: 'column',
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: 20
                        }}
                    >
                        <img
                            src={images().icCustomerService}
                            className="lpImage"
                            style={{width: 30, height: 30}}
                        />
                        <div
                            className="lpButtonTitle"
                            style={{marginTop: 10}}
                        >
                            {t('CustomerService')}
                        </div>
                        <div
                            className="lpButtonSubTitle"
                            style={{marginTop: 10}}
                        >
                            {t('SetBudget')}
                        </div>
                    </div>

                    <div
                        style={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            display: 'flex',
                            marginTop: 20
                        }}
                    >
                        <img
                            src={images().icSafety}
                            className="lpImage"
                            style={{width: 30, height: 30}}
                        />
                        <div className="lpButtonTitle" style={{marginTop: 10}}>
                            {t('Safety')}
                        </div>
                        <div className="lpButtonSubTitle" style={{marginTop: 10}}>
                            {t('MonitorUnexpected')}
                        </div>
                    </div>
                    <div
                        style={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            display: 'flex',
                            marginTop: 20
                        }}
                    >

                    </div>
                    <div className="alsoAvailableMob">
                        {t('mobileApps')}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignSelf: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <button
                            style={{
                                backgroundColor: 'transparent',
                                alignItems: 'center'
                            }}
                            onClick={() => window.open(globals.IOS_LINK, '_blank')}
                        >
                            <img className="lpButtonMob" src={images().btnAppStore}/>
                        </button>
                        <button
                            style={{
                                backgroundColor: 'transparent',
                                alignItems: 'center'
                            }}
                            onClick={() =>
                                window.open(globals.ANDROID_LINK, '_blank')
                            }
                        >
                            <img
                                className="lpButtonMob"
                                src={images().btnGooglePlay}
                            />
                        </button>
                    </div>
                    {/*<div style={{display: 'flex', marginBottom: 100, zIndex: -1}}>*/}
                    {/*    <img*/}
                    {/*        src={images().bgWaveMob}*/}
                    {/*        style={{position: 'absolute', width: '100%'}}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div className="Landing_BottomLogo">
                        <div style={{justifyContent: 'center'}}>
                            <img className="logoMWAHeader" src={images().logo} alt="logo"/>
                        </div>
                        <div className="Landing_Bottom_TitleLabel">
                            {t('MyWaterAdvisor')}
                        </div>
                        <div className="Landing_Bottom_SubtitleLabel">
                            {t('OnlineYourWorldWater')}
                        </div>
                    </div>
                    <img src={images().bgWaveMob} alt="" className="Landing_Bottom_background"/>
                </div>
            </div>
        );
    }

    _renderDesktop(t) {
        const {handleStateUpdate} = this.props
        return (
            <div className="landing_container">
                <div className="header_item_landing">
                    <LanguageSwitcher color='black' />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        marginTop: 50
                    }}
                >
                    <div className="landing_logoContainer">
                        <div

                            style={{
                                display: 'flex',
                                width: '100%',
                                // flex: 3
                            }}>
                            <img
                                style={{position: 'absolute'}}
                                src={images().lpTopImage}
                                className="lpBgTop"
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: 40,
                                    marginLeft: 100
                                }}
                            >
                                <div style={{justifyContent: 'center'}}>
                                    <img className="logoMWAHeader" src={images().logo}/>
                                </div>
                                <div className={i18next.dir() === "rtl" ? "header_item_title_rtl" : "header_item_title"}>
                                    <div
                                        className="headerTitleLabel"
                                        style={{margin: 0, padding: 0}}
                                    >
                                        {t('MyWaterAdvisor')}
                                    </div>
                                    <div className="headerSubtitleLabel"
                                         style={{margin: 0, padding: 0}}
                                    >
                                        {t('OnlineYourWorldWater')}
                                    </div>
                                </div>
                            </div>
                            <img
                                src={i18next.language === 'he' ? images().lpImage : images().lpImageEn}
                                className="lpBigImage"
                                style={{
                                    marginTop: 120,
                                    marginLeft: 80,
                                    position: 'absolute',
                                    zIndex: 1
                                }}
                            />
                        </div>
                        <div
                            style={{
                                flexDirection: 'column',
                                display: 'flex',
                                // width: '50%',
                                marginTop: 280,
                                // marginRight: 20,
                                // marginLeft: 20,
                                zIndex: 10,
                                // flex: 2,
                            }}
                        >
                            <div
                                className="lpMonitorLabel"
                                style={{color: '#3c63d7', direction: i18next.dir()}}
                            >
                                {t('MonitorRealTimeConsumption')}
                            </div>
                            <div className="lpButtonsContainer"
                                 style={{direction: i18next.dir()}}
                            >
                                <Link to={'/register'} onClick={()=> handleStateUpdate({currentScreen: 0})} className="JoinButton">{t('JoinButton')}</Link>
                                <div className="LandingButtonDivider"/>
                                <Link to={'/login'} onClick={()=> handleStateUpdate({currentScreen: 0})}  className="LoginButton">{t('Login').toUpperCase()}</Link>
                            </div>
                        </div>
                    </div>
                    <div style={{display: 'flex'}}>
                        <img
                            src={images().bgWave}
                            style={{position: 'absolute', width: '100%'}}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            marginTop: 360,
                            alignSelf: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <div
                            style={{
                                flexDirection: 'column',
                                alignItems: 'center',
                                display: 'flex'
                            }}
                        >
                            <img
                                src={images().icEmpowerment}
                                className="lpImage"
                                style={{width: 40, height: 40}}
                            />
                            <div
                                className="lpButtonTitle"
                                style={{marginTop: 10}}
                            >
                                {t('Empowerment')}
                            </div>
                            <div
                                className="lpButtonSubTitle"
                                style={{marginTop: 10}}
                            >
                                {t('KnowledgeOfHowUseWaterEmpower')}
                            </div>
                        </div>
                        <div
                            style={{
                                flexDirection: 'column',
                                alignItems: 'center',
                                display: 'flex',
                                marginLeft: 20
                            }}
                        >
                            <img
                                src={images().icFlexibility}
                                className="lpImage"
                                style={{width: 40, height: 40}}
                            />
                            <div
                                className="lpButtonTitle"
                                style={{marginTop: 10}}
                            >
                                {t('Flexibility')}
                            </div>
                            <div
                                className="lpButtonSubTitle"
                                style={{marginTop: 10}}
                            >
                                {t('AppsAvailableForBothApple')}
                            </div>
                        </div>

                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignSelf: 'center',
                            alignItems: 'center',
                            marginTop: 60,
                        }}
                    >

                        <div
                            style={{
                                flexDirection: 'column',
                                alignItems: 'center',
                                display: 'flex',
                                marginLeft: 20
                            }}
                        >
                            <img
                                src={images().icCustomerService}
                                className="lpImage"
                                style={{width: 40, height: 40}}
                            />
                            <div
                                className="lpButtonTitle"
                                style={{marginTop: 10}}
                            >
                                {t('CustomerService')}
                            </div>
                            <div
                                className="lpButtonSubTitle"
                                style={{marginTop: 10}}
                            >
                                {t('SetBudget')}
                            </div>
                        </div>

                        <div
                            style={{
                                flexDirection: 'column',
                                alignItems: 'center',
                                display: 'flex'
                            }}
                        >
                            <img
                                src={images().icSafety}
                                className="lpImage"
                                style={{width: 40, height: 40}}
                            />
                            <div
                                className="lpButtonTitle"
                                style={{marginTop: 10}}
                            >
                                {t('Safety')}
                            </div>
                            <div
                                className="lpButtonSubTitle"
                                style={{marginTop: 10}}
                            >
                                {t('MonitorUnexpected')}
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            alignSelf: 'center',
                            marginTop: 200
                        }}
                        className="alsoAvailable"
                    >
                        {t('mobileApps')}
                    </div>
                    <div
                        style={{
                            flexDirection: 'row',
                            display: 'flex',
                            marginTop: 20,
                            alignSelf: 'center'
                        }}
                    >
                        <button
                            style={{
                                backgroundColor: 'transparent',
                                alignItems: 'center'
                            }}
                            onClick={() => window.open(globals.IOS_LINK, '_blank')}
                        >
                            <img className="lpButton" src={images().btnAppStore}/>
                        </button>
                        <button
                            style={{
                                backgroundColor: 'transparent',
                                alignItems: 'center'
                            }}
                            onClick={() =>
                                window.open(globals.ANDROID_LINK, '_blank')
                            }
                        >
                            <img className="lpButton" src={images().btnGooglePlay}/>
                        </button>
                    </div>


                    <div className="Landing_BottomLogo">
                        <div style={{justifyContent: 'center'}}>
                            <img className="logoMWAHeader" src={images().logo} alt="logo"/>
                        </div>
                        <div className="Landing_Bottom_TitleLabel">
                            {t('MyWaterAdvisor')}
                        </div>
                        <div className="Landing_Bottom_SubtitleLabel">
                            {t('OnlineYourWorldWater')}
                        </div>
                    </div>
                </div>
                <img src={images().bgWaveBottom} alt="" className="Landing_Bottom_background"/>

            </div>
        );
    }

    componentWillUnmount() {
        this.props.toggleHeader(true);
    }
}


export default withTranslation('translations')(LandingPage)
