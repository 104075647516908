
import React from 'react';
import {withTranslation} from "react-i18next";

import './PrivacyPolicy.css';
import images from "../../images";
import '../../App.css';

class PrivacyPolicy extends React.Component {

    render() {
        const {t} = this.props
        return (
            <div className="privacyPolicy_container">
                <button style={{float: 'right', backgroundColor: 'transparent'}} onClick={() => this.props.onClose()}>
                    <img src={images().icClose} alt="Close button"/>
                </button>
                <p className="privacyPolicyTitle">{t('Privacy')}</p>

                <textarea disabled style={{border: 0, height: '100%',}} className={"termsText"}>
                    {t('PrivacyPolicyText')}
                </textarea>
            </div>
        );
    }
}

export default withTranslation('translations')(PrivacyPolicy)
