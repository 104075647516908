/* eslint-disable */
import moment from "moment";
import globals from "./globals";
import images from "./images";
import i18next from './i18n';

export const AlertType = {
  SUSPECTED_LEAK: 16,
  MONTHLY_CONS_LIMIT: 1002,
  DAILY_USAGE_VACATION: 100,
  Above_Maximum_Consumption: 12,
  Above_Average_Consumption: 14,
  Leak: 23,
  Annual_Ration_Exceeded: 40,
  Monthly_Ration_Exceeded: 41,
  Consumption_During_Vacation: 1001,
  Leak_In_Main_Meter: 35,
};

export const AlertsTitles = {
  12: "AboveMaximumConsumption",
  14: "AboveAverageConsumption",
  16: "SuspectedLeak",
  23: "PossibleLeak",
  40: "AnnualRationExceeded",
  41: "MonthlyRationExceededTitle",
  1001: "DailyUsageDuringVacation",
  1002: "BillingCycleConsumptionLimit",
  35: "PossibleLeakInMainMeter",
  '-3': "OldReading",
  '-2': "NetworkError",
  '-1': "InvalidData",
  1: "LeakGroups",
  2: "CommonUsageGroups",
  3: "NegativeLeakGroups",
  4: "NegativeCommonUsageGroups",
  5: "HighCommonConsumption",
  6: "NegativeCommonConsumption",
  10: "NegativeConsumption",
  11: "InactiveMeter",
  13: "BelowMinimumConsumption",
  15: "BelowAverageConsumption",
  17: "InactiveCompoundMeter",
  20: "Tamper",
  21: "CutWire",
  22: "LowBattery",
  24: "CCWRead",
  25: "NoTransmission",
  26: "HighFlow",
  27: "LowFlow",
  28: "AboveUpperBound",
  29: "BelowLowerBound",
  30: "ManualReadMissing",
  31: "FlowAboveQMax",
  32: "WrongMeterSN",
  33: "MeasurementFail",
  34: "MeterBoxOpen",
  36: "Tilt",
  37: "DisassembledMeter",
  110: "EmptyTanks",
  111: "WrongSequence",
  120: "GasTankBelowCriticalLevel",
  121: "StaticReadOnGasTank",
  130: "RTCImprecision",
  131: "UnitReset",
  132: "SensorFailure",
  133: "DryChamber",
  140: "Freeze",
  141: "SlaveBatteryLow",
  200: "HydrantNoCommunication",
  210: "HydrantTamper",
  300: "SolitaryPeopleStaticMeter"
};

export const MessageSubjects = {
  1: "UpdateInformation",
  2: "ReadAbnormalConsumption",
  3: "Leak",
  4: "WaterMeter",
  5: "WaterBill",
  6: "Other"
};

const MessageSubjectsMap = {
  'تحديث التفاصيل': 'UpdateInformation',
  'قراءة / استهلاك غير منطقي': 'ReadAbnormalConsumption',
  'تسرب': 'Leak',
  'عداد المياه': 'WaterMeter',
  'فاتورة الماء': 'WaterBill',
  'اخر': 'Other',
  'Update Information': 'UpdateInformation',
  'Read/Abnormal Consumption': 'ReadAbnormalConsumption',
  'Leak': 'Leak',
  'Water Meter': 'WaterMeter',
  'Water Bill': 'WaterBill',
  'Other': 'Other',
  'Actualizar detalles': 'UpdateInformation',
  'Lectura/consumo anormal': 'ReadAbnormalConsumption',
  'Fuga': 'Leak',
  'Medidor de agua': 'WaterMeter',
  'Factura de agua': 'WaterBill',
  'Otro': 'Other',
  'עדכון פרטים': 'UpdateInformation',
  'קריאה/צריכה חריגה': 'ReadAbnormalConsumption',
  'נזילה': 'Leak',
  'מד המים': 'WaterMeter',
  'חשבון המים': 'WaterBill',
  'אחר': 'Other'
};

export const getTranslatedRequestTitle = title => {
  return i18next.t(`MessageSubjectsMap.${MessageSubjectsMap[Object.keys(MessageSubjectsMap)
    .find(key => key === title)]}`) ?? '';
};

export const AlertsDescriptions = {
  12: "AboveMaximumConsumption",
  14: "AboveAverageConsumption",
  16: "ConsumptionAboveAverageSuspectedLeak",
  23: "Leak",
  40: "AnnualRationExceeded",
  41: "MonthlyRationExceeded",
  1001: "ConsumptionDuringVacation",
  35: "LeakInMainMeter",
};

const utils = {
  getFormattedConsumption(value) {
    if (value === "") {
      return "";
    }

    const val = parseFloat(parseFloat(value).toFixed(3))

      if (isNaN(val)) {
        return '';
      } else {
        return val;
      }

    //test
    // return parseFloat(parseFloat(value)).toFixed(3);
  },

  containsNumbers(value) {
    const re = /^[0-9\b]+$/;
    return re.test(value);
  },

  generateId() {
    const array = new Uint32Array(4);
    return window.crypto.getRandomValues(array).join("");
  },

  isEmail(candidate) {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return reg.test(candidate);
  },

  toVacationDateRange(vacation) {
    if (vacation === "") return null;

    return `${this.toVacationDate(vacation.startDate)} - ${this.toVacationDate(
      vacation.endDate
    )}`;
  },

  toVacationDate(date) {
    if (date === "") return "";

    return moment(date).format("ddd, MMM DD");
  },

  toMonthDate(date) {
    if (date === "") return "";

    return moment(date).format("MMM, YYYY");
  },

  toAlertDate(date) {
    if (date === "") return "";
    return moment(date).format('MMMM DD, YYYY, HH:mm');
    // return moment.utc(date).local().format("MMMM DD, YYYY, HH:mm");
  },

  getBillingCycleDate(date) {
    return moment(date, moment.ISO_8601).format("MM-DD-YYYY");
  },

  toHomeDisplayDateString(date) {
    if (date === "") return "";
    return moment(date).format("MMM DD, YYYY");
  },

  toDisplayDateString(date, withTime) {
    if (date === "") return "";

    return moment(date).format(`MM/DD/YYYY${withTime ? " HH:mm" : ""}`);
  },

  toDateFromString(dateString) {
    return moment(dateString, "YYYY-MM-DD").toDate();
  },

  toDateString(date) {
    if (date === "") return "";
    return moment(date).locale('en').format("YYYY-MM-DD");
  },

  toISODateString(date) {
    return moment(date, moment.ISO_8601);
  },

  getYearsBefore(date, amount) {
    return moment(date).subtract(amount, "years").format("YYYY");
  },

  getDaysBefore(date, amount) {
    return moment(date).subtract(amount, "days").format("YYYY-MM-DD");
  },

  getMonthsBefore(date, amount) {
    return moment(date).subtract(amount, "months").locale('en').format("YYYY-MM");
  },

  getMonth(date) {
    return moment(date).locale('en').format("YYYY-MM");
  },

  getEndOfMonthDate(date) {
    return moment(date).endOf("month").locale('en').format("YYYY-MM-DD");
  },

  getMonthDigit(date) {
    return moment(date).format("MM");
  },

  getMonthDiff(date1, date2) {
    let diff = moment(date2).diff(moment(date1), "months", true);
    return diff + 1;
  },

  getHour(date) {
    return moment(date, moment.ISO_8601).format("HH");
  },

  getDayNumber(date) {
    return moment(date, moment.ISO_8601).format("DD");
  },

  getYear(date) {
    return moment(date, moment.ISO_8601).locale('en').format("YYYY");
  },

  getMonthName(date) {
    return moment(date, moment.ISO_8601).format("MMM");
  },

  getDay(date) {
    return moment(date, moment.ISO_8601).date();
  },

  getDaysInMonth(year, month) {
    const date = new Date(year, month - 1, 1);
    const result = [];
    while (date.getMonth() === month - 1) {
      result.push(`${date.getDate()}`);
      date.setDate(date.getDate() + 1);
    }
    return result;
  },

  isToday(date) {
    const today = new Date();
    return (
      utils.getYear(date) === utils.getYear(today) &&
      utils.getMonth(date) === utils.getMonth(today) &&
      utils.getDayMonth(date) === utils.getDayMonth(today)
    );
  },

  isArrAscendingByConsDate(arr) {
    return arr.every(function (x, i) {
      return i === 0 || x.consDate >= arr[i - 1].consDate;
    });
  },

  getMeterDesc(meter) {
    return `${meter.meterSn}, ${
      meter.fullAddress ? meter.fullAddress : globals.METER_DEFAULT_ADDRESS
    }`;
  },

  getLargeIconForAlertType(alertTypeId) {
    let bigIcon = "";
    switch (parseInt(alertTypeId)) {
      case AlertType.SUSPECTED_LEAK:
        bigIcon = images().icFaucet;
        break;
      case AlertType.DAILY_USAGE_VACATION:
        bigIcon = images().icBigVacations;
        break;
      case AlertType.MONTHLY_CONS_LIMIT:
        bigIcon = images().icBigWaterLimit;
        break;
      case AlertType.Above_Average_Consumption:
        bigIcon = images().icAboveAverageConsumptionBig;
        break;
      case AlertType.Above_Maximum_Consumption:
        bigIcon = images().icAboveMaximumConsumptionBig;
        break;
      case AlertType.Leak:
        bigIcon = images().icLeakBig;
        break;
      case AlertType.Annual_Ration_Exceeded:
        bigIcon = images().icAnnualRationExceededBig;
        break;
      case AlertType.Monthly_Ration_Exceeded:
        bigIcon = images().icMonthlyRationExceededBig;
        break;
      case AlertType.Consumption_During_Vacation:
        bigIcon = images().icVacationExpenseBig;
        break;
      case AlertType.Leak_In_Main_Meter:
        bigIcon = images().LeakInMainMeterBig;
        break;
      default:
        bigIcon = images().message;
    }

    return bigIcon;
  },

  getSmallIconForAlertType(alertTypeId) {
    let icon = "";
    switch (parseInt(alertTypeId)) {
      case AlertType.SUSPECTED_LEAK:
        icon = images().icFaucet;
        break;
      case AlertType.DAILY_USAGE_VACATION:
        icon = images().icVacation;
        break;
      case AlertType.MONTHLY_CONS_LIMIT:
        icon = images().icWaterLimit;
        break;
      case AlertType.Above_Average_Consumption:
        icon = images().icAboveAverageConsumption;
        break;
      case AlertType.Above_Maximum_Consumption:
        icon = images().icAboveMaximumConsumption;
        break;
      case AlertType.Leak:
        icon = images().icLeak;
        break;
      case AlertType.Annual_Ration_Exceeded:
        icon = images().icAnnualRationExceeded;
        break;
      case AlertType.Monthly_Ration_Exceeded:
        icon = images().icMonthlyRationExceeded;
        break;
      case AlertType.Consumption_During_Vacation:
        icon = images().icVacationExpense;
        break;
      case AlertType.Leak_In_Main_Meter:
        icon = images().LeakInMainMeter;
        break;
      default:
        icon = images().message;
    }
    return icon;
  },

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },

  roundDecimal(value, toFixedValue) {
    let newValue = value?.toFixed(toFixedValue).replace(/0+$/, "") ?? 0;
    if (newValue.charAt(newValue.length - 1) === '.') {
      newValue = newValue.slice(0, -1);
    }

    return newValue === '0' ? '' : newValue;
  }
};

export default utils;
