import validator from "validator";
import React from 'react';

import utils from "./utils";
import i18next from "./i18n";

export const required = (value, props) => {
    if (!value.toString().trim().length) {
        // We can return string or jsx as the 'error' prop for the validated Component
        return <span
            className="inputError">{utils.capitalizeFirstLetter(i18next.t(props.name))}&nbsp;{i18next.t('isRequired')}</span>
    }
};

export const email = (value) => {
    if (!validator.isEmail(value)) {
        const reducedValue = value?.length > 26 ? value.split('').slice(0, 26).join('') : value;
        return <span className="inputError">{reducedValue}&nbsp;{i18next.t('notValidEmail')}</span>
    }
};

export const lt = (value, props) => {
    // get the maxLength from component's props
    if (!value.toString().trim().length > props.maxLength) {
        // Return jsx
        return <span
            className="inputError">{i18next.t('valueExceeded')}&nbsp;{props.maxLength}&nbsp;{i18next.t('symbols')}</span>
    }
};

export const password = (value, props, components) => {
    // NOTE: Tricky place. The 'value' argument is always current component's value.
    // So in case we're 'changing' let's say 'password' component - we'll compare it's value with 'confirm' value.
    // But if we're changing 'confirm' component - the condition will always be true
    // If we need to always compare own values - replace 'value' with components.password[0].value and make some magic with error rendering.
    if (value !== components['confirm'][0].value) { // components['password'][0].value !== components['confirm'][0].value
        // 'confirm' - name of input
        // components['confirm'] - array of same-name components because of checkboxes and radios
        return <span className="error">{i18next.t('PasswordsNotEqual')}</span>
    }
};

export const passwordRequirements = (value, props) => {
    const length = value?.length > 7;
    const isNumber = value?.split('').some(char => (char >= '0' && char <= '9'))
    const isString = value?.split('').some(char => !(char >= '0' && char <= '9'))
    if (!length || !isNumber || !isString) {
        return <div className="inputError">{i18next.t('passwordCheck')}</div>
    }
}
