/* eslint-disable */
import React from 'react';
import './AppPopup.css';
import images from '../images';
import { Mobile } from '../App';
import {withTranslation} from "react-i18next";

class AppPopup extends React.Component {
    render() {
        const { t } = this.props
        return (
            <div>
                <Mobile>
                    {matches => {
                        if (matches) return this._renderMobile(t);
                        else return this._renderDesktop(t);
                    }}
                </Mobile>
            </div>
        );
    }

    _renderMobile(t) {
        return (
            <div style={{ padding: 10 }}>
                <button
                    style={{ float: 'right', backgroundColor: 'transparent' }}
                    onClick={() => this.props.onClose()}
                >
                    <img src={images().icClose} />
                </button>
                <p className='titleError'>{t('MyWaterAdvisor')}</p>
                <p className={'message'}>{this.props.message}</p>
            </div>
        );
    }

    _renderDesktop(t) {
        return (
            <div style={{ padding: 10 }}>
                <button
                    style={{ float: 'right', backgroundColor: 'transparent' }}
                    onClick={() => this.props.onClose()}
                >
                    <img src={images().icClose} />
                </button>
                <p className={'title'}>{t('MyWaterAdvisor')}</p>
                <p className={'message'}>{this.props.message}</p>
            </div>
        );
    }
}


export default withTranslation('translations')(AppPopup)
