/* eslint-disable */
import React from 'react';
import Input from 'react-validation/build/input';
import Form from 'react-validation/build/form';
import Button from 'react-validation/build/button';
import Popup from "reactjs-popup";
import {withTranslation} from "react-i18next";

import * as api from "../../api";
import * as validators from "../../validators";
import images from "../../images";
import AppPopup from "../../components/AppPopup";
import utils from "../../utils";

import AppButton from "../../components/AppButton";

class EnterCodePassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            code: '',
            errorMessage: null
        }
    }

    render() {
        const {t} = this.props;
        return (
            <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <h1 className="registerTitle" >{t('changePassword')}</h1>
                <h3 className="enterCode">{t('enterCodeAgain')}</h3>
                <h3 className="phone" style={{display: 'flex', justifyContent: 'center'}}>{this.state.phone}</h3>
                <Form ref={c => { this.form = c }} method={"post"} onSubmit={this.onSubmit.bind(this)}>
                    <div style={{marginTop: 22, display: 'flex', alignItems: 'center'}}>
                        <Input validations={[validators.required]}
                               placeholder={utils.capitalizeFirstLetter(t('code'))}
                               type="text"
                               name='code'
                               onChange={(event) => this.setState({code: event.target.value})}
                               className="codeInput rectangle" value={this.state.code}
                               style={{
                                   paddingLeft: 10,
                                   paddingRight: 10,
                                   display: 'flex',
                                   backgroundColor: 'transparent'
                               }}>
                        </Input>
                    </div>
                    <div style={{marginTop: 50, display: 'flex', justifyContent: 'center'}}>
                        <Button style={{border: 0, backgroundColor: 'transparent'}}>
                            <AppButton text={t('buttons.continue')} color='blue' onClick={()=> this.form.validateAll()} propsStyle={{marginBottom: 30, width: 170}} />
                        </Button>
                    </div>
                </Form>
                <Popup
                    contentStyle={{borderRadius: 8, width: 'initial', minWidth: '50%'}}
                    open={this.state.errorMessage}
                    onClose={this.closeErrorPopup.bind(this)}
                >
                    <AppPopup onClose={this.closeErrorPopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }

    closeErrorPopup() {
        this.setState({errorMessage: null});
    }

    onSubmit = (event) => {
        event.preventDefault();
        const {t} = this.props;

        //this.setState({isLoading: true});
        api.validateRecoveryCode(this.state.code, (res, error) => {
            //this.setState({isLoading: false});
            if (error) {
                this.setState({errorMessage: t('errorOccurred')});
            } else {
                if (res.error) {
                    this.setState({errorMessage: res.error});
                } else {
                    if (Object.keys(res).length === 0 && res.constructor === Object) {
                        this.setState({errorMessage: t('enterCodeAgain')});
                        return;
                    }

                    this.props.history.push(`/password/change/${this.state.code}`);
                }
            }
        });
    }
}

export default withTranslation('translations')(EnterCodePassword)
