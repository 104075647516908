import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Translations from "./translations"

// const lngDetector = new LanguageDetector()
//   lngDetector.addDetector({
//     name: 'customDetector',

//     lookup(options) {
//         // options -> are passed in options
//         return 'he';
//       },
    
//       cacheUserLanguage(lng, options) {
//           console.log('lng', lng);
//         if (lng.substring(0,2) === 'he')
//         localStorage.setItem('i18nextLng', 'he')
//       else
//         localStorage.setItem('i18nextLng', 'en')
//     }
//       },)

i18n.use(LanguageDetector).init({
    // we init with resources
    detection: {
        // order and from where user language should be detected
        order: ['customDetector', 'querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag']
      },

    resources: Translations.resources,
    lng: localStorage?.getItem('i18nextLng') ?? Translations.languages.find(languages => languages.lang === 'he').lang,
    fallbackLng: Translations.languages.find(languages => languages.lang === 'he').lang,
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    // keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },

    react: {
        wait: true
    }
});

export default i18n;
