/* eslint-disable */
import React from "react";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import Button from "react-validation/build/button";
import Popup from "reactjs-popup";
import { withTranslation } from "react-i18next";

import "./SettingsChangePassword.css";
import "./SettingsChangePhone.css";
import images from "../../images";
import * as api from "../../api";
import { Mobile } from "../../App";
import DataManager from "../../managers/DataManager";
import AppPopup from "../../components/AppPopup";
import Phone from "../../components/Phone";
import translations from "../../translations";

import AppButton from "../../components/AppButton";

class SettingsChangePhone extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      phone: "",
      countryCode: "+972",
      countryName: "il",
      errorMessage: null,
      validation: false,
    };
  }
  componentDidMount() {
    if (DataManager?.sharedInstance?.user?.phoneNumber !== null) {
      const countryShortName = translations.findCountryShortName(DataManager?.sharedInstance?.user?.phoneNumber?.countryCode ?? '+972');

      this.setState({
        phone: DataManager?.sharedInstance?.user?.phoneNumber?.AdditionalPhoneNumber ?? DataManager?.sharedInstance?.user?.phoneNumber?.phoneNumber,
        countryCode: DataManager?.sharedInstance?.user?.phoneNumber?.countryCode ?? '+972',
        countryName: countryShortName
      });
    }
  }

  onPhoneChange = (phone, country) => {
    this.setState({
      phone: phone.substring(country?.dialCode.length, phone.length),
      countryCode: ('+' + country?.dialCode) ?? '+972',
      countryName: country?.countryCode ?? 'il'
    })
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <Mobile>
          {(matches) => {
            if (matches) return this._renderMobile(t);
            else return this._renderDesktop(t);
          }}
        </Mobile>
      </div>
    );
  }

  _renderMobile(t) {
    const { validation, phone } = this.state;
    return (
      <div
        style={{
          padding: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1 className={"title"}>{t("EnterPhoneReceiveSMS")}</h1>
        <div style={{direction: 'ltr'}}>
          <Phone phone={countryCode + phone} onChange={this.onPhoneChange} country={countryName} />
        </div>
        <Form method={"post"} onSubmit={this.onSubmit.bind(this)}>
          <span
            className="inputError"
            style={{
              display: validation && !phone ? "initial" : "none",
              marginLeft: 100,
            }}
          >
            {t("PhoneNumber")}&nbsp;{t("isRequired")}
          </span>
          <div
            style={{
              marginTop: 10,
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              backgroundColor: "transparent",
              direction: "ltr",
            }}
          >
            <Button
              style={{
                border: 0,
                backgroundColor: "transparent",
              }}
            >
              <AppButton text={t('buttons.save')} color='blue' onClick={() => this.setState({ validation: true })} propsStyle={{width: 75, marginBottom: 30}} />
            </Button>
            <button
              style={{
                border: 0,
                backgroundColor: "transparent",
              }}
            >
              <AppButton text={t('buttons.cancel')} color='white' onClick={this.closePopup.bind(this)} propsStyle={{width: 75, marginBottom: 30}} />
            </button>
          </div>
        </Form>
        <Popup
          contentStyle={{ borderRadius: 8, width: "initial", minWidth: "50%" }}
          open={this.state.errorMessage}
          onClose={this.closeErrorPopup.bind(this)}
        >
          <AppPopup
            onClose={this.closeErrorPopup.bind(this)}
            message={this.state.errorMessage}
          />
        </Popup>
      </div>
    );
  }

  _renderDesktop(t) {
    const { validation, phone, countryName, countryCode } = this.state;

    console.log('phone country', countryCode, phone, DataManager?.sharedInstance?.user?.phoneNumber)
    return (
      <div
        style={{
          // height: 200,
          padding: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div>
          <h1 className={"title"}>{t("EnterPhoneReceiveSMS")}</h1>
        </div>

        <div style={{direction: 'ltr'}}>
          <Phone phone={countryCode + phone} onChange={this.onPhoneChange} country={countryName} />
        </div>
        <Form method={"post"} onSubmit={this.onSubmit.bind(this)}>
          <span
            className="inputError"
            style={{
              display: validation && !phone ? "initial" : "none",
              marginLeft: 90,
            }}
          >
            {t("PhoneNumber")}&nbsp;{t("isRequired")}
          </span>
          <div
            style={{
              marginTop: 4,
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              backgroundColor: "transparent",
              direction: "ltr",
            }}
          >
            <Button
              style={{
                border: 0,
                backgroundColor: "transparent",
              }}
            >
              <AppButton text={t('buttons.save')} color='blue' onClick={() => this.setState({ validation: true })} propsStyle={{width: 150, marginBottom: 30}} />
            </Button>
            <button
              style={{
                border: 0,
                backgroundColor: "transparent",
              }}
            >
             <AppButton text={t('buttons.cancel')} color='white' onClick={this.closePopup.bind(this)} propsStyle={{width: 150, marginBottom: 30}} />
            </button>
          </div>
        </Form>
        <Popup
          contentStyle={{ borderRadius: 5 }}
          open={this.state.errorMessage}
          onClose={this.closeErrorPopup.bind(this)}
        >
          <AppPopup
            onClose={this.closeErrorPopup.bind(this)}
            message={this.state.errorMessage}
          />
        </Popup>
      </div>
    );
  }

  closeErrorPopup() {
    this.setState({ errorMessage: null });
  }

  onSubmit = (event) => {
    event.preventDefault();
    const { phone, countryCode, countryName } = this.state;
    const { t } = this.props;

    const countryLanguage = translations.findCountryLanguage(countryName)
    console.log(`onSubmit countryName ${translations.findCountryLanguage(countryName)}`); 

    if (phone) {
      // api.updatePhoneNumber(countryCode, phone, 'en', (response, error) => {
      api.updatePhoneNumber(countryCode, phone, countryLanguage, (response, error) => {
        if (error) {
          this.setState({ errorMessage: t("errorOccurred") });
        } else {
          if (response.error) {
            this.setState({ errorMessage: response.error });
          } else {
            console.log(response);
            this.props.updateUser();
            this.closePopup();
          }
        }
      });
    }
  };

  closePopup = () => {
    this.props.onClose();
  };
}

export default withTranslation("translations")(SettingsChangePhone);
