/* eslint-disable */
import React from 'react';
import {withTranslation} from "react-i18next";
import Popup from "reactjs-popup";
import Input from 'react-validation/build/input';
import Form from 'react-validation/build/form';
import Button from 'react-validation/build/button';

import images from "../../images";
import './RegisterEnterCode.css';
import '../../App.css';
import * as validators from '../../validators';
import * as api from "../../api";
import DataManager from "../../managers/DataManager";
import AppPopup from "../../components/AppPopup";
import utils from "../../utils";

import AppButton from "../../components/AppButton";


class RegisterEnterCode extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            phoneNbr: props.match.params.phone,
            code: '',
            errorMessage: null
        }
    }

    render() {
        const {t} = this.props
        return (
            <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <h1 className="registerTitle">{t('JoinMyWaterAdvisor')}</h1>
                <h3 className="enterCode">{t('smsCode')}</h3>
                <h3 className="phone" style={{display: 'flex', justifyContent: 'center'}}>{this.state.phoneNbr}</h3>
                <Form ref={c => { this.form = c }} method={"post"} onSubmit={this.onSubmit.bind(this)}>
                    <div style={{marginTop: 22, display: 'flex', alignItems: 'center'}}>
                        <Input validations={[validators.required]}
                               placeholder={utils.capitalizeFirstLetter(t('code'))}
                               type="text"
                               name='code'
                               onChange={(event) => this.setState({code: event.target.value})}
                               className="codeInput rectangle" value={this.state.code}
                               style={{
                                   paddingLeft: 10,
                                   paddingRight: 10,
                                   display: 'flex',
                                   backgroundColor: 'transparent'
                               }}>
                        </Input>
                    </div>
                    <div style={{marginTop: 50, display: 'flex', justifyContent: 'center'}}>
                        <Button style={{border: 0}}>
                            <AppButton text={t('buttons.continue')} color='blue' onClick={() => this.form.validateAll()} propsStyle={{marginBottom: 30, width: 170}} />
                        </Button>
                    </div>
                </Form>
                <button onClick={()=> this.onResendCode()} className="linkButton">{t('ResendCode')}</button>
                <Popup
                    contentStyle={{borderRadius: 8, width: 'initial', minWidth: '50%'}}
                    open={this.state.errorMessage}
                    onClose={this.closeErrorPopup.bind(this)}
                >
                    <AppPopup onClose={this.closeErrorPopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }

    closeErrorPopup() {
        this.setState({errorMessage: null});
    }

    onSubmit = (event) => {
        event.preventDefault();
        const {t} = this.props;

        const sessionId = DataManager.sharedInstance.sessionId;

        api.validateRegisterCode(sessionId, this.state.code, (response, error) => {
            //this.setState({isLoading: false});
            if (error) {
                this.setState({errorMessage: t('errorOccurred')});
            } else {
                if (response.error) {
                    this.setState({errorMessage: response.error});
                } else {
                    //Success
                    this.props.history.push('/register/finish', {
                        sessionId: sessionId,
                        code: this.state.code
                    });
                }
            }
        });
    }

    onResendCode = () => {
        const {phoneNbr} = this.state;
        const countryCode = phoneNbr.split('-')[0];
        const phone = phoneNbr.split('-')[1];

        api.registerPhone(countryCode, phone, 'he', (response, error) => { // 'en'
            if (error) {
                this.setState({errorMessage: t('errorOccurred')});
            } else {
                if (response.error) {
                    if (response.code === 10030) {
                        DataManager.sharedInstance.loginErrors.phone = 1;
                        this.props.history.push('/register/phone/not_found');
                    } else
                        this.setState({errorMessage: response.error});
                }

                // else {
                //     const sessionId = response.sessionId;
                //     DataManager.sharedInstance.sessionId = sessionId;
                //     this.props.history.push(`/register/code/${this.state.phone}`);
                // }
            }
        });
    }
}

export default withTranslation('translations')(RegisterEnterCode)
