import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";

import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import i18n from "./i18n";
import DataManager, { DataContext } from "./managers/DataManager";

class Root extends Component {
  render() {
    return (
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <DataContext.Provider value={DataManager.sharedInstance}>
            <App />
          </DataContext.Provider>
        </I18nextProvider>
      </BrowserRouter>
    );
  }
}

Root.contextType = DataContext;

if (process.env.NODE_ENV === "staging" || process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

ReactDOM.render(<Root />, document.getElementById("root"));
registerServiceWorker();
