export default {
    // BASE_URL: 'https://test-api.city-mind.com',
    // SECOND_URL: 'https://test-api-ctm.city-mind.com',
    BASE_URL: process.env.REACT_APP_BASE_URL,
    SECOND_URL: process.env.REACT_APP_SECOND_URL,
    IOS_LINK: 'https://apps.apple.com/il/app/read-your-meter-pro/id1571281928?l=iw',
    ANDROID_LINK: 'https://play.google.com/store/apps/details?id=com.aradtec.rym',
    COLOR: {
        SKY_BLUE: '#66c0ff',
        PALE_SKY_BLUE: '#c2e8ff',
        CAROLINA_BLUE: '#8abeff',
        BABY_BLUE: '#a6deff',
        BABY_BLUE_2: '#b0cdff',
        CLEAR_BLUE: '#2b8fff',
        CLEAR_BLUE_2: '#2d7bfb',
        CLEAR_BLUE_3: '#2b74fa',
        DODGER_BLUE: '#3eb3f9',
        DODGER_BLUE_2: '#3eb2fa',
        DODGER_BLUE_3: '#5781ff',
        DUSK: '#46557a',
        WHITE_TWO: '#fafafa',
        WHITE_THREE: '#d9d9d9',
        AZURE: '#27aeff',
        COOL_GRAY: '#999ea8',
        ICE_BLUE: '#eaf4ff',
        ICE_BLUE_2: '#f7faff',
        BABY_GREEN: '#81fc95',
        SALMON: '#ff7a7a',
        SILVER: '#d0d5d9',
        REALLY_LIGHT_BLUE: '#d4efff',
        VIBRANT_BLUE: '#003fff',
        BROWNISH_GRAY: '#726e6e',
        BLUSH: '#fff1f1',
        GRAPEFRUIT: '#ff4f4f'
    },
    STORAGE: {
        DEVICE_ID: "DEVICE_ID",
        TOKEN: "TOKEN"
    },
    METER_DEFAULT_ADDRESS: '-',
    DATE: {
        STANDARD_FORMAT: "YYYY-MM-DDTHH:mm:ss.SSSZ"
    },
    MOBILE_WIDTH: 767,
    RECAPTCHA_SECRET: '6LdsMHoUAAAAAHkjJYCrUvaD5g-kD-xFPcIJgKkd',
    RECAPTCHA_SITE_KEY: '6Lfs5bwZAAAAAEz9WxZCLyxITDG322nhWi-ANOrm',
    APP_ID: '3a869241-d476-40f6-a923-d789d63db11d',
    APP_ID_2: '78FE99BC-5D35-4AC8-A15A-85E9D3C90ED0'
}
