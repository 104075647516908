import React from "react";
import { withTranslation } from "react-i18next";

import "./LanguageSwitcher.css";
import i18next from "../i18n";
import translations from "../translations";
import moment from "moment";
import 'moment/locale/he';
import 'moment/locale/ar';
import 'moment/locale/es';

class LanguageSwitcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      language: i18next.language,
    };
  }

  componentDidMount() {
    // console.log("i18next.language", i18next.language);
    // console.log("i18next.dir()", i18next.dir());

    const isDefaultLanguage = translations.languages.every((value) => {
      return !translations.languages.includes(value);
    });

    if (isDefaultLanguage) {
      this.handleChangeLanguage(translations.languages[0].lang);
    }
  }

  handleChangeLanguage = (language) => {
    i18next.changeLanguage(language).then(() => this.setState({ language }));
    moment.locale(language);
    if (language === 'ar') {
      const symbolMap = {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        '9': '9',
        '0': '0'
      };
      const numberMap = {
          '١': '1',
          '٢': '2',
          '٣': '3',
          '٤': '4',
          '٥': '5',
          '٦': '6',
          '٧': '7',
          '٨': '8',
          '٩': '9',
          '٠': '0'
      }
      moment.updateLocale('ar', {
          preparse: function (string) {
              return string.replace(/\u200f/g, '').replace(/[١٢٣٤٥٦٧٨٩٠]/g, function (match) {
                  return numberMap[match];
              }).replace(/،/g, ',');
          },
          postformat: function(string) {
            return string.replace(/\d/g, function(match) {
              return symbolMap[match];
            }).replace(/,/g, '،');
          },
      });
    }

    window.interdeal.SetLocale(language);
    // window.location.reload();
  };

  render() {
    let generalColor = 'white';
    if (this.props.color === 'black') generalColor = 'black';

    const separatorStyle = (index) =>
      i18next.dir() === "ltr"
        ? index !== translations.languages.length - 1
          ? "separator "
          : ""
        : index !== translations.languages.length - 1
        ? "separatorRtl "
        : "";

    return (
      <>
        {translations.languages.map((item, index) => {
          return (
            <div
              key={item.lang.toString()}
              style={{color: generalColor, borderColor: generalColor}}
              className={
                "languageButton " +
                ((this.state.language === item.lang ? "activeLanguage " : "") +
                  separatorStyle(index))
              }
              onClick={() => this.handleChangeLanguage(item.lang)}
            >
              {item.lang.replace(/^./, (str) => str.toUpperCase())}
            </div>
          );
        })}
      </>
    );
  }
}

export default withTranslation("translations")(LanguageSwitcher);
