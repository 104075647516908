import React from 'react';
import './Footer.css';
import {Link} from 'react-router-dom';
import {withTranslation} from "react-i18next";

import images from '../../images';
import {Mobile} from '../../App';
import RYM_He from '../../resources/RYM_He.pdf';
import RYM_En from '../../resources/RYM_En.pdf';
import i18next from '../../i18n';

class Footer extends React.Component {

    render() {
        const {t} = this.props
        return (
            <Mobile>
                {matches => {
                    if (matches) return this._renderMobile(t);
                    else return this._renderDesktop(t);
                }}
            </Mobile>
        );
    }

    _renderDesktop(t) {
        const {dropScreenID} = this.props;
        return (
            <div className="footer">
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}
                >
                    <h4
                        style={{
                            color: '#fff',
                            fontWeight: 'lighter',
                            fontSize: 12,
                            textAlign: 'center',
                        }}
                    >
                        {t('AllRightsReserve')}
                    </h4>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <p className="footerLink"
                        onClick={e => {
                            e.preventDefault();
                            this.props.openTerms();
                        }}
                    >
                        {t('TermsOfUse')}
                    </p>
                    <div className='footerLink_Divider'/>
                    <p className="footerLink"
                        onClick={e => {
                            e.preventDefault();
                            this.props.openPrivacy();
                        }}
                    >
                        {t('Privacy')}
                    </p>
                    <div className='footerLink_Divider'/>
                    <Link to={'/contacts'} className="footerLink" onClick={()=> dropScreenID()}>
                        {t('FooterContact')}
                    </Link>
                    <div className='footerLink_Divider'/>

                    <Link to={'/accessibility_statement'} className="footerLink" onClick={()=> dropScreenID()}>
                        {t('AccessibilityStatement')}
                    </Link>

                    <div className='footerLink_Divider'/>
                    <a
                        href={i18next.language === 'he'? RYM_He : RYM_En} download="FAQ.pdf"
                        className="footerLink"
                    >
                        {t('FAQ')}
                    </a>
                </div>
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        // direction: i18next.dir(),
                    }}
                >
                    <img className="logoMWAFooter" src={images().logo} alt="Logo"/>
                    <h5 className="myWaterAdvisor">{t('MyWaterAdvisor')}</h5>
                </div>
            </div>
        );
    }

    _renderMobile(t) {
        const {dropScreenID} = this.props;
        return (
            <div className="footerMobile">
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        direction: "ltr",
                        marginBottom: 26,
                    }}
                >
                    <img className="logoMWAFooter" src={images().logo} alt="Logo"/>
                    <h5 style={{color: 'white', fontSize: 14, fontWeight:700, margin: 0}}>
                        {t('MyWaterAdvisor')}
                    </h5>
                </div>
                <div
                    style={{
                        flex: 3,
                        flexWrap: 'wrap',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <p className="footerLink"
                        onClick={e => {
                            e.preventDefault();
                            this.props.openTerms();
                        }}
                    >
                        {t('TermsOfUse')}
                    </p>
                    <div className='footerLink_Divider'/>
                    <p className="footerLink"
                        onClick={e => {
                            e.preventDefault();
                            this.props.openPrivacy();
                        }}
                    >
                        {t('Privacy')}
                    </p>
                    <div className='footerLink_Divider'/>
                    <Link to={'/contacts'} className="footerLink" onClick={()=> dropScreenID()}>
                        {t('FooterContact')}
                    </Link>
                    <div className='footerLink_Divider'/>

                    <Link to={'/accessibility_statement'} className="footerLink" onClick={()=>dropScreenID()}>
                        {t('AccessibilityStatement')}
                    </Link>

                    <div className='footerLink_Divider'/>
                    <a
                        href={i18next.language === 'he'? RYM_He : RYM_En} download="FAQ.pdf"
                        className="footerLink"
                    >
                        {t('FAQ')}
                    </a>
                </div>
                <div style={{alignItems: 'center', marginLeft: 20, marginTop: 14}}>
                    <h4
                        style={{
                            color: "#fff",
                            fontWeight: 400,
                            fontSize: 12,
                            textAlign: 'center',
                            margin: 0,
                            direction: 'initial',
                        }}
                    >
                        {t('AllRightsReserve')}
                    </h4>
                </div>
            </div>
        );
    }
}

export default withTranslation('translations')(Footer)
