/* eslint-disable */
import React from 'react';
import globals from "../../globals";
import * as api from "../../api";
import './RegisterNotFound.css';
import {Link} from "react-router-dom";
import images from "../../images";
import {Mobile} from "../../App";
import {withTranslation} from "react-i18next";
import DataManager from "../../managers/DataManager";

import AppButton from "../../components/AppButton";

class RegisterNotFound extends React.Component {
    constructor(props) {
        super(props);

        this.type = props.match.params.type;

        this.state = {
            utilities: [],
        }
    }


    render() {
        const {t} = this.props
        return (
            <div>
                <Mobile>
                    {matches => {
                        if (matches)
                            return this._renderMobile(t);
                        else
                            return this._renderDesktop(t);
                    }}
                </Mobile>
            </div>
        );
    }

    _renderMobile(t) {
        return (
            <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginRight: 20,
                marginLeft: 20,
                marginTop: -36
            }}>
                <h1 className={"titleMobile"}>{t('JoinMyWaterAdvisor')}</h1>
                <div style={{margin: 20}} align="center">
                    <h3 className="accountNotFound">{this.type === 'phone' ? t('PhoneNumber') : t('Account')} {t('wasNotFound')}</h3>
                    <h3 className="tryAnother" style={{
                        marginLeft: 30,
                        marginRight: 30
                    }}>{t('pleaseTry')} {this.type === 'phone' ? t('PhoneNumber').toLowerCase() : t('Account').toLowerCase()} {t('orRegisteringWith') + ' '}
                        <Link
                            to={this.type === 'phone' ? '/register/account' : '/register/phone'}>{ (this.type === 'phone' ? t('AccountNumber') : t('PhoneNumber') ).toLowerCase()}</Link>
                    </h3>
                    <button style={{backgroundColor: 'transparent'}} onClick={() => this.props.history.goBack()}>
                        <AppButton text={strtings('buttons.back')} color='blue' onClick={() => null} />
                    </button>
                </div>
                {this.state.phoneError * this.state.accountError > 0
                &&  <p style={{justifyContent: 'center'}}
                       className="Need-help-Call-your-utility-office">לתמיכה טכנית בתקלות ניתן לפנות למרכז שירות ארד 1700-50-65-65</p>}
                <div style={{marginTop: 10, width: 320}}>
                    {this.state.utilities.map(item => {
                        return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <p style={{color: globals.COLOR.DUSK, fontSize: 16}}>{item.description}</p>
                            <p style={{
                                color: globals.COLOR.AZURE,
                                fontSize: 15,
                                marginLeft: 10
                            }}>{item.phoneNumber}</p>
                        </div>
                    })}
                </div>
            </div>);
    }

    _renderDesktop(t) {
        // console.log('errorCode', DataManager.sharedInstance.errorCode);
        console.log('this.props', this.props?.location?.search, this.props);

        let errorMessage = '';
        if (this.type === 'phone') {
            errorMessage = t('PhoneNumber') + t('wasNotFound');

            if (this.props?.location?.search !== "?errorCode=0") {
                errorMessage = t(this.props?.location?.search.replace("?errorCode=", ""));
                // errorMessage = DataManager.sharedInstance.errorMessage;
            }
        }
        

        return (
            <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 20}}>
                <h1 className={"title"}>{t('JoinMyWaterAdvisor')}</h1>
                <div style={{marginTop: 20}} align="center">
                    <h3 className="accountNotFound">{this.type === 'phone' ? errorMessage : t('Account') + ' ' + t('wasNotFound')}</h3>
                    <h3 className="tryAnother">{t('pleaseTry')} {this.type === 'phone' ? t('PhoneNumber').toLowerCase() : t('Account').toLowerCase()} {t('orRegisteringWith') + ' '}
                        <Link
                            to={this.type === 'phone' ? '/register/account' : '/register/phone'}>{(this.type === 'phone' ? t('AccountNumber') : t('PhoneNumber')).toLowerCase()}</Link>
                    </h3>
                    <button style={{backgroundColor: 'transparent'}} onClick={() => this.props.history.goBack()}>
                        <AppButton text={t('buttons.back')} color='blue' onClick={() => null} />
                    </button>
                </div>
                {DataManager.sharedInstance.loginErrors.phone * DataManager.sharedInstance.loginErrors.account > 0
                &&  <p style={{justifyContent: 'center'}}
                       className="Need-help-Call-your-utility-office">לתמיכה טכנית בתקלות ניתן לפנות למרכז שירות ארד 1700-50-65-65</p>}
                <div style={{marginTop: 10, width: 320}}>
                    {this.state.utilities.map(item => {
                        return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <p style={{color: globals.COLOR.DUSK, fontSize: 16}}>{item.description}</p>
                            <p style={{
                                color: globals.COLOR.AZURE,
                                fontSize: 15,
                                marginLeft: 10
                            }}>{item.phoneNumber}</p>
                        </div>
                    })}
                </div>
            </div>);
    }

    onChangeUtilityName = (event) => {
        const term = event.target.value;
        if (term.length >= 2) {
            api.searchUtilities(term, (res, err) => {
                if (!err) {
                    this.setState({utilities: res});
                }
            })
        } else if (term.length === 0) {
            this.setState({utilities: []});
        }
    };
}

export default withTranslation('translations')(RegisterNotFound)
