import React from 'react';
import {withTranslation} from "react-i18next";

import './Contacts.css';

class Contacts extends React.Component {

    render() {
        const {t, isLoggedIn} = this.props
        return (
            <div className="contactsContainer"  style={ !isLoggedIn? {marginTop: 0} : {}}>
                <h3 className="contactsContainer_title">{t('Contact')}</h3>
                <div className="RequestContacts_Container">
                    <p>{t('HappyToAssist')}</p>
                    <p>{t('InTouch')}</p>
                    <div className="RequestContacts_row">
                        <p>{t('Tel')}&nbsp;</p>
                        <p>1-700-50-6565</p>
                    </div>
                    <div className="RequestContacts_row">
                        <p>{t('mail')}:&nbsp;</p>
                        <a href="mailto:supportarad@arad.co.il">supportarad@arad.co.il</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation('translations')(Contacts)
