/* eslint-disable */
import React from 'react';
import './MonthlyLimitPopup.css';
import images from "../../images";
import '../../App.css';
import {Desktop, Mobile} from "../../App";
import Toggle from 'react-toggle';
import * as validators from "../../validators";
import DataManager from "../../managers/DataManager";
import {AlertType} from "../../utils";
import * as api from "../../api";
import Popup from "reactjs-popup";
import AppPopup from "../../components/AppPopup";
import {withTranslation} from "react-i18next";

import AppButton from "../../components/AppButton";


class MonthlyLimitPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            smsAlert: false,
            emailAlert: false,
            monthlyLimit: this.props.monthlyLimit ? this.props.monthlyLimit : '',
            errorMessage: null
        };
    }

    componentWillMount() {

        api.getAlertSettings((res, err) => {
            if (err) {
                console.log('Unknown error has occurred.');
            } else {
                if (res.error) {
                    console.log(res.error);
                } else {
                    if (res.length > 0) {
                        let emailAlert = false;
                        let smsAlert = false;

                        res.forEach(alertSetting => {
                            if (alertSetting.alertTypeId === AlertType.MONTHLY_CONS_LIMIT) {
                                alertSetting.mediaTypeId === 1 ? emailAlert = true : smsAlert = true;
                            }
                        });

                        this.setState({emailAlert, smsAlert});
                    }
                }
            }
        });
    }

    render() {
        const {t} = this.props
        return (
            <div>
                <Mobile>
                    {matches => {
                        if (matches)
                            return this._renderMobile(t);
                        else
                            return this._renderDesktop(t);
                    }}
                </Mobile>
            </div>
        );
    }

    _renderMobile(t) {
        const units = DataManager.sharedInstance.units;
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 10,
                paddingLeft: 20
            }}>
                <h1 className={"setMonthlyTitle"}>{t('SetBillingCycleLimit')}</h1>
                <div style={{marginTop: 10, flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
                    <input onChange={(event) => this.setState({monthlyLimit: event.target.value})} type="text"
                           className="borderRect"
                           style={{
                               paddingLeft: 10,
                               paddingRight: 10,
                               display: 'flex',
                               fontSize: 16,
                               textAlign: 'center'
                           }}
                           value={this.state.monthlyLimit}>
                    </input>
                    <div style={{marginLeft: 20}} className="monthlyLimitUnits">{units}</div>
                </div>
                <div style={{marginTop: 20, flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
                    <div className="monthlyLimitUnits">{t('ReceiveAlerts')}</div>
                    <div className="monthlyLimitUnits" style={{marginLeft: 10}}>{t('EmailAlert')}</div>
                    <Toggle icons={false} checked={this.state.emailAlert}
                            onChange={(e) => this._toggleEmailAlertSwitch(e.target.checked)}/>
                    <div className="monthlyLimitUnits" style={{marginLeft: 10}}>{t('SMSAlert')}</div>
                    <Toggle icons={false} checked={this.state.smsAlert}
                            onChange={(e) => this._toggleSMSAlertSwitch(e.target.checked)}/>
                </div>
                <div style={{
                    marginTop: 24,
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'row',
                    backgroundColor: 'transparent'
                }}>
                    <button type="submit" style={{border: 0, backgroundColor: 'transparent'}}
                            onClick={this.saveMonthlyLimit.bind(this)}>
                        <AppButton text={t('buttons.save')} color='blue' onClick={() => null} propsStyle={{width: 75, marginBottom: 30}} />
                    </button>
                    <div style={{width: 20}} />
                    <button style={{border: 0, backgroundColor: 'transparent'}} onClick={this.closePopup.bind(this)}>
                        <AppButton text={t('buttons.cancel')} color='white' onClick={() => null} propsStyle={{width: 75, marginBottom: 30}} />
                    </button>
                </div>
                <Popup
                    contentStyle={{borderRadius: 5}}
                    open={this.state.errorMessage}
                    onClose={this.closeErrorPopup.bind(this)}
                >
                    <AppPopup onClose={this.closeErrorPopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }

    _renderDesktop(t) {
        const units = DataManager.sharedInstance.units;
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 10,
                paddingLeft: 20
            }}>
                <h1 className={"setMonthlyTitle"}>{t('SetBillingCycleLimit')}</h1>
                <div style={{marginTop: 10, display: 'flex', alignItems: 'center'}}>
                    <input onChange={(event) => this.setState({monthlyLimit: event.target.value})} type="text"
                           className="borderRect"
                           style={{
                               paddingLeft: 10,
                               paddingRight: 10,
                               display: 'flex',
                               fontSize: 16,
                               textAlign: 'center'
                           }}
                           value={this.state.monthlyLimit}>
                    </input>
                    <div style={{marginLeft: 20}} className="monthlyLimitUnits">{units}</div>
                </div>
                <div style={{marginTop: 20, flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
                    <div className="monthlyLimitUnits">{t('ReceiveAlerts')}</div>
                    <div className="monthlyLimitUnits" style={{marginLeft: 40, marginRight: 15}}>{t('EmailAlert')}</div>
                    <Toggle icons={false} checked={this.state.emailAlert}
                            onChange={(e) => this._toggleEmailAlertSwitch(e.target.checked)}/>
                    <div className="monthlyLimitUnits" style={{marginLeft: 40, marginRight: 15}}>{t('SMSAlert')}</div>
                    <Toggle icons={false} checked={this.state.smsAlert}
                            onChange={(e) => this._toggleSMSAlertSwitch(e.target.checked)}/>
                </div>
                <div style={{
                    marginTop: 24,
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'row',
                    backgroundColor: 'transparent'
                }}>
                    <button type="submit" style={{border: 0, backgroundColor: 'transparent'}}
                            onClick={this.saveMonthlyLimit.bind(this)}>
                        <AppButton text={t('buttons.save')} color='blue' onClick={() => null} propsStyle={{width: 160, marginBottom: 30}} />
                    </button>
                    <div style={{width: 40}} />
                    <button style={{border: 0, backgroundColor: 'transparent'}} onClick={this.closePopup.bind(this)}>
                        <AppButton text={t('buttons.cancel')} color='white' onClick={() => null} propsStyle={{width: 160, marginBottom: 30}} />
                    </button>
                </div>
                <Popup
                    contentStyle={{borderRadius: 5}}
                    open={this.state.errorMessage}
                    onClose={this.closeErrorPopup.bind(this)}
                >
                    <AppPopup onClose={this.closeErrorPopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }

    saveMonthlyLimit = () => {
        const {t} = this.props
        if (isNaN(this.state.monthlyLimit)) {
            this.setState({errorMessage: t('InvalidMonthlyLimitSet')});
            return;
        }

        if (this.state.smsAlert) {
            api.setAlert(AlertType.MONTHLY_CONS_LIMIT, 3, (res, err) => {

            });
        } else {
            api.unsetAlert(AlertType.MONTHLY_CONS_LIMIT, 3, (res, err) => {

            });
        }

        if (this.state.emailAlert) {
            api.setAlert(AlertType.MONTHLY_CONS_LIMIT, 1, (res, err) => {

            });
        } else {
            api.unsetAlert(AlertType.MONTHLY_CONS_LIMIT, 1, (res, err) => {

            });
        }

        if (this.state.monthlyLimit === '') {
            //deleteMonthlyLimit
            api.deleteMonthlyLimit((res, err) => {
                if (err) {
                    console.log('setMonthlyLimit, ' + err);
                }
            });
        } else {
            api.setMonthlyLimit(this.state.monthlyLimit, (res, err) => {
                if (err) {
                    console.log('setMonthlyLimit, ' + err);
                }
            });
        }


        this.closePopupSave(this.state.monthlyLimit);
    };

    closePopupSave = (monthlyLimit) => {
        this.props.onClose(monthlyLimit);
    };

    closePopup = () => {
        this.props.onClose();
    };

    closeErrorPopup = () => {
        this.setState({errorMessage: null});
    };

    _toggleSMSAlertSwitch(smsAlert) {
        this.setState({smsAlert});
    }

    _toggleEmailAlertSwitch(emailAlert) {
        this.setState({emailAlert});
    }
}

export default withTranslation('translations')(MonthlyLimitPopup);
