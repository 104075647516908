// /* eslint-disable */
import React from "react";
import Responsive from "react-responsive";
import { Link, HashRouter, Route, withRouter } from "react-router-dom";
import Popup from "reactjs-popup";
import i18next from "i18next";
import { withTranslation } from "react-i18next";
import moment from "moment";
import 'moment/locale/he';
import 'moment/locale/ar';
import 'moment/locale/es';

import "./App.css";
import "./React-Toggle.css";
import Header from "./common/Header/Header";
import Footer from "./common/Footer/Footer";
import Register from "./scenes/register/Register";
import images from "./images";
import globals from "./globals";
import Login from "./scenes/login/Login";
import ForgotPassword from "./scenes/password/ForgotPassword";
import RegisterAccount from "./scenes/register/RegisterAccount";
import RegisterPhone from "./scenes/register/RegisterPhone";
import RegisterEnterCode from "./scenes/register/RegisterEnterCode";
import RegisterNotFound from "./scenes/register/RegisterNotFound";
import TermsOfUse from "./scenes/common/TermsOfUse";
import RegisterFinish from "./scenes/register/RegisterFinish";
import ChangePassword from "./scenes/password/ChangePassword";
import EnterCodePassword from "./scenes/password/EnterCodePassword";
import DataManager from "./managers/DataManager";
import Settings from "./scenes/settings/Settings";
import Home from "./scenes/home/Home";
import SettingsChangePassword from "./scenes/settings/SettingsChangePassword";
import SettingsChangePhone from "./scenes/settings/SettingsChangePhone";
import Alerts from "./scenes/alerts/Alerts";
import AlertInfo from "./scenes/alerts/AlertInfo";
import NewRequest from "./scenes/requests/NewRequest";
import * as api from "./api";
import PersistenceManager from "./managers/PersistenceManager";
import LandingPage from "./scenes/home/LandingPage";
import PrivacyPolicy from "./scenes/common/PrivacyPolicy";
import ProtectedRoute from "./common/ProtectedRoute";
import Contacts from "./scenes/contacts/Contacts";
import AccessibilityStatement from "./scenes/accessibility_statement/AccessibilityStatement";
import About from "./scenes/about/About";
import { Offline } from "react-detect-offline";
import AppPopup from "./components/AppPopup";
import AdminLogin from "./scenes/admin_login/AdminLogin";
import i18n from "./i18n";
import AppButton from "./components/AppButton";

export const Desktop = (props) => <Responsive {...props} minWidth={992} />;
export const Tablet = (props) => (
  <Responsive {...props} minWidth={768} maxWidth={991} />
);
export const Mobile = (props) => <Responsive {...props} maxWidth={767} />;
export const MobileLand = (props) => (
  <Responsive {...props} maxWidth={767} orientation={"landscape"} />
);
export const Default = (props) => <Responsive {...props} minWidth={768} />;

// const dataForTest = {
//     requests: [
//         {
//             "answerID": 65,
//             "requestID": 562,
//             "answerText": "To test set-read function answer",
//             "answerDate": null,
//             "requestDate": "2021-04-11T07:49:23.747",
//             "wasRead": false,
//             "requestText": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
//             "requestSubject": "צריכה שגויה",
//             "registration_ID": null,
//             "os_Type_ID": null,
//             "device_ID": null
//         },
//         {
//             "answerID": 66,
//             "requestID": 563,
//             "answerText": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//             "answerDate": "2021-04-12T15:03:48.6",
//             "requestDate": "2021-02-11T08:49:55.01",
//             "wasRead": true,
//             "requestText": "The automatic reading system of your water utility has detected a \"suspected leak\" alert. This alert is detected when there is a constant use of water for a period of 24 hours. The reason for high water usage can be genuine usage, a leak in the line, or a problem in one of your water devices (leaky hose.",
//             "requestSubject": " wasReadצריכה שגויה",
//             "registration_ID": null,
//             "os_Type_ID": null,
//             "device_ID": null
//         },
//         {
//             "answerID": 67,
//             "requestID": 564,
//             "answerText": "To test set-read function answer",
//             "answerDate": "2021-03-02T18:04:18.9",
//             "requestDate": "2021-02-15T08:04:29.75",
//             "wasRead": null,
//             "requestText": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
//             "requestSubject": "nullצריכה שגויה",
//             "registration_ID": null,
//             "os_Type_ID": null,
//             "device_ID": null
//         },
//         {
//             "answerID": 68,
//             "requestID": 565,
//             "answerText": "To test set-read function answer",
//             "answerDate": "2021-03-02T15:04:24.17",
//             "requestDate": "2021-02-14T12:03:53.01",
//             "wasRead": true,
//             "requestText": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
//             "requestSubject": "wasReadצריכה שגויה",
//             "registration_ID": null,
//             "os_Type_ID": null,
//             "device_ID": null
//         },
// //         {
// //             "answerID": 69,
// //             "requestID": 566,
// //             "answerText": "To test set-read function answer",
// //             "answerDate": "2021-03-02T15:04:36.387",
// //             "requestDate": "2021-02-14T12:06:54.017",
// //             "wasRead": true,
// //             "requestText": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
// //             "requestSubject": "צריכה שגויה",
// //             "registration_ID": null,
// //             "os_Type_ID": null,
// //             "device_ID": null
// //         },
// //         {
// //             "answerID": 71,
// //             "requestID": 568,
// //             "answerText": "To test set-read function answer",
// //             "answerDate": "2021-03-02T15:04:47.58",
// //             "requestDate": "2021-02-16T07:28:57.487",
// //             "wasRead": true,
// //             "requestText": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
// //             "requestSubject": "צריכה שגויה",
// //             "registration_ID": null,
// //             "os_Type_ID": null,
// //             "device_ID": null
// //         },
// //         {
// //             "answerID": 72,
// //             "requestID": 569,
// //             "answerText": "To test set-read function answer",
// //             "answerDate": null,
// //             "requestDate": "2021-02-16T07:29:22.94",
// //             "wasRead": true,
// //             "requestText": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
// //             "requestSubject": "אחר",
// //             "registration_ID": null,
// //             "os_Type_ID": null,
// //             "device_ID": null
// //         },
// //         {
// //             "answerID": 73,
// //             "requestID": 570,
// //             "answerText": "To test set-read function answer",
// //             "answerDate": "2021-03-02T15:04:57.193",
// //             "requestDate": "2021-02-17T11:04:02.467",
// //             "wasRead": false,
// //             "requestText": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
// //             "requestSubject": "אחר",
// //             "registration_ID": null,
// //             "os_Type_ID": null,
// //             "device_ID": null
// //         },
// //         {
// //             "answerID": 74,
// //             "requestID": 571,
// //             "answerText": "To test set-read function answer",
// //             "answerDate": "2021-03-02T15:05:18.177",
// //             "requestDate": "2021-02-17T11:05:24.347",
// //             "wasRead": true,
// //             "requestText": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
// //             "requestSubject": "אחר",
// //             "registration_ID": null,
// //             "os_Type_ID": null,
// //             "device_ID": null
// //         },
// //         {
// //             "answerID": 60,
// //             "requestID": 572,
// //             "answerText": "Answer from municipal",
// //             "answerDate": "2021-02-22T14:50:04.603",
// //             "requestDate": "2021-02-17T11:37:03.353",
// //             "wasRead": true,
// //             "requestText": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
// //             "requestSubject": "WasReadTest",
// //             "registration_ID": null,
// //             "os_Type_ID": null,
// //             "device_ID": null
// //         },
// //         {
// //             "answerID": 59,
// //             "requestID": 573,
// //             "answerText": "Answer from municipal",
// //             "answerDate": "2021-02-22T14:49:58.883",
// //             "requestDate": "2021-02-17T11:37:53.647",
// //             "wasRead": true,
// //             "requestText": "WasReadTest",
// //             "requestSubject": "WasReadTest",
// //             "registration_ID": null,
// //             "os_Type_ID": null,
// //             "device_ID": null
// //         },
// //         {
// //             "answerID": 58,
// //             "requestID": 574,
// //             "answerText": "Answer from municipal",
// //             "answerDate": "2021-02-22T14:49:53.32",
// //             "requestDate": "2021-02-17T13:56:50.23",
// //             "wasRead": true,
// //             "requestText": "stdfsdfsfsdfsdfsdfring",
// //             "requestSubject": "ckvckvck",
// //             "registration_ID": null,
// //             "os_Type_ID": null,
// //             "device_ID": null
// //         },
// //         {
// //             "answerID": 57,
// //             "requestID": 575,
// //             "answerText": "Answer from municipal",
// //             "answerDate": "2021-02-22T14:48:03.203",
// //             "requestDate": "2021-02-18T12:43:13.603",
// //             "wasRead": true,
// //             "requestText": "WasReadTest",
// //             "requestSubject": "WasReadTest",
// //             "registration_ID": null,
// //             "os_Type_ID": null,
// //             "device_ID": null
// //         },
// //         {
// //             "answerID": null,
// //             "requestID": 576,
// //             "answerText": null,
// //             "answerDate": null,
// //             "requestDate": "2021-02-28T15:42:55.237",
// //             "wasRead": null,
// //             "requestText": "testtesttest",
// //             "requestSubject": "פתיחת תקלה",
// //             "registration_ID": null,
// //             "os_Type_ID": null,
// //             "device_ID": null
// //         },
// //         {
// //             "answerID": null,
// //             "requestID": 577,
// //             "answerText": null,
// //             "answerDate": null,
// //             "requestDate": "2021-02-28T16:24:08.373",
// //             "wasRead": null,
// //             "requestText": "Test test test",
// //             "requestSubject": "צריכה שגויה",
// //             "registration_ID": null,
// //             "os_Type_ID": null,
// //             "device_ID": null
// //         },
// //         {
// //             "answerID": null,
// //             "requestID": 578,
// //             "answerText": null,
// //             "answerDate": null,
// //             "requestDate": "2021-02-28T17:19:26.003",
// //             "wasRead": null,
// //             "requestText": "New test message",
// //             "requestSubject": "פתיחת תקלה",
// //             "registration_ID": null,
// //             "os_Type_ID": null,
// //             "device_ID": null
// //         },
// //         {
// //             "answerID": null,
// //             "requestID": 583,
// //             "answerText": null,
// //             "answerDate": null,
// //             "requestDate": "2021-03-01T15:37:15.913",
// //             "wasRead": null,
// //             "requestText": "שלום אני חושת שיש לי צריכה שגויה המדווחת במערכת ",
// //             "requestSubject": "צריכה שגויה",
// //             "registration_ID": null,
// //             "os_Type_ID": null,
// //             "device_ID": null
// //         },
// //         {
// //             "answerID": null,
// //             "requestID": 584,
// //             "answerText": null,
// //             "answerDate": null,
// //             "requestDate": "2021-03-01T15:39:55.367",
// //             "wasRead": null,
// //             "requestText": "יצחיצי",
// //             "requestSubject": "צריכה שגויה",
// //             "registration_ID": null,
// //             "os_Type_ID": null,
// //             "device_ID": null
// //         },
// //         {
// //             "answerID": null,
// //             "requestID": 586,
// //             "answerText": null,
// //             "answerDate": null,
// //             "requestDate": "2021-03-02T13:27:59.543",
// //             "wasRead": null,
// //             "requestText": "test",
// //             "requestSubject": "צריכה שגויה",
// //             "registration_ID": null,
// //             "os_Type_ID": null,
// //             "device_ID": null
// //         },
// //         {
// //             "answerID": null,
// //             "requestID": 587,
// //             "answerText": null,
// //             "answerDate": null,
// //             "requestDate": "2021-03-02T15:34:03.073",
// //             "wasRead": null,
// //             "requestText": "test",
// //             "requestSubject": "פתיחת תקלה",
// //             "registration_ID": null,
// //             "os_Type_ID": null,
// //             "device_ID": null
// //         },
// //         {
// //             "answerID": null,
// //             "requestID": 589,
// //             "answerText": null,
// //             "answerDate": null,
// //             "requestDate": "2021-03-04T15:36:45.253",
// //             "wasRead": null,
// //             "requestText": "Test message from user",
// //             "requestSubject": "פתיחת תקלה",
// //             "registration_ID": null,
// //             "os_Type_ID": null,
// //             "device_ID": null
// //         },
// //         {
// //             "answerID": 75,
// //             "requestID": 590,
// //             "answerText": "אנו נבדוק את הבעיה ונחזור אליך בתוך 7 ימי עסקים",
// //             "answerDate": "2021-03-18T10:15:49.41",
// //             "requestDate": "2021-03-17T16:27:32.857",
// //             "wasRead": false,
// //             "requestText": "TEST",
// //             "requestSubject": "עדכון פרטים",
// //             "registration_ID": null,
// //             "os_Type_ID": null,
// //             "device_ID": null
// //         }
//     ],
// //     meters: [
// //             {
// //                 "meterCount": 98648,
// //                 "meterSn": "9970918",
// //                 "fullAddress": "פתח תקווה, פרישמן דוד, 20"
// //             },
// //         {
// //             "meterCount": 98648,
// //             "meterSn": "9970918",
// //             "fullAddress": "פtesttest20"
// //         },
// //         {
// //             "meterCount": 98648,
// //             "meterSn": "9970918",
// //             "fullAddress": "Love Haifa"
// //         },
// //     ],
//     alerts: [
//
//         // {
//         //     "notificationType": null,
//         //     "alertTypeName": "צריכה מעל למקס12",
//         //     "alertTypeId": "12",
//         //     "alertTime": "2020-12-06T15:00:30",
//         //     "logId": "74546912",
//         //     "meterCount": "98648",
//         //     "meterSn": null
//         // },
//         // {
//         //     "notificationType": null,
//         //     "alertTypeName": "צריכה מעל לממוצע14",
//         //     "alertTypeId": "14",
//         //     "alertTime": "2021-03-07T16:51:40.173",
//         //     "logId": "1310414",
//         //     "meterCount": "98648",
//         //     "meterSn": null
//         // },
//         //
//         // {
//         //     "notificationType": null,
//         //     "alertTypeName": "צריכה חריגה מאוד16",
//         //     "alertTypeId": "16",
//         //     "alertTime": "2020-12-06T15:00:30",
//         //     "logId": "74546916",
//         //     "meterCount": "98648",
//         //     "meterSn": null
//         // },
//         // {
//         //     "notificationType": null,
//         //     "alertTypeName": "חשד לדליפה23",
//         //     "alertTypeId": "23",
//         //     "alertTime": "2020-03-07T16:50:48.08",
//         //     "logId": "131040123",
//         //     "meterCount": "98648",
//         //     "meterSn": null
//         // },
//         // {
//         //     "notificationType": null,
//         //     "alertTypeName": "לדליפה במונה ראשי35",
//         //     "alertTypeId": "35",
//         //     "alertTime": "2020-03-07T16:50:48.08",
//         //     "logId": "131040135",
//         //     "meterCount": "98648",
//         //     "meterSn": null
//         // },
//         // {
//         //     "notificationType": null,
//         //     "alertTypeName": "צריכה בזמן היעדרות1001",
//         //     "alertTypeId": "1001",
//         //     "alertTime": "2021-03-07T16:51:40.173",
//         //     "logId": "1310434-1001",
//         //     "meterCount": "986481",
//         //     "meterSn": null
//         // },
//     ],
// }

const initialState = {
  openTerms: false,
  openPrivacy: false,
  openChangePass: false,
  openChangePhone: false,
  isLoggedIn: false,
  currentScreen: 0,
  shouldShowBack: true,
  showHeader: true,
  showRightButton: true,
  showSignUpButton: false,
  initLoading: true,
  alertsCount: 0,
  errorMessage: null,
  alertsTimers: null,
  meters: [],
  units: "",
  user: {
    firstName: "",
    lastName: "",
    accountNumber: "",
    phoneNumber: "",
    municipalId: "",
  },
  vacation: null,
  muni: null,
  alerts: [],
  isAlertLoaded: false,
  isMessagesAllowed: false,
  requests: [],
  isRequestsLoaded: false,
  showAlertBadge: false,
  sessionId: "",
  isAuthenticated: false,
  clearedAlerts: [],
  loginErrors: { phone: 0, account: 0 },
  subjects: [],
  alertsSettings: [],
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;

    this.updateInterval = null;
  }

  getAlerts = () => {
    if (!this.state.isLoggedIn) return;

    const { t } = this.props;
    const alerts = () =>
      new Promise((resolve, reject) =>
        api.getAlerts((res, err) => {
          if (err) {
            this.setState({ errorMessage: t("errorOccurred") });
            console.log('getAlerts 1', res, err);
          } else {
            if (res.error) {
              console.log(res);
              console.log(err);
              resolve(res.error);
            } else {
              console.log('getAlerts alerts', res);
              this.setState({
                alerts:
                  // dataForTest.alerts,
                  res.sort(
                    (a, b) =>
                      moment(b.alertTime).unix() - moment(a.alertTime).unix()
                  ),
                isAlertLoaded: true,
              });
              resolve(res);
            }
          }
        })
      );

    Promise.all([alerts()])
      .then((res) => {
        this.setState({
          alertsCount:
            this.state.alerts.filter((alert) => alert.isRead === "false")
              .length +
            this.state.requests.filter(
              (req) => req?.wasRead !== null && !req?.wasRead
            ).length,
        });
      })
      .catch((err) => {
        this.setState({ errorMessage: err.toString });
      });
  };

  fetchData = () => {
    api.getMeters((res, err) => {
      if (err) {
        console.log("Unknown error has occurred.");
      } else {
        if (res.error) {
          console.log(res.error);
        } else {
          console.log("meters", res);
          this.setState({ meters: res });

          api.getLastRead((response, err) => {
            if (err) {
              console.log("getLastRead", "Unknown error has occurred.");
            } else {
              if (response.error) {
                console.log("getLastRead", response.error);
              } else {
                console.log("getLastRead", response);
                let meters = res;

                 // mock data
          // [ { meterCount: 6835,
          //   meterSn: '10690092',
          //   fullAddress: '1898 N 300 E' } ]
          
          // mock data
          // for (let i = 0; i < 30; i++) {
          //   meters.push(meters[0]);
          // }

                for (let i = 0; i < meters.length; i++) {
                  for (let k = 0; k < response.length; k++) {
                    if (meters[i].meterCount === response[k].meterCount) {
                      meters[i].meterId = response[k].meterId;
                      meters[i].read = response[k].read;
                      break;
                    }
                  }
                }

                this.setState({ meters });
              }
            }
          });
        }
      }
    });

    //Get measurement units
    api.getMeasurementUnits((res, err) => {
      //this._checkFirstDataFetchCounter();
      if (err) {
        console.log("Unknown error has occurred.");
      } else {
        if (res.error) {
          console.log(res.error);
        } else {
          this.setState({ units: res.unit });
        }
      }
    });

    api.getMunicipalCustomerService((res, err) => {
      if (err) {
        console.log("Unknown error has occurred.");
      } else {
        if (res.error) {
          console.log(res.error);
        } else {
          // DataManager.sharedInstance.muni = {...res};
          this.setState({ muni: res });
        }
      }
    });

    //Get consumer vacations
    api.getVacations((res, err) => {
      if (err) {
        console.log("Unknown error has occurred.");
      } else {
        if (res.error) {
          console.log(res.error);
        } else {
          if (res.length > 0) {
            const vacation = res[0];
            this.setState({
              vacation,
            });
          }
        }
      }
    });

    this.getAlerts();
    // this.setState({
    //   alertsTimers: setInterval(() => this.getAlerts(), 60 * 1000),
    // });

    this.updateInterval = setInterval(() => this.getAlerts(), 60 * 1000);

    api.getAlertsForSettings((res, err) => {
      if (err) {
        console.log("Unknown error has occurred.");
      } else {
        if (res.error) {
          console.log(res.error);
        } else {
          this.setState({ alertsSettings: res });
        }
      }
    });

  };

  componentDidMount() {
    DataManager.sharedInstance.onEnter = this.onEnter;
    const token = PersistenceManager.getItem(globals.STORAGE.TOKEN);

    if (token) {
      api.setTokenValue(token);

      switch (this.props.location.pathname) {
        case "/settings":
          this.setState({ currentScreen: 1 });
          break;
        case "/alerts":
          this.setState({ currentScreen: 2 });
          break;
        case "/new_request":
          this.setState({ currentScreen: 3 });
          break;
        case "/home":
          this.setState({ currentScreen: 0 });
          break;
        default:
          this.setState({ currentScreen: "" });
      }

      api.getMe((res, err) => {
        if (err) {
          console.log("Unknown error has occurred.");
        } else {
          if (res.error) {
            console.log(res.error);

            if (res.code === 401) {
              PersistenceManager.deleteItem(globals.STORAGE.TOKEN);
              this.setState({ errorMessage: res.error });
            }

            this.setState({
              initLoading: false,
            });
          } else {
            // Additional phone number fix
            if (res?.phoneNumber?.countryCode && res?.phoneNumber?.AdditionalPhoneNumber && res.phoneNumber.AdditionalPhoneNumber.includes(res.phoneNumber.countryCode)) {
              res.phoneNumber.AdditionalPhoneNumber = res.phoneNumber.AdditionalPhoneNumber.replace(res.phoneNumber.countryCode, '');
              if (res.phoneNumber.AdditionalPhoneNumber.includes('+')) {
                res.phoneNumber.AdditionalPhoneNumber = res.phoneNumber.AdditionalPhoneNumber.replace('+', '');
              }
            }

            DataManager.sharedInstance.user = res;
            DataManager.sharedInstance.isAuthenticated = true;
            this.setState({
              isLoggedIn: true,
              initLoading: false,
              isAuthenticated: true,
              user: res,
            });
            this.fetchData();
          }
        }
      });
    } else {
      this.setState({
        initLoading: false,
      });
    }
  }

  updateUser() {
    api.getMe((res, err) => {
      if (err) {
        console.log("Unknown error has occurred.");
      } else {
        if (res.error) {
          console.log(res.error);
        } else {
          // Additional phone number fix
          if (res?.phoneNumber?.countryCode && res?.phoneNumber?.AdditionalPhoneNumber && res.phoneNumber.AdditionalPhoneNumber.includes(res.phoneNumber.countryCode)) {
            res.phoneNumber.AdditionalPhoneNumber = res.phoneNumber.AdditionalPhoneNumber.replace(res.phoneNumber.countryCode, '');
            if (res.phoneNumber.AdditionalPhoneNumber.includes('+')) {
              res.phoneNumber.AdditionalPhoneNumber = res.phoneNumber.AdditionalPhoneNumber.replace('+', '');
            }
          }

          DataManager.sharedInstance.user = res;
          this.setState({ user: res });
        }
      }
    });
  }

  handleStateUpdate(newState) {
    this.setState(newState);
  }

  confirmAlert() {
    this.setState({ alertsCount: this.state.alertsCount - 1 });
  }

  render() {
    const { t } = this.props;
    const { initLoading } = this.state;
    document.body.dir = i18next.dir();
    moment.locale(i18next.language)

    if (initLoading) {
      return <div />;
    }

    return (
      <Mobile>
        {(matches) => {
          if (matches) return this._renderMobile(t);
          else return this._renderDesktop(t);
        }}
      </Mobile>
    );
  }

  _onRightButtonPressed() {
    this.setState({ showSignUpButton: !this.state.showSignUpButton });
  }

  _renderLoggedOutStripDesktop(t) {
    const pathname = window.location.pathname;
    const showHomeButton = !(
      pathname === "/register" ||
      pathname === "/login" ||
      pathname === "/register/phone" ||
      pathname === "/register/account"
    );

    if (this.state.showHeader)
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            // alignItems: 'center',
            zIndex: 1,
            marginTop: 20,
            height: 85,
          }}
        >
          {showHomeButton ? (
            <Link to={"/"} className="ToLandingLink">
              <img src={images().icBackBlue} alt="home" />
              <div style={{ textDecoration: "initial", marginBottom: 3 }}>
                {t("Home")}
              </div>
            </Link>
          ) : (
            <div />
          )}

          <Link
            className="purpleButton logoutTabButton"
            to={this.state.showSignUpButton ? "/register" : "/login"}
            onClick={() => this._onRightButtonPressed()}
          >
            {this.state.showSignUpButton
              ? t("SignUp")
              : t("Login").toUpperCase()}
          </Link>
        </div>
      );
    else {
      return null;
    }
  }

  _renderLoggedOutStripMobile(t) {
    const pathname = window.location.pathname;
    const showHomeButton = !(
      pathname === "/register" ||
      pathname === "/login" ||
      pathname === "/register/phone" ||
      pathname === "/register/account"
    );

    if (this.state.showHeader)
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            zIndex: 10,
          }}
        >
          {showHomeButton ? (
            <Link to={"/"} className="ToLandingLink">
              <img src={images().icBackBlue} alt="home" />
              <div style={{ textDecoration: "initial", marginBottom: 3 }}>
                {t("Home")}
              </div>
            </Link>
          ) : (
            <div />
          )}

          <Link
            className="purpleButton logoutTabButton"
            to={this.state.showSignUpButton ? "/register" : "/login"}
            onClick={() => this._onRightButtonPressed()}
          >
            {this.state.showSignUpButton
              ? t("SignUp")
              : t("Login").toUpperCase()}
          </Link>
        </div>
      );
    else {
      return null;
    }
  }

  _renderLoggedInStripDesktop(t) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          backgroundColor: "#3659c3",
          height: 60,
          justifyContent: "space-between",
          paddingRight: 145,
          paddingLeft: 145,
        }}
      >
        <div style={{ display: "flex", alignContent: "center" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginLeft: i18next.dir() === "rtl" ? 30 : 0,
            }}
          >
            <Link
              to={"/home"}
              style={{
                textDecoration: "none",
                backgroundColor: "transparent",
                color: "white",
                marginTop: 20,
                fontSize: i18next.dir() === "rtl" ? 18 : 16,
              }}
              onClick={this.onHomePressed.bind(this)}
            >
              {t("HOME")}
            </Link>
            {this.state.currentScreen === 0 && (
              <div className="activeMenuIndicator" />
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginLeft: 30,
            }}
          >
            <Link
              to={"/alerts"}
              style={{
                textDecoration: "none",
                backgroundColor: "transparent",
                color: "white",
                display: "flex",
                direction: "ltr",
              }}
              onClick={() => this.onAlertsPressed()}
            >
              <div
                style={{
                  marginTop: 20,
                  fontSize: i18next.dir() === "rtl" ? 18 : 16,
                }}
              >
                {t("ALERTS")}
              </div>

              <div className="alertsBellContainer">
                <div style={{ position: "absolute", display: "flex" }}>
                  <img
                    src={images().icAlerts}
                    className="alertsBell"
                    alt="alerts counter"
                  />
                  {this.state.alertsCount > 0 && (
                    <div className="alertsCounter">
                      {this.state.alertsCount}
                    </div>
                  )}
                </div>
              </div>
            </Link>
            {this.state.currentScreen === 2 && (
              <div
                className="activeMenuIndicator"
                style={{ marginRight: 10 }}
              />
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginLeft: 30,
            }}
          >
            <Link
              to={"/new_request"}
              style={{
                textDecoration: "none",
                backgroundColor: "transparent",
                color: "white",
                marginTop: 20,
                fontSize: i18next.dir() === "rtl" ? 18 : 16,
              }}
              onClick={() => this.onNewRequestPressed()}
            >
              {t("NewMessage").toUpperCase()}
            </Link>
            {this.state.currentScreen === 3 && (
              <div className="activeMenuIndicator" />
            )}
          </div>


          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginLeft: 30,
            }}
          >
            <Link
              to={"/settings"}
              style={{
                textDecoration: "none",
                backgroundColor: "transparent",
                color: "white",
                marginTop: 20,
                fontSize: i18next.dir() === "rtl" ? 18 : 16,
              }}
              onClick={() => this.onSettingsPressed()}
            >
              {t("SETTINGS")}
            </Link>
            {this.state.currentScreen === 1 && (
              <div className="activeMenuIndicator" />
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "space-between",
          }}
        >
          <div className={"sep"} />
          <h4 className="welcomeText">
            {t("Welcome")} {this.state.user.firstName}{" "}
            {this.state.user.lastName}
          </h4>
          <Link
            to={"/"}
            style={{
              border: 0,
              backgroundColor: "transparent",
              marginTop: 5,
              textDecoration: 'none'
              // marginLeft: -20,
              // marginRight: -20,
            }}
            onClick={this.onLogout.bind(this)}
          >
            <AppButton text={t('buttons.logOut')} color='blue' onClick={() => null} propsStyle={{width: 200, backgroundColor: "rgb(54, 89, 195)", border: "1px solid #b0cdff", boxShadow: null, color: "#b0cdff"}} />
          </Link>
        </div>
      </div>
    );
  }

  _renderLoggedInStripMobile(t) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#3659c3",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginLeft: 20,
          }}
        >
          <h4 className={"welcomeText"}>
            Welcome {this.state.user.firstName} {this.state.user.lastName}
          </h4>
          <Link
            to={"/"}
            style={{ border: 0, backgroundColor: "transparent", textDecoration: 'none' }}
            onClick={this.onLogout.bind(this)}
          >
            <AppButton text={t('buttons.logOut')} color='blue' onClick={() => null} propsStyle={{width: 75, backgroundColor: "rgb(54, 89, 195)", border: "1px solid #b0cdff", boxShadow: null, color: "#b0cdff"}} />
          </Link>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <div
            className={
              "NavbarLinkContainer " +
              (this.state.currentScreen === 0 && "activeMenuIndicatorUnderline")
            }
          >
            <Link
              to={"/home"}
              style={{
                textDecoration: "none",
                backgroundColor: "transparent",
                color: "white",
                alignSelf: "center",
                fontSize: i18next.dir() === "rtl" ? 16 : 14,
                height: 15,
              }}
              onClick={this.onHomePressed.bind(this)}
            >
              {t("HOME")}
            </Link>
            {/*{this.state.currentScreen === 0 && <div className="activeMenuIndicator"/>}*/}
          </div>

          <div
            className={
              "NavbarLinkContainer " +
              (this.state.currentScreen === 2 && "activeMenuIndicatorUnderline")
            }
          >
            <Link
              to={"/alerts"}
              style={{
                textDecoration: "none",
                backgroundColor: "transparent",
                color: "white",
                display: "flex",
                direction: "ltr",
              }}
              onClick={() => this.onAlertsPressed()}
            >
              <div style={{ fontSize: i18next.dir() === "rtl" ? 16 : 14 }}>
                {t("ALERTS")}
              </div>

              <div className="alertsBellContainer">
                <div style={{ position: "absolute", display: "flex" }}>
                  <img
                    src={images().icAlerts}
                    className="alertsBell"
                    alt="alerts count"
                  />
                  {this.state.alertsCount > 0 && (
                    <div className="alertsCounter">
                      {this.state.alertsCount}
                    </div>
                  )}
                </div>
              </div>
            </Link>
            {/*{this.state.currentScreen === 2 && <div className="activeMenuIndicator" />}*/}
          </div>

          <div
            className={
              "NavbarLinkContainer " +
              (this.state.currentScreen === 3 && "activeMenuIndicatorUnderline")
            }
          >
            <Link
              to={"/new_request"}
              style={{
                textDecoration: "none",
                backgroundColor: "transparent",
                color: "white",
                alignSelf: "center",
                fontSize: i18next.dir() === "rtl" ? 16 : 14,
                height: 15,
              }}
              onClick={() => this.onNewRequestPressed()}
            >
              {t("NewMessage").toUpperCase()}
            </Link>
            {/*{this.state.currentScreen === 3 && <div className="activeMenuIndicator"/>}*/}
          </div>


          <div
            className={
              "NavbarLinkContainer " +
              (this.state.currentScreen === 1 && "activeMenuIndicatorUnderline")
            }
          >
            <Link
              to={"/settings"}
              style={{
                textDecoration: "none",
                backgroundColor: "transparent",
                color: "white",
                alignSelf: "center",
                fontSize: i18next.dir() === "rtl" ? 16 : 14,
                height: 15,
              }}
              onClick={() => this.onSettingsPressed()}
            >
              {t("SETTINGS")}
            </Link>
            {/*{this.state.currentScreen === 1 && <div className="activeMenuIndicator"/>}*/}
          </div>
        </div>
      </div>
    );
  }

  _renderMobile(t) {
    return (
      <HashRouter>
        <div
          lang={i18n.language}
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            minHeight: "100vh",
          }}
        >
          <Offline>
            <div
              style={{
                backgroundColor: "red",
                padding: 2,
                width: "100%",
                color: "white",
                textAlign: "center",
              }}
            >
              No internet connection
            </div>
          </Offline>
          {this.state.showHeader && <Header />}
          {this.state.isLoggedIn
            ? this._renderLoggedInStripMobile(t)
            : this._renderLoggedOutStripMobile(t)}

          <Route
            exact
            path="/"
            render={(props) => (
              <LandingPage
                {...props}
                toggleHeader={this.toggleHeader.bind(this)}
                onEnter={this.onEnter.bind(this)}
                handleStateUpdate={(newState) =>
                  this.handleStateUpdate(newState)
                }
              />
            )}
          />
          <Route
            exact
            path="/login"
            render={(props) => (
              <Login
                {...props}
                onLogin={this.onLogin.bind(this)}
                onEnter={this.onEnter.bind(this)}
                handleStateUpdate={(newState) =>
                  this.handleStateUpdate(newState)
                }
              />
            )}
          />
          <Route
            exact
            path="/register"
            component={Register}
            onEnter={this.onEnter.bind(this)}
          />
          <Route exact path="/password/recovery" component={ForgotPassword} />
          <Route
            exact
            path="/password/change/:code"
            component={ChangePassword}
            onEnter={this.onEnter.bind(this)}
          />
          <Route exact path="/password/code" component={EnterCodePassword} />
          <Route exact path="/register/account" component={RegisterAccount} />
          <Route exact path="/register/phone" component={RegisterPhone} />
          <Route
            exact
            path="/register/code/:phone"
            component={RegisterEnterCode}
          />
          <Route
            exact
            path="/register/:type/not_found"
            component={RegisterNotFound}
          />
          <Route
            exact
            path="/register/finish"
            render={(props) => (
              <RegisterFinish
                {...props}
                onLogin={this.onLogin.bind(this)}
                openTerms={this.openTerms.bind(this)}
                openPrivacy={this.openPrivacy.bind(this)}
              />
            )}
          />
          <Route
            exact
            path="/contacts"
            component={() => <Contacts {...this.state} />}
          />
          <Route
            exact
            path="/accessibility_statement"
            component={() => <AccessibilityStatement {...this.state} />}
          />
          <Route exact path="/about" component={About} />
          <ProtectedRoute
            exact
            path="/settings"
            render={(props) => (
              <Settings
                {...props}
                {...this.state}
                onOpenChangePhone={this.openChangePhone.bind(this)}
                onOpenChangePass={this.openChangePass.bind(this)}
              />
            )}
          />
          <ProtectedRoute
            exact
            path="/home"
            render={(props) => (
              <Home
                {...props}
                {...this.state}
                onAlerts={(alertsCount) => this.setState({ alertsCount })}
                handleStateUpdate={(newState) =>
                  this.handleStateUpdate(newState)
                }
              />
            )}
          />
          <ProtectedRoute
            exact
            path="/new_request"
            render={(props) => (
              <NewRequest
                {...props}
                {...this.state}
                setError={(err) => this.setState({ errorMessage: err })}
                getAlerts={() => this.getAlerts()}
              />
            )}
          />
          <ProtectedRoute
            exact
            path="/alerts"
            render={(props) => (
              <Alerts
                {...props}
                {...this.state}
                onAlerts={(alertsCount) => this.setState({ alertsCount })}
                confirmAlert={() => this.confirmAlert()}
                handleStateUpdate={(newState) =>
                  this.handleStateUpdate(newState)
                }
              />
            )}
          />

          <ProtectedRoute
            exact
            path="/alerts/:id"
            component={(props) => (
              <AlertInfo
                {...props}
                {...this.state}
                confirmAlert={() => this.confirmAlert()}
                handleStateUpdate={(newState) =>
                  this.handleStateUpdate(newState)
                }
              />
            )}
          />

          {/*</div>*/}
          <Footer
            openTerms={this.openTerms.bind(this)}
            openPrivacy={this.openPrivacy.bind(this)}
            dropScreenID={() => this.setState({ currentScreen: "" })}
          />
          <Popup
            contentStyle={{
              borderRadius: 8,
              width: "100%",
              marginTop: 95,
              marginBottom: 95,
              marginLeft: 15,
              marginRight: 15,
            }}
            open={this.state.openTerms}
            onClose={this.closeTerms.bind(this)}
          >
            <TermsOfUse onClose={this.closeTerms.bind(this)} />
          </Popup>
          <Popup
            contentStyle={{
              borderRadius: 8,
              width: "100%",
              marginTop: 95,
              marginBottom: 95,
              marginLeft: 15,
              marginRight: 15,
            }}
            open={this.state.openPrivacy}
            onClose={this.closePrivacy.bind(this)}
          >
            <PrivacyPolicy onClose={this.closePrivacy.bind(this)} />
          </Popup>
          <Popup
            contentStyle={{
              borderRadius: 8,
              paddingRight: 15,
              paddingLeft: 15,
              paddingTop: 10,
              width: "initial",
            }}
            open={this.state.openChangePass}
            onClose={this.closeChangePass.bind(this)}
          >
            <SettingsChangePassword onClose={this.closeChangePass.bind(this)} />
          </Popup>
          <Popup
            contentStyle={{ borderRadius: 8, width: "80%" }}
            open={this.state.openChangePhone}
            onClose={this.closeChangePhone.bind(this)}
          >
            <SettingsChangePhone
              onClose={this.closeChangePhone.bind(this)}
              updateUser={() => this.updateUser()}
            />
          </Popup>
          <Popup
            contentStyle={{ borderRadius: 8 }}
            open={this.state.errorMessage}
            onClose={this.closeErrorPopup.bind(this)}
          >
            <AppPopup
              onClose={this.closeErrorPopup.bind(this)}
              message={this.state.errorMessage}
            />
          </Popup>
        </div>
      </HashRouter>
    );
  }

  closeErrorPopup() {
    this.setState({ errorMessage: null });
  }

  _renderDesktop(t) {
    const pathname = window.location.pathname;
    const isFooterContainer =
      pathname === "/accessibility_statement" ||
      pathname === "/contacts" ||
      pathname === "/about";

    return (
      <HashRouter>
        <div
          lang={i18n.language}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/*<div className="Site-content">*/}
          <Offline>
            <div
              style={{
                backgroundColor: "red",
                padding: 2,
                width: "100%",
                color: "white",
                textAlign: "center",
              }}
            >
              {t("NoInternetConnection")}
            </div>
          </Offline>
          {this.state.showHeader && <Header t={t} />}
          {this.state.isLoggedIn
            ? this._renderLoggedInStripDesktop(t)
            : this._renderLoggedOutStripDesktop(t)}

          <div
            id="content"
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              marginTop: this.state.isLoggedIn || isFooterContainer ? 0 : -74,
            }}
          >
            <Route
              exact
              path="/"
              render={(props) => (
                <LandingPage
                  {...props}
                  onEnter={this.onEnter.bind(this)}
                  toggleHeader={this.toggleHeader.bind(this)}
                  handleStateUpdate={(newState) =>
                    this.handleStateUpdate(newState)
                  }
                />
              )}
            />
            <Route
              exact
              path="/admin_login"
              render={(props) => (
                <AdminLogin
                  {...props}
                  onLogin={() => {
                    this.onLogin();
                    this.setState({ showRightButton: true });
                  }}
                  onEnter={() => {
                    this.setState({ showRightButton: false });
                    this.onEnter();
                  }}
                />
              )}
            />
            <Route
              exact
              path="/login"
              render={(props) => (
                <Login
                  {...props}
                  onLogin={this.onLogin.bind(this)}
                  onEnter={this.onEnter.bind(this)}
                  toggleHeader={this.toggleHeader.bind(this)}
                  handleStateUpdate={(newState) =>
                    this.handleStateUpdate(newState)
                  }
                />
              )}
            />
            <Route
              exact
              path="/register"
              component={Register}
              onEnter={this.onEnter.bind(this)}
            />
            <Route exact path="/password/recovery" component={ForgotPassword} />
            <Route
              exact
              path="/password/change/:code"
              component={ChangePassword}
              onEnter={this.onEnter.bind(this)}
            />
            <Route exact path="/password/code" component={EnterCodePassword} />
            <Route exact path="/register/account" component={RegisterAccount} />
            <Route exact path="/register/phone" component={RegisterPhone} />
            <Route
              exact
              path="/register/code/:phone"
              component={RegisterEnterCode}
            />
            <Route
              exact
              path="/register/:type/not_found"
              component={RegisterNotFound}
            />
            <Route
              exact
              path="/register/finish"
              render={(props) => (
                <RegisterFinish
                  {...props}
                  onLogin={this.onLogin.bind(this)}
                  openTerms={this.openTerms.bind(this)}
                  openPrivacy={this.openPrivacy.bind(this)}
                />
              )}
            />
            <Route
              exact
              path="/contacts"
              component={() => <Contacts {...this.state} />}
            />
            <Route
              exact
              path="/accessibility_statement"
              component={() => <AccessibilityStatement {...this.state} />}
            />
            <Route exact path="/about" component={About} />
            <ProtectedRoute
              exact
              path="/home"
              render={(props) => (
                <Home
                  {...props}
                  {...this.state}
                  onAlerts={(alertsCount) => this.setState({ alertsCount })}
                  handleStateUpdate={(newState) =>
                    this.handleStateUpdate(newState)
                  }
                />
              )}
            />
            <ProtectedRoute
              exact
              path="/settings"
              render={(props) => (
                <Settings
                  {...props}
                  {...this.state}
                  onOpenChangePhone={this.openChangePhone.bind(this)}
                  onOpenChangePass={this.openChangePass.bind(this)}
                />
              )}
            />
            <ProtectedRoute
              exact
              path="/new_request"
              render={(props) => (
                <NewRequest
                  {...props}
                  {...this.state}
                  setError={(err) => this.setState({ errorMessage: err })}
                  getAlerts={() => this.getAlerts()}
                />
              )}
            />
            <ProtectedRoute
              exact
              path="/alerts"
              render={(props) => (
                <Alerts
                  {...props}
                  {...this.state}
                  onAlerts={(alertsCount) => this.setState({ alertsCount })}
                  confirmAlert={() => this.confirmAlert()}
                  handleStateUpdate={(newState) =>
                    this.handleStateUpdate(newState)
                  }
                />
              )}
            />
            <ProtectedRoute
              exact
              path="/alerts/:id"
              component={(props) => (
                <AlertInfo
                  {...props}
                  {...this.state}
                  confirmAlert={() => this.confirmAlert()}
                  alerts={this.state.alerts}
                  handleStateUpdate={(newState) =>
                    this.handleStateUpdate(newState)
                  }
                />
              )}
            />

            {/*</Switch>*/}
            {/*</CSSTransition>*/}
            {/*</TransitionGroup>*/}
          </div>

          {/*</div>*/}
          <Footer
            openTerms={this.openTerms.bind(this)}
            openPrivacy={this.openPrivacy.bind(this)}
            dropScreenID={() => this.setState({ currentScreen: "" })}
          />
          <Popup
            lockScroll={false}
            contentStyle={{
              borderRadius: 8,
              width: "100%",
              marginTop: 140,
              marginBottom: 140,
              maxWidth: 950,
            }}
            open={this.state.openTerms}
            onClose={this.closeTerms.bind(this)}
          >
            <TermsOfUse onClose={this.closeTerms.bind(this)} />
          </Popup>
          <Popup
            contentStyle={{
              borderRadius: 8,
              width: "100%",
              marginTop: 140,
              marginBottom: 140,
              maxWidth: 950,
            }}
            open={this.state.openPrivacy}
            onClose={this.closePrivacy.bind(this)}
          >
            <PrivacyPolicy onClose={this.closePrivacy.bind(this)} />
          </Popup>
          <Popup
            contentStyle={{
              borderRadius: 8,
              paddingRight: 140,
              paddingLeft: 140,
              paddingTop: 10,
              width: "initial",
            }}
            open={this.state.openChangePass}
            onClose={this.closeChangePass.bind(this)}
          >
            <SettingsChangePassword onClose={this.closeChangePass.bind(this)} />
          </Popup>
          <Popup
            contentStyle={{ borderRadius: 8 }}
            open={this.state.openChangePhone}
            onClose={this.closeChangePhone.bind(this)}
          >
            <SettingsChangePhone
              onClose={this.closeChangePhone.bind(this)}
              updateUser={() => this.updateUser()}
            />
          </Popup>
          <Popup
            contentStyle={{ borderRadius: 8 }}
            open={this.state.errorMessage}
            onClose={this.closeErrorPopup.bind(this)}
          >
            <AppPopup
              onClose={this.closeErrorPopup.bind(this)}
              message={this.state.errorMessage}
            />
          </Popup>
        </div>
      </HashRouter>
    );
  }

  onEnter = (showSignUpButton) => {
    this.setState({ showSignUpButton });
  };

  toggleHeader = (show) => {
    let isLoggedIn = this.state.isLoggedIn;
    if (!show) isLoggedIn = false;
    this.setState({ showHeader: show, isLoggedIn });
  };

  onLogout = () => {
    // clearInterval(this.state.alertsTimers);
    clearInterval(this.updateInterval);
    this.setState({ initialState });
    this.setState({vacation: null});
    api.logout((res, err) => {
      if (err) {
        console.log("Unknown error has occurred.");
      } else {
        if (res.error) {
          console.log(res.error);

          if (res.code === 401) {
            PersistenceManager.deleteItem(globals.STORAGE.TOKEN);
            this.setState({ errorMessage: res.error });
          }

          this.setState({
            initLoading: false,
          });
        } else {
          this.setState({meters: [], alertsCount: 0, alerts: []});
          DataManager.sharedInstance.reset();
        }
      }
    });
  };

  onLogin = () => {
    this.setState({
      isLoggedIn: true,
    });
    this.fetchData();
  };

  onHomePressed = () => {
    if (this.state.currentScreen === 0) return;

    this.setState({ currentScreen: 0 });
  };

  onSettingsPressed = () => {
    if (this.state.currentScreen === 1) return;

    this.setState({ currentScreen: 1 });
  };

  onNewRequestPressed = () => {
    if (this.state.currentScreen === 3) return;

    this.setState({ currentScreen: 3 });
  };

  onAlertsPressed = () => {
    if (this.state.currentScreen === 2) return;

    this.setState({ currentScreen: 2 });
  };

  openChangePhone = () => {
    this.setState({ openChangePhone: true });
  };

  closeChangePhone = () => {
    this.setState({ openChangePhone: false });
  };

  openChangePass = () => {
    this.setState({ openChangePass: true });
  };

  closeChangePass = () => {
    this.setState({ openChangePass: false });
  };

  closeTerms = () => {
    this.setState({ openTerms: false });
  };

  openTerms = () => {
    this.setState({ openTerms: true });
  };

  closePrivacy = () => {
    this.setState({ openPrivacy: false });
  };

  openPrivacy = () => {
    this.setState({ openPrivacy: true });
  };
}

export default withRouter(withTranslation("translations")(App));
