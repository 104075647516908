import React, {Component} from 'react';
import {withTranslation} from "react-i18next";

import './Reply.css'
import images from '../../images/index'
import utils, {AlertsDescriptions, getTranslatedRequestTitle} from "../../utils"; 
import {AlertsTitles} from "../../utils";

import AppButton from "../../components/AppButton";


class Reply extends Component {

    render() {
        const {
            t,
            requests,
            alerts,
            activeMessageIndex,
            activeAlertIndex,
            isAlertsView,
            backHistory,
            history,
            handleActiveMessage,
            handleDeleteAnItem,
            meters,
            muni
        } = this.props;
        const activeMessage = isAlertsView ? alerts[activeAlertIndex] : requests[activeMessageIndex];

        const description = isAlertsView? t(AlertsDescriptions[activeMessage?.alertTypeId]).split('\n')
            .map((row, index) => <div key={'alertRow'+index}>{row}</div>) : activeMessage?.requestText;

        return (
            <div className="ReplyBody">
                <div className={"ReplyBody__ReturnButton" + (backHistory ? ' Visible' : '')}
                     onClick={() => backHistory ? history.goBack() : handleActiveMessage(activeMessageIndex, true)}
                >
                    <img src={images().icBackBlue} alt="Back"/>
                    <p>{t('Return')}</p>
                </div>
                <div className="ReplyBody__Header">
                    <div className="ReplyBody__Header_Row">
                        <div className="ReplyBody__Header__Company">
                            <div className="ReplyBody__Header__Company_Icon">
                                <img src={utils.getLargeIconForAlertType(activeMessage?.alertTypeId)}
                                     alt="message icon"/>
                            </div>
                            <div
                                className={"ReplyBody__Header__Company__Description" + (isAlertsView ? " AlertHeader" : "")}>
                                {isAlertsView ? t(`AlertsTitles.${AlertsTitles[+activeMessage?.alertTypeId] ?? ''}`) : getTranslatedRequestTitle(activeMessage?.requestSubject)}
                            </div>
                        </div>
                    </div>

                    <div className="alertInfoWaterMeter">
                        {isAlertsView ? t('WaterMeter') + ': ' + (meters?.find(meter => +meter.meterCount === +activeMessage?.meterCount)?.meterSn || '') : ''}
                    </div>
                    <div className="ReplyBody__Header_MuniAddress">
                        {isAlertsView ? meters?.find(meter => +meter.meterCount === +activeMessage?.meterCount)?.fullAddress : ''}
                    </div>
                    <div className="ReplyBody__Header__Date">
                        {isAlertsView ? utils.toAlertDate(activeMessage?.alertTime)
                            : t('replyInquiry') + ' ' + utils.toAlertDate(activeMessage?.requestDate)
                        }
                    </div>
                </div>
                <div className="ReplyBody__Content">
                    <div className="ReplyBody__Content__Request">
                        {description}
                    </div>
                    {!isAlertsView && activeMessage.answerDate && <div className="ReplyBody__Content__Reply">
                        <p>{t('ReplyOn')}</p>
                        <p className="ReplyBody__Header__Date">
                            {utils.toAlertDate(activeMessage?.answerDate)}
                        </p>
                        <p  className="ReplyBody__Content__Reply__Body">{activeMessage?.answerText}</p>
                    </div>}

                    {isAlertsView &&
                    <>
                        <div className="alertInfoText">{t('callUs')}</div>
                        <div className="alertsCallContacts"
                        >
                            <div className="alertInfoCall">{muni?.description}</div>
                            <div className="alertSep"/>
                            <div className="alertInfoCall">{muni?.phoneNumber}</div>
                        </div>
                        { activeMessage?.isRead !== 'true' &&
                            <button className="alert_ConfirmButton"
                                    onClick={() => handleDeleteAnItem(activeAlertIndex, activeMessage?.logId)}
                            >
                                <AppButton text={t('buttons.confirm')} color='blue' onClick={() => null} propsStyle={{width: 190, marginBottom: 30, marginLeft: 20}} />
                            </button>
                        }
                    </>
                    }

                </div>
            </div>
        )
    }
}

export default withTranslation('translations')(Reply);
