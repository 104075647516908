import React from 'react';
import {withTranslation} from "react-i18next";
import Toggle from 'react-toggle';

import './Settings.css';
import * as api from "../../api";
import {AlertsTitles} from "../../utils";
import images from "../../images";
import globals from "../../globals";

class Settings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            chosenSettings: [],
            isLoading: false
        }

        this.toggleSwitch = this.toggleSwitch.bind(this);
    }

    componentWillMount() {
        api.getAlertSettings((res, err) => {
            //this.setState({isLoading: false});
            if (err) {
                console.log('Unknown error has occurred.');
            } else {
                if (res.error) {
                    console.log(res.error);
                } else {
                    if (res.length > 0) {
                        this.setState({chosenSettings: res})
                    }
                }
            }
        });

    }

    render() {
        const {t, user, meters, alertsSettings} = this.props
        const {phoneNumber} = this.props.user;
        const {chosenSettings} = this.state;

        const alertsToggles = alertsSettings?.map((alertType, index) => {
            const {alertTypeId} = alertType;
            const email = chosenSettings.some(type => +type.alertTypeId === +alertTypeId && +type.mediaTypeId === 1)
            const sms = chosenSettings.some(type => +type.alertTypeId === +alertTypeId && +type.mediaTypeId === 3)
            return (
                <div key={'alertsRow' + index}
                     className="settingsContainer_params_togglesContainer_row">
                    <div className="alertType">{t(`AlertsTitles.${AlertsTitles[+alertType.alertTypeId] ?? 'AlertIdNotFound'}`)}</div>
                    <div className="alertToggle">
                        <Toggle icons={false}
                                disabled={+alertTypeId === 23}
                                checked={email || +alertTypeId === 23}
                                onChange={() => this.toggleSwitch(+alertTypeId, 1)}/>
                    </div>
                    <div className="alertToggle">
                        <Toggle icons={false}
                                checked={sms}
                                onChange={() => this.toggleSwitch(+alertTypeId, 3)}/>
                    </div>
                </div>
            )
        })


        return (
            <div className="settingsContainer">
                <div className="settingsContainer_params">
                    <div className="title alertParamsTitle">{t('SetRealTimeAlerts')}</div>
                    <div className="settingsContainer_params_togglesContainer">
                        <div className="settingsContainer_params_togglesContainer_row">
                            <div className="alertTitle alertType">{t('Alerts')}</div>
                            <div className="alertTitle alertToggle">{t('EmailAlert')}</div>
                            <div className="alertTitle alertToggle">{t('SMSAlert')}</div>
                        </div>
                        {alertsToggles}
                    </div>
                    <p className="thinSep" style={{marginTop: 30}}/>
                    <div className="settingsContainer_params_meters">
                        <div className="settingsContainer_params_meters_header">
                            <img src={images().icBigGauge} alt="water  meters"/>
                            <div className="myWaterMeters">{t('MyWaterMeters')}</div>
                        </div>
                        <div className="settingsContainer_params_meters_list">
                            {meters?.map((item, index) => {
                                return <div className="settingsContainer_params_meters_list_row" key={'meters' + index}>
                                    <p className="waterMeterItem">{t('WaterMeter')}:&nbsp;</p>
                                    <p className="waterMeterItem">{item.meterSn + ','}&nbsp;</p>
                                    <p className="waterMeterItem"
                                    >{item.fullAddress ? item.fullAddress : globals.METER_DEFAULT_ADDRESS}</p>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
                <div className="settingsContainer_account">
                    <div className="accountBox"
                         style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{display: 'flex'}}>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <div className="accountKey">{t('name')}</div>
                                <div className="accountKey">{t('AccountNumber')}</div>
                                <div className="accountKey">{t('PhoneNumber')}</div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <div
                                    className="accountValue">{user.firstName} {user.lastName}</div>
                                <div className="accountValue">{user.accountNumber}</div>
                                <div className="accountValue phoneNumber"
                                >{phoneNumber ? (phoneNumber?.countryCode ?? '') + ((phoneNumber.AdditionalPhoneNumber ? phoneNumber.AdditionalPhoneNumber : phoneNumber.phoneNumber) ?? '') : ''}</div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20}}>
                                <div className="accountKey" style={{opacity: 0}}>{t('a')}</div>
                                <div className="accountKey" style={{opacity: 0}}>{t('a')}</div>
                                <div className="accountKey accountLink" onClick={this.openChangePhone.bind(this)}
                                     style={{marginTop: 10}}>{t('Edit')}</div>
                            </div>
                        </div>
                        <div className="accountBox_buttonWrapper">
                            <button className="settingsContainer_account_button blueButton"
                                    onClick={this.openChangePass.bind(this)}>
                                <img src={images().lockIcon} alt="Lock Icon"/>
                                <div className="buttonText">{t('changePassword').toUpperCase()}</div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    openChangePhone = () => {
        this.props.onOpenChangePhone();
    };

    openChangePass = () => {
        this.props.onOpenChangePass();
    };

    toggleSwitch = (alertTypeId, index) => {
        if (!this.state.isLoading) {
            this.setState({isLoading: true});

            const {chosenSettings} = this.state;
            const chosenSettingsIndex = chosenSettings.findIndex(type => +type.alertTypeId === alertTypeId && +type.mediaTypeId === index);
            if (chosenSettingsIndex >= 0) {
                api.unsetAlert(alertTypeId, index, (res, err) => {
                    if (err) {
                        console.log('Unknown error has occurred.');
                    } else {
                        if (res.error) {
                            console.log(res.error);
                        } else {
                            let tempChosenSettings = [...chosenSettings];
                            tempChosenSettings.splice(chosenSettingsIndex, 1);
                            this.setState({chosenSettings: tempChosenSettings})
                        }
                    }

                    this.setState({isLoading: false});
                });
            } else {
                api.setAlert(alertTypeId, index, (res, err) => {
                    if (err) {
                        console.log('Unknown error has occurred.');
                    } else {
                        if (res.error) {
                            console.log(res.error);
                        } else {
                            let tempChosenSettings = [...chosenSettings];
                            tempChosenSettings.push({alertTypeId, mediaTypeId: index})
                            this.setState({chosenSettings: tempChosenSettings})
                        }
                    }

                    this.setState({isLoading: false});
                });
            }
        }
    };
}

export default withTranslation('translations')(Settings)
