/* eslint-disable */
import React from "react";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import Button from "react-validation/build/button";
import Popup from "reactjs-popup";
import { withTranslation } from "react-i18next";

import images from "../../images";
import "../../App.css";
import "./RegisterPhone.css";
import * as api from "../../api";
import DataManager from "../../managers/DataManager";
import { Mobile } from "../../App";
import AppPopup from "../../components/AppPopup";
import Phone from "../../components/Phone";
import translations from "../../translations";

import AppButton from "../../components/AppButton";

class RegisterPhone extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      phone: "",
      countryCode: "+972",
      countryName: "il",
      errorMessage: null,
      validation: false,
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <Mobile>
          {(matches) => {
            if (matches) return this._renderMobile(t);
            else return this._renderDesktop(t);
          }}
        </Mobile>
      </div>
    );
  }

  _renderMobile(t) {
    const { validation, phone } = this.state;
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginRight: 15,
          marginLeft: 15,
        }}
      >
        <h1 className={"title"} style={{ fontSize: 22, fontWeight: 700 }}>
          {t("JoinMyWaterAdvisor")}
        </h1>
        <h3
          style={{
            marginTop: 10,
            marginBottom: 10,
            textAlign: "center",
            fontSize: 16,
            fontWeight: 400,
          }}
        >
          {t("enterPrimaryCellPhoneProvider")}
        </h3>
        <Form
          dir="ltr"
          method={"post"}
          onSubmit={this.onSubmit.bind(this)}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div className="phoneBox">
            <select
              name="country"
              disabled
              className="country"
              onChange={(event) =>
                this.setState({ countryCode: event.target.value })
              }
            >
              <option value="+972">+972 IL</option>
              {/*<option value="+1">+1 US</option>*/}
            </select>
            <Input
              // validations={[validators.required]}
              placeholder=""
              type="tel"
              name="PhoneNumber"
              onChange={(event) => this.setState({ phone: event.target.value })}
              value={this.state.phone}
              className="phoneInput country"
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                display: "flex",
                border: 0,
                backgroundColor: "transparent",
              }}
            ></Input>
          </div>
          <span
            className="inputError"
            style={{
              display: validation && !phone ? "initial" : "none",
              marginLeft: 10,
            }}
          >
            {t("PhoneNumber")}&nbsp;{t("isRequired")}
          </span>
          <div
            style={{ marginTop: 30, display: "flex", justifyContent: "center" }}
          >
            <Button style={{ border: 0, backgroundColor: "transparent" }}>
              <AppButton text={t('buttons.getCode')} color='blue' onClick={()=> this.setState({ validation: true })} propsStyle={{marginBottom: 30, width: 210}} />
            </Button>
          </div>
        </Form>
        <Popup
          contentStyle={{ borderRadius: 5 }}
          open={this.state.errorMessage}
          onClose={this.closeErrorPopup.bind(this)}
        >
          <AppPopup
            onClose={this.closeErrorPopup.bind(this)}
            message={this.state.errorMessage}
          />
        </Popup>
      </div>
    );
  }
  
  onPhoneChange = (phone, country) => {
    console.log('onPhoneChange', phone, phone.substring(country?.dialCode.length, phone.length), country, '+' + country?.dialCode);
    this.setState({
      phone: phone.substring(country?.dialCode.length, phone.length),
      countryCode: ('+' + country?.dialCode) ?? '+972',
      countryName: country?.countryCode ?? 'il'
    })
  }

  _renderDesktop(t) {
    const { validation, phone } = this.state;
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 20,
        }}
      >
        <h1 className={"title"}>{t("JoinMyWaterAdvisor")}</h1>
        <h3 className="enterPrimary">{t("enterPrimaryCellPhoneProvider")}</h3>
        <div style={{direction: 'ltr'}}>
          <Phone value={this.state.phone} onChange={this.onPhoneChange} />
        </div>
        <Form
          ref={(c) => {
            this.form = c;
          }}
          dir="ltr"
          method={"post"}
          onSubmit={this.onSubmit.bind(this)}
        >
          <span
            className="inputError"
            style={{
              display: validation && !phone ? "initial" : "none",
              marginLeft: 30,
            }}
          >
            {t("PhoneNumber")}&nbsp;{t("isRequired")}
          </span>
          <div
            style={{ marginTop: 20, display: "flex", justifyContent: "center" }}
          >
            <Button style={{ border: 0, backgroundColor: "transparent" }}>
              <AppButton text={t('buttons.getCode')} color='blue' onClick={()=> this.setState({ validation: true })} propsStyle={{marginBottom: 30, width: 210}} />
            </Button>
          </div>
        </Form>
        <Popup
          contentStyle={{ borderRadius: 5 }}
          open={this.state.errorMessage}
          onClose={this.closeErrorPopup.bind(this)}
        >
          <AppPopup
            onClose={this.closeErrorPopup.bind(this)}
            message={this.state.errorMessage}
          />
        </Popup>
      </div>
    );
  }

  closeErrorPopup() {
    this.setState({ errorMessage: null });
  }

  onSubmit = (event) => {
    event.preventDefault();
    const { t } = this.props;

    const { phone, countryCode, countryName } = this.state;
    console.log('onSubmit countryName', translations.findCountryLanguage(countryName)); 
    const countryLanguage = translations.findCountryLanguage(countryName)

    if (phone) {
      api.registerPhone(countryCode, phone, countryLanguage, (response, error) => { // 'en'
        console.log("response", response);
        if (error) {
          this.setState({ errorMessage: t("errorOccurred") });
        } else {
          if (response.error) {
            if (response.code === 10327 || response.code === 10030) {
            //   DataManager.sharedInstance.errorCode = response.code;
            //   DataManager.sharedInstance.errorMessage = response.error;
              DataManager.sharedInstance.loginErrors.phone = 1;
            //   this.props.history.push("/register/phone/not_found", {
            //     errorMessage: response.error,
            //     errorCode: response.code,
            //   });
              this.props.history.push({
                pathname: `/register/phone/not_found`,
                search: `?errorCode=${response.code}`,
                // state: {
                //   errorMessage: response.error,
                //   errorCode: response.code,
                // },
              });
            } else this.setState({ errorMessage: response.error });
          } else {
            const sessionId = response.sessionId;
            DataManager.sharedInstance.sessionId = sessionId;
            this.props.history.push(`/register/code/${countryCode}-${phone}`);
          }
        }
      });
    }
  };
}

export default withTranslation("translations")(RegisterPhone);
