import React from "react";
import { withTranslation } from "react-i18next";

import "./LanguageSwitcher.css";

class AppButton extends React.Component {
  render() {
    const { text, color, onClick, propsStyle } = this.props;

    const commonStyle =
      color === "blue" ? styles.blueBackground : styles.whiteBackground;

    return (
      <div
        style={{ ...styles.button, ...commonStyle, ...propsStyle }}
        onClick={onClick}
      >
        {text}
      </div>
    );
  }
}

const styles = {
  button: {
    fontSize: 16,
    fontWeight: 400,
    padding: "14px 30px",
    borderRadius: 24,
    textDecoration: "none",
    cursor: "pointer",
    width: 200,
    height: 47.5,
    display: "flex",
    justifyContent: "center",
  },
  whiteBackground: {
    color: "#999ea8",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 10px 10px #d9d9d9",
  },
  blueBackground: {
    color: "#FFFFFF",
    backgroundColor: "#003FFF",
    boxShadow: "0 10px 20px rgba(0, 47, 189, 0.2)",
  },
};

export default withTranslation("translations")(AppButton);
