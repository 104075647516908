import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import "./NewRequest.css";
import loader from "../../images/favicon-194x194.png";
import * as api from "../../api";
import images from "../../images";
import {MessageSubjects} from "../../utils";

class NewRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: "",
      text: "",
      sent: false,
      isLoader: false,
      validation: false,
      dropDownVisible: false,
      searchInputText: "",
    };
  }

  handleTextChange = (e) => {
    this.setState({ text: e.target.value });
  };

  onSubjectSelect = (e) => {
    e.preventDefault();
    this.setState({ subject: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { subject, text } = this.state;
    this.setState({ validation: true });
    if (subject && text) {
      this.setState({ isLoader: true });
      api.sendMunicipalityMessage(
        { requestSubject: subject, requestText: text },
        (res, err) => {
          if (err) {
            console.log("Unknown error has occurred.");
            this.setState({ isLoader: false });
          } else {
            if (res.error) {
              console.log(res.error);
              this.props.setError(res.error);
              this.setState({ sent: false, isLoader: false });
            } else {
              this.setState({ sent: true, isLoader: false });
              this.props.getAlerts();
            }
          }
        }
      );
    }
  };

  render() {
    const { t, muni, isMessagesAllowed, subjects } = this.props;
    const { sent, isLoader, validation, subject, text } = this.state;

    return (
      <div className="NewRequest">
        <div className="RequestContacts_Container">
          <div className="NewRequest_contactsContainer">
            <p className="contactsInfo">{t("ContactUs")}</p>
            <div className="contactsDetails">
              <div className="detailRow">
                <p>{muni?.description}</p>
              </div>
              {muni?.phoneNumber && (
                <div className="detailRow">
                  <p>{t("Tel")}&nbsp;</p>
                  <p>{muni?.phoneNumber}&nbsp;</p>
                  <img src={images().icPhoneCall} alt="Phone icon" />
                </div>
              )}
              {muni?.Email && (
                <div className="detailRow">
                  <p>{t("mail")}:&nbsp;</p>
                  <p>{muni?.Email}&nbsp;</p>
                </div>
              )}
            </div>
          </div>
        </div>

        {sent ? (
          <div className="HasSent">
            <div>{t("HasSent")}</div>
          </div>
        ) : (
          isMessagesAllowed && (
            <div className="NewRequest_wizard_container">
              <h1>{t("NewMessage")}</h1>
              <form>
                <div
                  className={
                    "NewRequest_container" + (sent ? " mobile-hidden" : "")
                  }
                >
                  <div className="NewRequest_container_label">
                    {t("Subject")}
                  </div>

                  {/* <div
                    className="dropdown"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <input
                      type="text"
                      className={"dropdownInput registerDropdown "}
                      onFocus={(e) =>
                        this.setState({
                          dropDownVisible: true,
                        })
                      }

                      placeholder={t("chooseMunicipality")}
                      value={this.state.searchInputText}
                      onChange={(e) => {
                        console.log("e", e);
                        this.setState({
                          searchInputText: e.target.value,
                          subject: "",
                        });
                      }}
                    />
                    {this.state.dropDownVisible ? (
                      <img
                        className="dropdown_button_close"
                        src={images().icClose}
                        alt=""
                        width={15}
                        height={15}
                        onClick={() =>
                          this.setState({
                            // dropDownVisible: false,
                            searchInputText: "",
                            subject: "",
                          })
                        }
                      />
                    ) : (
                      <img
                        className="dropdown_button"
                        src={images().icDropDownArrow}
                        alt=""
                        onClick={() =>
                          this.setState({
                            dropDownVisible: true,
                          })
                        }
                      />
                    )}

                    {this.state.dropDownVisible && (
                      <div
                        className="dropdown-content dropdown-content-register"
                        style={{
                          maxHeight: "35%",
                          minWidth: 500,
                          maxWidth: 500,
                          overflowY: "scroll",
                          flex: 1,
                        }}
                      >
                        {subjects?.map((subject) => {
                          if (
                            subject.MessageSubjectsText.toLowerCase().includes(
                              this.state.searchInputText.toLowerCase()
                            )
                          )
                            return (
                              <div
                                key={"subject" + subject.MessageSubjectsID}
                                onClick={() => {
                                  console.log("onClick");
                                  this.setState({
                                    subject,
                                    searchInputText:
                                      subject.MessageSubjectsText,
                                    validation: false,
                                    dropDownVisible: false,
                                  });
                                }}
                              >
                                {subject.MessageSubjectsText}
                              </div>
                            );
                        })}
                      </div>
                    )}
                  </div> */}

                  <div
                    className="dropdown2"
                    // style={{direction: 'rtl'}}
                  >
                    <button
                      disabled
                      className="dropdownInput"
                      // style={{...(!this.state.subject && i18next.dir() === 'ltr')? {direction: "ltr"} : {}}}
                    >
                      {this.state.subject ? this.state.subject : t("Subject")}
                    </button>
                    <img
                      className="dropdown_button"
                      src={images().icDropDownArrow}
                      alt=""
                    />
                    <div className="dropdown2-content">
                      {subjects?.map((subject) => (
                        <div
                          key={subject.MessageSubjectsID}
                          onClick={() => {
                            this.setState({
                              subject: t(`MessageSubjectsMap.${MessageSubjects[+subject.MessageSubjectsID]}`)
                            });
                          }}
                        >
                          {t(`MessageSubjectsMap.${MessageSubjects[+subject.MessageSubjectsID]}`)}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <span
                  className="inputError"
                  style={{
                    display: validation && !subject ? "initial" : "none",
                  }}
                >
                  {t("Subject")}&nbsp;{t("isRequired")}
                </span>

                <div
                  className={
                    "NewRequest_container" + (sent ? " mobile-hidden" : "")
                  }
                >
                  <div className="NewRequest_container_label">
                    {t("Message")}
                  </div>
                  <textarea
                    className="NewRequest__TextArea"
                    placeholder={t("Message")}
                    onChange={this.handleTextChange}
                  />
                </div>
                <span
                  className="inputError"
                  style={{ display: validation && !text ? "initial" : "none" }}
                >
                  {t("Message")}&nbsp;{t("isRequired")}
                </span>
                <div
                  className={
                    isLoader || sent ? "NewRequest_sent" : "NewRequest_wait"
                  }
                >
                  {/*<div>{sent?  t('HasSent'): ''}</div>*/}
                  <img
                    className="NewRequest_sent__Loader"
                    src={loader}
                    alt="Loader"
                  />
                </div>
                <div
                  className={
                    "NewRequest_container" + (sent || isLoader ? " hidden" : "")
                  }
                >
                  <div
                    className="NewRequest__Button"
                    onClick={this.handleSubmit}
                  >
                    {t("Send")}
                  </div>
                </div>
              </form>
            </div>
          )
        )}
      </div>
    );
  }
}

export default withTranslation("translations")(NewRequest);
