import { translationEN } from "./locales/en";
import { translationHE } from "./locales/he";
import { translationAR } from "./locales/ar";
import { translationES } from "./locales/es";

const translations = {
  languages: [
    { lang: "he", phoneCountries: ["il"] },
    {
      lang: "en",
      phoneCountries: [
        "au",
        "az",
        "be",
        "hr",
        "fr",
        "de",
        "gr",
        "ie",
        "it",
        "nz",
        "ph",
        "sk",
        "si",
        "th",
        "gb",
        "vn",
      ],
    },
    { lang: "ar", phoneCountries: ["ar"] },
    {
      lang: "es",
      phoneCountries: [
        "cr",
        "br",
        "cl",
        "co",
        "do",
        "ec",
        "gt",
        "mx",
        "pe",
        "pt",
        "es",
        "uy",
      ],
    },
  ],
  resources: {
    he: {
      translations: translationHE,
    },
    en: {
      translations: translationEN,
    },
    ar: {
      translations: translationAR,
    },
    es: {
      translations: translationES,
    },
  },
  // The first in the list - default for the phone input
  phoneCountries: [
    { label: "il", value: "+972" },
    { label: "cr", value: "+506" },
    { label: "ar", value: "+54" },
    { label: "au", value: "+61" },
    { label: "az", value: "+994" },
    { label: "be", value: "+32" },
    { label: "br", value: "+55" },
    { label: "cl", value: "+56" },
    { label: "co", value: "+57" },
    { label: "hr", value: "+385" },
    { label: "do", value: "+1" },
    { label: "ec", value: "+593" },
    { label: "fr", value: "+33" },
    { label: "de", value: "+49" },
    { label: "gr", value: "+30" },
    { label: "gt", value: "+502" },
    { label: "ie", value: "+353" },
    { label: "it", value: "+39" },
    { label: "mx", value: "+52" },
    { label: "nz", value: "+64" },
    { label: "pe", value: "+51" },
    { label: "ph", value: "+63" },
    { label: "pt", value: "+351" },
    { label: "sk", value: "+421" },
    { label: "si", value: "+386" },
    { label: "es", value: "+34" },
    { label: "th", value: "+66" },
    { label: "gb", value: "+44" },
    { label: "uy", value: "+598" },
    { label: "vn", value: "+84" },
  ],
  findCountryLanguage: (country) => {
    let countryLanguage = translations.languages[0].lang;

    for (let i = 0; i < translations.languages.length; i++) {
      if (translations.languages[i].phoneCountries.includes(country)) {
        countryLanguage = translations.languages[i].lang;

        break;
      }
    }

    return countryLanguage;
  },
  findCountryShortName: (countryCode) => {
    if (!countryCode.includes("+")) countryCode = "+" + countryCode;

    const shortName = translations.phoneCountries.find(
      item => item.value === countryCode,
    ).label;

    return shortName;
  }
};

export default translations;
