import i18next from "../i18n";

export default function images() {
    return (
        {
            rtl: i18next.dir() === 'rtl',
            logo: require('./App_Consumers_Logo 66_84.png'),
            icBack: require('./ic_back.svg'),
            icBackBlue: i18next.dir() === 'rtl' ? require('./ic_back_blue_heb.svg') : require('./ic_back_blue.svg'),
            icBigLock: require('./ic_big_lock.svg'),
            btnLogOut: i18next.dir() === 'rtl' ? require('./btnLogOutHe.svg') : require('./btn_log_out.svg'),
            icArrowRightBlue: i18next.dir() === 'rtl' ? require('./ic_arrow_left_blue.svg') : require('./ic_arrow_right_blue.svg'),
            btnLogin: i18next.dir() === 'rtl' ? require('./btn_login_he.svg') : require('./btn_login.svg'),
            btnSendInstructions: i18next.dir() === 'rtl' ? require('./btn_send_instructions_he.svg') : require('./btn_send_instructions.svg'),
            btnContinue: i18next.dir() === 'rtl' ? require('./btn_continue_he.svg') : require('./btn_continue.svg'),
            btnGetCode: i18next.dir() === 'rtl' ? require('./btn_get_code_he.svg') : require('./btn_get_code.svg'),
            btnSavePassword: i18next.dir() === 'rtl' ? require('./btn_save_password_he.svg') : require('./btn_save_password.svg'),
            btnSignUp: i18next.dir() === 'rtl' ? require('./btn_sign_up_he.svg') : require('./btn_sign up.svg'),
            btnFinishAndLogin: i18next.dir() === 'rtl' ? require('./btn_finish_he.svg') : require('./btn_finish.svg'),
            btnDefineDays: i18next.dir() === 'rtl' ?  require('./defineDaysBtn.svg') : require('./btn_define_days.svg'),
            btnJoin: i18next.dir() === 'rtl' ? require('./btn_join_he.svg') : require('./btn_join.svg'),
            btnJoinMob: i18next.dir() === 'rtl' ? require('./btn_join_he.svg') : require('./btn_join_mob.svg'),
            btnLoginWhite: i18next.dir() === 'rtl' ? require('./btnLoginWhiteHe.svg') : require('./btn_log_in.svg'),
            btnLoginWhiteMob: i18next.dir() === 'rtl' ? require('./btnLoginWhiteHe.svg') : require('./btn_log_in_mob.svg'),
            btnBack: i18next.dir() === 'rtl' ? require('./btn_back_he.svg') : require('./btn_back.svg'),
            btnGooglePlay: require('./ic_google play.svg'),
            btnAppStore: require('./ic_app store.svg'),
            icClose: require('./ic_close.svg'),
            icOpenEyeGray: require('./ic_eye_open_gray.svg'),
            icBigGauge: require('./ic_big_gauge.svg'),
            btnChangePassword: i18next.dir() === 'rtl' ? require('./btnChangePasswordHe.svg') : require('./btn_change_password.svg'),
            btnSaveBlue: i18next.dir() === 'rtl' ? require('./btn_save_gray_he.svg') : require('./btn_save_blue.svg'),
            btnCancelWhite: i18next.dir() === 'rtl' ? require('./btn_cancel_white_he.svg') : require('./btn_cancel_white.svg'),
            btnConfirm: i18next.dir() === 'rtl' ? require('./btn_confirm_he.svg') : require('./btn_confirm.svg'),
            icWaterLimit: require('./ic_water_limit.svg'),
                icBigWaterLimit: require('./ic_water_limit.svg'),
            icVacation: require('./ic_vacation.svg'),
                icBigVacations: require('./ic_vacation.svg'),
            icFaucet: require('./ic_big_tap.svg'),
            icSmallCalendar: require('./ic_small_calendar.svg'),
            icLocation: require('./ic_location.svg'),
            lpImage: require('./landing_page_imageHe.png'),
            lpImageEn: require('./landing_page_image.png'),
            lpTopImage: require('./landing_page_top.svg'),
            lpLogo: require('./logo.svg'),
            bgWave: require('./bg_wave.svg'),
            bgWaveMob: require('./bg_wave_mob.svg'),
            bgWaveBottom: require('./bg_wave_bottom.svg'),
            icEmpowerment: require('./ic_empowerement.svg'),
            icFlexibility: require('./ic_flexibility.svg'),
            icCustomerService: require('./ic_custom service.svg'),
            icSafety: require('./ic_safety.svg'),
            icClarity: require('./ic_clarity.svg'),
            icContext: require('./ic_context.svg'),
            icBarChart: require('./ic_bar_chart.svg'),
            icBarChartSelected: require('./ic_bar_chart_selected.svg'),
            icTableChart: require('./ic_table_chart.svg'),
            icTableChartSelected: require('./ic_table_chart_selected.svg'),
            icPrinter: require('./printer.svg'),
            icExcel: require('./excel.svg'),
            lpTopImageMob: require('./landing_page_top_mob.svg'),
            lpImageMob: require('./landing_page_image_mob.svg'),
            icPhoneCall: require('./phone-call.svg'),
            placeholder: require('./placeholder.png'),
            aboutLogo: require('./about_logo.png'),
            SeeDetails: i18next.dir() === 'rtl' ? require('./btn_see_details_he.svg') : require('./btn_see_details.svg'),
            icAlerts: require('./ic_alerts.svg'),
            message: require('./ic_big_new message.svg'),
            IsrFlag: require('./israel.png'),
            UsFlag: require('./usa.png'),
            icAboveAverageConsumption: require('./ic_above_average_consumption.svg'),
            icAboveMaximumConsumption: require('./ic_above_maximum_consumption.svg'),
            icLeak: require('./ic_big_tap.svg'),
            icAnnualRationExceeded: require('./ic_annual_ration_exceeded.svg'),
            icMonthlyRationExceeded: require('./ic_monthly_ration_exceeded.svg'),
            icVacationExpense: require('./ic_vacation_expense/ic_vacation_expense.png'),
            icAboveAverageConsumptionBig: require('./ic_above_average_consumption_big.svg'),
            icAboveMaximumConsumptionBig: require('./ic_above_maximum_consumption_big.svg'),
            icLeakBig: require('./ic_big_tap.svg'),
            icAnnualRationExceededBig: require('./ic_annual_ration_exceeded_big.svg'),
            icMonthlyRationExceededBig: require('./ic_monthly_ration_exceeded_big.svg'),
            icVacationExpenseBig: require('./ic_vacation_expense/ic_vacation_expense_big.png'),
                LeakInMainMeterBig: require('./ic_big_water_meter.png'),
                LeakInMainMeter: require('./ic_water_meter.png'),
                lockIcon: require('./ic_big_padlock_white.png'),
                icDropDownArrow: require('./dropDownArrow.png')
        }
    )
}
